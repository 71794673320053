import AxiosGraczPromotionJS from './axios_gracz_promotion_js_api'

const ModuleGraczPromotionJS = {
  state: {
    stateGraczListPromotion: [],
    stateGraczPromotionDetail: [],
    stateGraczCreatePromotion: [],
    stateGraczEditPromotion: [],
    stateGraczDeletePromotion: []
  },
  mutations: {
    mutationsGraczListPromotion (state, data) {
      state.stateGraczListPromotion = data
    },
    mutationsGraczPromotionDetail (state, data) {
      state.stateGraczPromotionDetail = data
    },
    mutationsGraczCreatePromotion (state, data) {
      state.stateGraczCreatePromotion = data
    },
    mutationsGraczEditPromotion (state, data) {
      state.stateGraczEditPromotion = data
    },
    mutationsGraczDeletePromotion (state, data) {
      state.stateGraczDeletePromotion = data
    }
  },
  actions: {
    async actionsGraczListPromotion (context, access) {
      var response = await AxiosGraczPromotionJS.GraczListPromotion(access)
      await context.commit('mutationsGraczListPromotion', response)
    },
    async actionsGraczPromotionDetail (context, access) {
      var response = await AxiosGraczPromotionJS.GraczPromotionDetail(access)
      await context.commit('mutationsGraczPromotionDetail', response)
    },
    async actionsGraczCreatePromotion (context, access) {
      var response = await AxiosGraczPromotionJS.GraczCreatePromotion(access)
      await context.commit('mutationsGraczCreatePromotion', response)
    },
    async actionsGraczEditPromotion (context, access) {
      var response = await AxiosGraczPromotionJS.GraczEditPromotion(access)
      await context.commit('mutationsGraczEditPromotion', response)
    },
    async actionsGraczDeletePromotion (context, access) {
      var response = await AxiosGraczPromotionJS.GraczDeletePromotion(access)
      await context.commit('mutationsGraczDeletePromotion', response)
    }
  }
}
export default ModuleGraczPromotionJS
