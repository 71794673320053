import AxiosGraczBrand from './axios_gracz_brand_api'

const ModuleGraczBrand = {
  state: {
    stateGraczListBrand: [],
    stateGraczCreateBrand: []
  },
  mutations: {
    mutationsGraczListBrand (state, data) {
      state.stateGraczListBrand = data
    },
    mutationsGraczCreateBrand (state, data) {
      state.stateGraczCreateBrand = data
    }
  },
  actions: {
    async actionsGraczListBrand (context, access) {
      var response = await AxiosGraczBrand.GraczListBrand(access)
      await context.commit('mutationsGraczListBrand', response)
    },
    async actionsGraczCreateBrand (context, access) {
      var response = await AxiosGraczBrand.GraczCreateBrand(access)
      await context.commit('mutationsGraczCreateBrand', response)
    }
  }
}
export default ModuleGraczBrand
