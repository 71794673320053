import axios from 'axios'
import { Decode } from '@/services'

const GetToken = () => {
  if (Object.prototype.hasOwnProperty.call(localStorage, 'oneData')) {
    const oneData = JSON.parse(Decode.decode(localStorage.getItem('oneData')))
    const auth = {
      headers: { Authorization: `Bearer ${oneData.user.access_token}` }
    }
    return auth
  }
}

export default {
  async GraczCategoryList (val) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}api/g/list_category`, val, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async GraczCreateCategory (val) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}api/g/create_category`, val, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async GraczEditCategory (val) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}api/g/edit_category`, val, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async GraczChangeStatusCategory (val) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}api/g/change_status_category`, val, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async GraczDeleteCategory (val) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}api/g/delete_category`, val, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  }
}
