import AxiosBusiness from '../store_Business/axios_business'

const ModuleBusiness = {
  state: {
    stateCreateBusiness: [],
    stateDetailBusiness: [],
    stateFindTaxIDByRevenue: [],
    stateFindTaxIDByOneID: [],
    stateUploadApprover: [],
    stateUploadResultDocument: [],
    stateCheckeKYC: [],
    stateCreateBusinessOther: []
  },
  mutations: {
    mutationCreateBusiness (state, data) {
      state.stateCreateBusiness = data
    },
    mutationDetailBusiness (state, data) {
      state.stateDetailBusiness = data
    },
    mutationFindTaxIDByRevenue (state, data) {
      state.stateFindTaxIDByRevenue = data
    },
    mutationFindTaxIDByOneID (state, data) {
      state.stateFindTaxIDByOneID = data
    },
    mutationUploadApprover (state, data) {
      state.stateUploadApprover = data
    },
    mutationUploadResultDocument (state, data) {
      state.stateUploadResultDocument = data
    },
    mutationCheckeKYC (state, data) {
      state.stateCheckeKYC = data
    },
    mutationCreateBusinessOther (state, data) {
      state.stateCreateBusinessOther = data
    }
  },
  actions: {
    async actionCreateBusiness (context, access) {
      const dataFromAxios = await AxiosBusiness.axiosCreateBusiness(access)
      await context.commit('mutationCreateBusiness', dataFromAxios)
    },
    async actionDetailBusiness (context, access) {
      const dataFromAxios = await AxiosBusiness.axiosDetailBusiness(access)
      await context.commit('mutationDetailBusiness', dataFromAxios)
    },
    async actionFindTaxIDByRevenue (context, access) {
      // console.log(access)
      const dataFromAxios = await AxiosBusiness.axiosFindTaxIDByRevenue(access)
      await context.commit('mutationFindTaxIDByRevenue', dataFromAxios)
    },
    async actionFindTaxIDByOneID (context, access) {
      const dataFromAxios = await AxiosBusiness.axiosFindTaxIDByOneID(access)
      await context.commit('mutationFindTaxIDByOneID', dataFromAxios)
    },
    async actionUploadApprover (context, access) {
      const dataFromAxios = await AxiosBusiness.axiosUploadApprover(access)
      await context.commit('mutationUploadApprover', dataFromAxios)
    },
    async actionUploadResultDocument (context, access) {
      const dataFromAxios = await AxiosBusiness.axiosUploadResultDocument(access)
      await context.commit('mutationUploadResultDocument', dataFromAxios)
    },
    async actionsCheckeKYC (context) {
      const dataFromAxios = await AxiosBusiness.axiosDetailBusinessOfUser()
      await context.commit('mutationCheckeKYC', dataFromAxios)
    },
    async actionsCreateBusinessOther (context, access) {
      const dataFromAxios = await AxiosBusiness.axiosCreateBusinessOther(access)
      await context.commit('mutationCreateBusinessOther', dataFromAxios)
    }
  }
}

export default ModuleBusiness
