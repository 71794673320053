import AxiosGraczCategory from './axios_gracz_category_api'

const ModuleGraczCategory = {
  state: {
    stateGraczCategoryList: [],
    stateGraczCreateCategory: [],
    stateGraczEditCategory: [],
    stateGraczChangeStatusCategory: [],
    stateGraczDeleteCategory: []
  },
  mutations: {
    mutationsGraczCategoryList (state, data) {
      state.stateGraczCategoryList = data
    },
    mutationsGraczCreateCategory (state, data) {
      state.stateGraczCreateCategory = data
    },
    mutationsGraczEditCategory (state, data) {
      state.stateGraczEditCategory = data
    },
    mutationsGraczChangeStatusCategory (state, data) {
      state.stateGraczChangeStatusCategory = data
    },
    mutationsGraczDeleteCategory (state, data) {
      state.stateGraczDeleteCategory = data
    }
  },
  actions: {
    async actionsGraczCategoryList (context, access) {
      var response = await AxiosGraczCategory.GraczCategoryList(access)
      await context.commit('mutationsGraczCategoryList', response)
    },
    async actionsGraczCreateCategory (context, access) {
      var response = await AxiosGraczCategory.GraczCreateCategory(access)
      await context.commit('mutationsGraczCreateCategory', response)
    },
    async actionsGraczEditCategory (context, access) {
      var response = await AxiosGraczCategory.GraczEditCategory(access)
      await context.commit('mutationsGraczEditCategory', response)
    },
    async actionsGraczChangeStatusCategory (context, access) {
      var response = await AxiosGraczCategory.GraczChangeStatusCategory(access)
      await context.commit('mutationsGraczChangeStatusCategory', response)
    },
    async actionsGraczDeleteCategory (context, access) {
      var response = await AxiosGraczCategory.GraczDeleteCategory(access)
      await context.commit('mutationsGraczDeleteCategory', response)
    }
  }
}
export default ModuleGraczCategory
