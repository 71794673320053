import axios from 'axios'
import { Decode } from '@/services'

const GetToken = () => {
  if (Object.prototype.hasOwnProperty.call(localStorage, 'oneData')) {
    const oneData = JSON.parse(Decode.decode(localStorage.getItem('oneData')))
    // console.log('ONEDATA NAAAa', oneData)
    const auth = {
      headers: { Authorization: `Bearer ${oneData.user.access_token}` }
    }
    return auth
  }
}

export default {
  // manage list Department
  async GetListDepartment (data) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}api/list_department_api`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  // manage Detail Department
  async DetailDepartment (data) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}api/detail_department_api`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  // create Department
  async CreateDepartment (data) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}api/create_department_api`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  // Edit Department
  async EditDepartment (data) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}api/edit_department_api`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async DeleteDepartment (data) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}api/delete_department_api`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  }
}
