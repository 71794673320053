import AxiosGraczAdmin from './axios_gracz_admin_api'

const ModuleGraczAdmin = {
  state: {
    stateGraczListProduct: [],
    stateGraczAddProduct: [],
    stateGraczEditProduct: [],
    stateGraczChangeStatusProduct: [],
    stateGraczDeleteProduct: [],
    stateGraczExportProduct: [],
    stateGraczImportProduct: [],
    stateGraczListPanitProduct: [],
    stateGraczImportPanitProduct: []
  },
  mutations: {
    mutationsGraczListProduct (state, data) {
      state.stateGraczListProduct = data
    },
    mutationsGraczAddProduct (state, data) {
      state.stateGraczAddProduct = data
    },
    mutationsGraczEditProduct (state, data) {
      state.stateGraczEditProduct = data
    },
    mutationsGraczChangeStatusProduct (state, data) {
      state.stateGraczChangeStatusProduct = data
    },
    mutationsGraczDeleteProduct (state, data) {
      state.stateGraczDeleteProduct = data
    },
    mutationsGraczExportProduct (state, data) {
      state.stateGraczExportProduct = data
    },
    mutationsGraczImportProduct (state, data) {
      state.stateGraczImportProduct = data
    },
    mutationsGraczListPanitProduct (state, data) {
      state.stateGraczListPanitProduct = data
    },
    mutationsGraczImportPanitProduct (state, data) {
      state.stateGraczImportPanitProduct = data
    }
  },
  actions: {
    async actionsGraczListProduct (context, access) {
      var response = await AxiosGraczAdmin.GraczListProduct(access)
      await context.commit('mutationsGraczListProduct', response)
    },
    async actionsGraczAddProduct (context, access) {
      var response = await AxiosGraczAdmin.GraczAddProduct(access)
      await context.commit('mutationsGraczAddProduct', response)
    },
    async actionsGraczEditProduct (context, access) {
      var response = await AxiosGraczAdmin.GraczEditProduct(access)
      await context.commit('mutationsGraczEditProduct', response)
    },
    async actionsGraczChangeStatusProduct (context, access) {
      var response = await AxiosGraczAdmin.GraczChangeStatusProduct(access)
      await context.commit('mutationsGraczChangeStatusProduct', response)
    },
    async actionsGraczDeleteProduct (context, access) {
      var response = await AxiosGraczAdmin.GraczDeleteProduct(access)
      await context.commit('mutationsGraczDeleteProduct', response)
    },
    async actionsGraczExportProduct (context, access) {
      var response = await AxiosGraczAdmin.GraczExportProduct(access)
      await context.commit('mutationsGraczExportProduct', response)
    },
    async actionsGraczImportProduct (context, access) {
      var response = await AxiosGraczAdmin.GraczImportProduct(access)
      await context.commit('mutationsGraczImportProduct', response)
    },
    async actionsGraczListPanitProduct (context, access) {
      var response = await AxiosGraczAdmin.GraczListPanitProduct(access)
      await context.commit('mutationsGraczListPanitProduct', response)
    },
    async actionsGraczImportPanitProduct (context, access) {
      var response = await AxiosGraczAdmin.GraczImportPanitProduct(access)
      await context.commit('mutationsGraczImportPanitProduct', response)
    }
  }
}
export default ModuleGraczAdmin
