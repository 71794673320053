import axios from 'axios'
import { Decode } from '@/services'

const GetToken = () => {
  if (localStorage.getItem('oneData') !== null) {
    const oneData = JSON.parse(Decode.decode(localStorage.getItem('oneData')))
    const auth = {
      headers: { Authorization: `Bearer ${oneData.user.access_token}` }
    }
    return auth
  } else {
    const auth = ''
    return auth
  }
}

export default {
  // OrderList
  async GetProduct (val) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END2}products/search`, val, auth)
      // console.log('data orderlist', response.data)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  // orderList In manage shop
  async GetProductManageShop (val) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END2}products/searchBySeller`, val, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  // Get Product New
  async GetProductNew (val) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END2}search/product/new`, val, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  // Get BestSeller Product
  async GetProductBestSeller (val) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END2}search/product/best-seller`, val, auth)
      console.log(response.data)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  // Get Recomment Product
  async GetProductRecomment (val) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END2}search/product/recommend`, val, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  // Get All Product
  async GetAllProduct (val) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END2}search/product/all`, val, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async GetAllProductCategory (val) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END2}search/product/category`, val, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async GetBuyAgainProduct (val) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END2}search/product/history`, val, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  }
}
