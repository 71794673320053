import Vue from 'vue'
import App from './App.vue'
import VueCookies from 'vue-cookies'
import './registerServiceWorker'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import VueSweetalert2 from 'vue-sweetalert2'
import Antd from 'ant-design-vue'
import VueThailandAddress from 'vue-thailand-address'
import draggable from 'vuedraggable'
import VueHorizontalList from 'vue-horizontal-list'
import axios from 'axios'
import VueAxios from 'vue-axios'
import VueMask from 'v-mask'
import 'sweetalert2/dist/sweetalert2.min.css'
import 'ant-design-vue/dist/antd.css'
import 'vue-thailand-address/dist/vue-thailand-address.css'
import { VueperSlides, VueperSlide } from 'vueperslides'
import { CoolSelectPlugin } from 'vue-cool-select'
import VueQrcode from '@chenfengyuan/vue-qrcode'
import VueClipboard from 'vue-clipboard2'
// import VueMeta from 'vue-meta'
import CKEditor from '@ckeditor/ckeditor5-vue'
// import 'vue-cool-select/dist/themes/material-design.css'
import 'vue-cool-select/dist/themes/bootstrap.css'
import VueFileAgent from 'vue-file-agent'
import VueSlickCarousel from 'vue-slick-carousel'
import VueFileAgentStyles from 'vue-file-agent/dist/vue-file-agent.css'
import '@fortawesome/fontawesome-free/css/all.css'
import '@fortawesome/fontawesome-free/js/all.js'
import Media from '@dongido/vue-viaudio'
import VueSocialSharing from 'vue-social-sharing'
import VueSocials from 'vue-socials'
import VueSnip from 'vue-snip'
import VueApexCharts from 'vue-apexcharts'
import VueMoment from 'vue-moment'
// import moment from 'moment-timezone'
import moment from 'moment'
import Affix from 'vue-affix'
import LazyLoadDirective from './directives/LazyLoadDirective'
// datatable
// import 'bootstrap/dist/css/bootstrap.min.css'
import 'datatables.net'
import 'datatables.net-dt/css/jquery.dataTables.min.css'
import 'jszip'
import 'datatables.net-buttons-dt'
import 'datatables.net-buttons-dt/css/buttons.dataTables.min.css'
import 'datatables.net-buttons/js/buttons.colVis'
import 'datatables.net-buttons/js/buttons.flash'
import 'datatables.net-buttons/js/buttons.html5'
import 'datatables.net-buttons/js/buttons.print'
import 'datatables.net-buttons/js/dataTables.buttons'
import 'datatables.net-responsive-dt'
// import 'pdfmake'
// require('pdfmake/build/pdfmake.js')
// require('datatables.net-buttons-dt')
// require('datatables.net')
// require('datatables.net-responsive-dt')
// require('../node_modules/datatables.net-buttons-dt/css/buttons.dataTables.min.css')
// require('../node_modules/datatables.net-dt/css/jquery.dataTables.min.css')
// require('../node_modules/datatables.net-buttons/js/buttons.colVis')
// require('../node_modules/datatables.net-buttons/js/buttons.flash')
// require('../node_modules/datatables.net-buttons/js/buttons.html5')
// require('../node_modules/datatables.net-buttons/js/buttons.print')
// require('../node_modules/datatables.net-buttons/js/dataTables.buttons')
// require('pdfmake')

// import QrcodeVue from 'qrcode.vue'

// window.$ = window.jQuery = require('jquery')
require('moment/locale/th')
window.JSZip = require('jszip')
moment.locale('th')
Vue.use(VueApexCharts)
Vue.component('apexchart', VueApexCharts)

// Vue.use(QrcodeVue)
Vue.use(Affix)
Vue.use(VueSnip)
Vue.use(VueSocialSharing)
Vue.use(VueSocials)
Vue.use(VueFileAgent, VueFileAgentStyles)
Vue.use(CoolSelectPlugin)
Vue.use(CKEditor)
Vue.component('vueper-slides', VueperSlides)
Vue.component('vueper-slide', VueperSlide)
Vue.component('vue-qrcode', VueQrcode)
Vue.use(VueAxios, axios)
Vue.use(VueSweetalert2)
Vue.use(VueCookies)
Vue.use(Antd)
Vue.use(VueThailandAddress)
Vue.use(draggable)
Vue.use(VueHorizontalList)
VueClipboard.config.autoSetContainer = true
Vue.use(VueClipboard)
// Vue.use(VueMeta, { refreshOnceOnNavigation: true })
Vue.use(VueMask)
Vue.use(Media)
Vue.use(VueMoment, {
  moment
})

Vue.use({
  install: function (Vue, options) {
    Vue.prototype.$jQuery = require('jquery')
  }
})
Vue.filter('formatDate', function (val) {
  if (val) {
    return moment(String(val)).format('MM-DD-YYYY')
  }
})
Vue.config.productionTip = false

Vue.directive('lazyload', LazyLoadDirective)

Vue.prototype.$EventBus = new Vue()
new Vue({
  router,
  store,
  vuetify,
  VueSlickCarousel,
  render: function (h) { return h(App) }
}).$mount('#app')
