import AxiosShop from './axios_shop_api'
import eventBus from '@/components/eventBus'
// import Converter from '@/components/library/tableDataForm'
// import moment from 'moment'

const ModuleShop = {
  state: {
    // Shop Detail Page
    stateShopDetailPage: [],
    // Create Shop Page
    stateCreateSellerShop: [],
    // Get Shop Data
    stateShopData: [],
    // Create Setting Shop
    stateCreateSettingSellerShop: [],
    // Delete product
    stateDeleteProduct: [],
    // product select option
    SelectOption: [],
    // update shop address
    stateUpdateshopaddress: [],
    stategetUpdateshopaddress: [],
    stateDashboard: [],
    stateDashboard2: [],
    stateBeforeDashboard: [],
    setKey: [],
    stateGetListPartner: [],
    staterequest: [],
    stateSeries: [],
    stateMax: 0,
    stateColor: [],
    tmpObj: {},
    tmpJson: [],
    stateListRefundSeller: [],
    stateApproveRefundSeller: [],
    stateDetailRefundSeller: [],
    transactionNumber: '',
    stateNotiRefundSeller: [],
    stateDateTime: [],
    stateTimeline: [],
    stateProductBestSeller: [],
    stateUserMostBuyProduct: [],
    stateListRefMerchant: [],
    stateRefMerchant: [],
    stateNewRefMerchant: [],
    stateFuntionChange: {},
    keyReload: '0',
    dateBus: {},
    stateCreateShop: [],
    stateDatailShop: [],
    stateCategory: [],
    stateDetailDocumentShop: [],
    stateEditShop: [],
    stateShowProductShop: [],
    stateDetailMerchantFromPayment: [],
    stateDetailSellerPartner: [],
    stateEditPartner: [],
    stateCreatePosition: [],
    stateEditPodition: [],
    stateListCompanyPorsition: [],
    stateSearchUserCompany: [],
    stateDetailUserCompany: [],
    stateList_Appover: [],
    stateAdd_Appover: [],
    stateEdit_Appover: [],
    openModalPurchaseOrder: false,
    formData: {
      customerName: 'ประยุทธ์ อาทิตย์โอชา',
      detailsProduct: [
        { description: 'ATK 100 อัน 58 บาทThe v-btn component replaces the standard html button with a material design theme and a multitude of options. Any color helper class can be used to alter the background or text color. Try out an interactive screencast on how Vuetify buttons work.', quantity: 3, unit: 'อัน', cost: 53 }
      ],
      address: '320 หมู่ 23 ตำบล บ้านเป็ด อำเภอเมืองขอนแก่น จังหวัดขอนแก่น 40000',
      taxpayerIdentificationNo: '',
      quotationNumber: '',
      date: '',
      conditional: '',
      credit: '',
      signaturePersonality1: 'วรวุฒิ ธิดากร',
      signaturePersonality2: 'วินัย ธรรมนัส',
      signaturePersonality3: 'Alex choc',
      thaiIncluding: ''
    },
    // Get Review Prodoct
    stateGetReviewProduct: [],
    stateListQuotationSeller: [],
    stateListUserCompany: [],
    stateDetailUserCompanyPosition: [],
    stateEditPositionCompany: [],
    stateCreatePositionCompany: [],
    stateList_ManageBuyer: [],
    stateReport: [],
    stateDetail_ManageBuyer: [],
    stateAppoverQU: [],
    // (YaPalm)Seller List Order Credit Term
    stateListOrderCreditTerm: [],
    stateListCreditTerm: [],
    stateUploadInvoiceCreditTerm: [],
    stateListRequestChangeTermBySellerShop: [],
    stateUpdateRequestCreditTerm: [],
    // (Ya)List Special Price Selller
    stateListSpecialPriceSeller: [],
    stateDetailSpecialPrice: [],
    stateDetailSpecialPriceSeller: [],
    stateApproveSpecialPrice: [],
    stateCalculateSpecialPrice: [],
    stateEditSpecialPrice: [],
    stateCreateMerchant: [],
    stateCheckMerchant: [],
    // stateListPositionSeller: []
    stateCreateUpdatePositionInShop: [],
    stateGetPositionDetailInShop: [],
    stateListPositionInShop: [],
    stateManageUserWithRoleInShop: [],
    stateIndexAddProduct: '',
    // shop coupon
    stateListShopCoupon: [],
    stateDetailShopCoupon: [],
    stateDeleteShopCoupon: [],
    stateCreateShopCoupon: [],
    stateSearchCoupon: [],
    stateEditShopCoupon: [],
    // Credit Term Revenue
    stateDetailMerchantCrtermFromPayment: [],
    stateRefShareCreditTermToMerchant: [],
    stateNewGetDashboard: [],
    state10User: [],
    state10Product: [],
    stateUpdatePositionStatus: [],
    // Check User Business
    stateCheckUserBusiness: [],
    stateListAllShop: []
  },
  getters: {
    drawChart (state) {
      return state.stateSeries
    },
    dataChart (state) {
      return JSON.stringify(state.setKey)
    },
    chartOption (state) {
      return state.stateMax
    },
    chartColor (state) {
      return state.stateColor
    },
    jsonCopy (state) {
      var keys = JSON.stringify(state.tmpJson).toString()
      var key = '/[]/'
      const JsonS = keys.split(key)
      return JsonS.toString()
    },
    chartDate (state) {
      return state.stateDateTime
    },
    topProduct (state) {
      return state.stateNewGetDashboard.top_product
    },
    topBuyer (state) {
      return state.stateNewGetDashboard.top_user
    },
    // allReturn (state) {
    //   return state.stateListRefundSeller
    // },
    // approveReturn (state) {
    //   return state.stateListRefundSeller.data.waiting.filter(approve => {
    //     return approve.
    //   }),
    allReturn: (state) => (status) => {
      return state.stateListRefundSeller.data.waiting.filter(waiting => waiting.status_refund === status)
    },
    filterDate: (state) => (...as) => {
      // console.log('as[0]:', as[0], 'as[1]:', as[1])
      const data = state.stateDashboard.data.filter(date => {
        var myDate = date.date.split('-')
        var newDate = new Date(myDate[2], myDate[1] - 1, myDate[0])
        // console.log('newDate.getTime:', newDate.getTime())
        if (newDate.getTime() >= as[0] && newDate.getTime() <= as[1]) {
          return date
        }
      })
      return data
    },
    groupBy: (state) => (...month) => {
      // var months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
    }
  },
  mutations: {
    mutaionsCategory (state, data) {
      state.stateCategory = data
    },
    // Check User Business
    mutationsCheckUserBusiness (state, data) {
      state.stateCheckUserBusiness = data
    },
    // Shop Detail Page
    mutationsShopDetailPage (state, data) {
      state.stateShopDetailPage = data
    },
    // Create Shop Page
    mutationsCreateSellerShop (state, data) {
      state.stateCreateSellerShop = data
    },
    // Get Shop Data
    mutationsGetShopData (state, data) {
      state.stateShopData = data
    },
    mutationsCreateSettingeSellerShop (state, data) {
      state.stateCreateSettingSellerShop = data
    },
    // delete Product
    mutationsDeleteProduct (state, data) {
      state.stateDeleteProduct = data
    },
    // product select option
    mutationsSelectOption (state, data) {
      state.SelectOption = data
    },
    // update shop address
    mutationsUpdateShop (state, data) {
      state.stateUpdateshopaddress = data
    },
    mutationsgetUpdateShop (state, data) {
      state.stategetUpdateshopaddress = data
    },
    async mutationsDashboard (state, data) {
      var date = await []
      state.stateDashboard = await []
      state.stateDashboard = await data
      var DataValues = await []
      DataValues = await data.data.map(x => {
        var myDate = x.date.split(' ')
        var timeParts = myDate[1].split(':')
        var dateParts = myDate[0].split('-')
        var newDate = new Date(dateParts[2], parseInt(dateParts[1], 10) - 1, dateParts[0], timeParts[0], timeParts[1])
        return [String(newDate.getTime()).substring(0, 5), x.value]
      })
      // var DataValues = data.data.map(x => {
      //   return [String(Date.parse(x.date)), x.value]
      // })
      // console.log('unstate', DataValues)
      date = await new Set(data.data.map(el => {
        return el.date
      }))
      var sum = {}
      for (const x of DataValues) {
        // console.log('C :', sum[c[0]])
        // if (sum[c[0]] === undefined) {
        //   sum[c[0]] = c
        //   console.log('unSum', sum[c[0]])
        // }
        if (undefined === sum[x[0]]) {
          sum[x[0]] = x
          // console.log('undefined = ', sum[x[0]])
        } else {
          sum[x[0]][1] += x[1]
          // console.log('unSum ', sum)
        }
      }
      state.stateTimeline = await Object.keys(sum).map((val) => { return [Number(sum[val][0] + '00000000'), sum[val][1]] })
      state.stateDateTime = await [...date]
      // console.log('unstateDateTime', state.stateTimeline)
    },
    async mutationsDashboard2 (state, data) {
      // console.log('SSmutationsDashboard2', data)
      state.stateDashboard2 = data
    },
    mutationsListRefundSeller (state, data) {
      state.stateListRefundSeller = data
    },
    mutationsApproveRefundSeller (state, data) {
      state.stateApproveRefundSeller = data
    },
    mutationsDetailRefundSeller (state, data) {
      state.stateDetailRefundSeller = data
    },
    mutationsNotiRefundSeller (state, data) {
      state.stateNotiRefundSeller = data
    },
    async mutationsSetChart (state, data = '') {
      // console.log('TEST+3:', data)
      // state.stateSeries = await []
      // state.tmpObj = await []
      // state.stateMax = await []
      // state.setKey = await []
      // var temp = await []
      var temp = await state.stateDashboard
      // console.log('TEST+4:', state.stateDashboard)
      // console.log('TEST+5:', temp)
      for (var o in data) {
        state.tmpObj[o] = data[o]
        state.tmpJson.push(data)
      }
      // console.log('TEST+6:', state.tmpObj)
      // console.log('temp', temp)
      // var Xc = await []
      var Xc = await temp.data.filter((el) => {
        for (const key in state.tmpObj) {
          if (el[key] === undefined || el[key] !== state.tmpObj[key]) {
            return false
          }
        }
        return { ...el }
      })
      // await console.log('TEST+7:', Xc)
      state.stateMax = await Math.max.apply(Math, Xc.map(o => o.value))
      // console.log('TEST+4', Xc)
      // console.log('stateMax', state.stateMax)
      // console.log('Object**', data)
      var val = await {
        name: data.buyer_name,
        data: Xc.map(e => { return Number(e.value) })
      }
      // console.log('TEST+5', val)
      // console.log('LineChar - Val', val)
      // const dataForm = await val
      // console.log('DataForm', val)
      await state.stateSeries.push(val)
      // console.log('LineChar - Seriers', state.stateSeries)
      // await state.setKey.push(data)
      // console.log('TTbefore')
      state.stateDashboard = await { data: Xc }
      // console.log('TTafter', state.stateDashboard)
      eventBus.$emit('selectDataFilter')
    },
    mutationsProductBestSeller (state, data) {
      state.stateProductBestSeller = data
    },
    mutationsUserMostBuyProduct (state, data) {
      state.stateUserMostBuyProduct = data
    },
    mutationsListRefMerchant (state, data) {
      state.stateListRefMerchant = data
    },
    mutationsRefMerchant (state, data) {
      state.stateRefMerchant = data
    },
    mutationsCreateShop (state, data) {
      state.stateCreateShop = data
    },
    mutationsDetailShop (state, data) {
      state.stateDatailShop = data
    },
    mutationsEditShop (state, data) {
      state.stateEditShop = data
    },
    mutationsShowProductShop (state, data) {
      state.stateShowProductShop = data
    },
    mutationsDetailMerchantFromPayment (state, data) {
      state.stateDetailMerchantFromPayment = data
    },
    // Get Review Prodoct
    mutationsGetReviewProduct (state, data) {
      state.stateGetReviewProduct = data
    },
    // Get list Partner
    mutationsGetListPratner (state, data) {
      state.stateGetListPartner = data
    },
    // Post Partner new
    mutationsPostPartner (state, data) {
      state.staterequest = data
    },
    //
    mutationsDetailDocumentShop (state, data) {
      state.stateDetailDocumentShop = data
    },
    // seller_partner
    mutationsDetailSellerPartner (state, data) {
      state.stateDetailSellerPartner = data
    },
    // Edit partner
    mutationsEditPartner (state, data) {
      state.stateEditPartner = data
    },
    // Get List Quotation seller
    mutationsListQuotationSeller (state, data) {
      state.stateListQuotationSeller = data
    },
    // list_user_company
    mutationsListUserCompany (state, data) {
      state.stateListUserCompany = data
    },
    mutationsDetailUserCompanyPosition (state, data) {
      state.stateDetailUserCompanyPosition = data
    },
    mutationsCreatePosition (state, data) {
      state.stateCreatePosition = data
    },
    mutationsEditPodition (state, data) {
      state.stateEditPodition = data
    },
    mutationsListCompanyPosition (state, data) {
      state.stateListCompanyPorsition = data
    },
    mutationsSearchUserCompany (state, data) {
      state.stateSearchUserCompany = data
    },
    mutationsDetailUserCompany (state, data) {
      state.stateDetailUserCompany = data
    },
    // Edit Position User Company
    mutationsEditPositionCompany (state, data) {
      state.stateEditPositionCompany = data
    },
    // Create Position User Company
    mutationsCreatePositionCompany (state, data) {
      state.stateCreatePositionCompany = data
    },
    // List Manage buyer
    mutationsList_ManageBuyer (state, data) {
      state.stateList_ManageBuyer = data
    },
    mutationsReport (state, data) {
      state.stateReport = data
    },
    // Detail Manage Buyer
    mutationDetailManageBuyer (state, data) {
      state.stateDetail_ManageBuyer = data
    },
    mutationListAppover (state, data) {
      state.stateList_Appover = data
    },
    mutationAddAppover (state, data) {
      state.stateAdd_Appover = data
    },
    mutationEditAppover (state, data) {
      state.stateEdit_Appover = data
    },
    mutationAppoverQU (state, data) {
      state.stateAppoverQU = data
    },
    // (YaPalm)List Order Credit Term
    mutationsListOrderCreditTerm (state, data) {
      state.stateListOrderCreditTerm = data
    },
    // (YaPalm)List Credit Term
    mutationsListCreditTerm (state, data) {
      state.stateListCreditTerm = data
    },
    // (YaPalm)List Credit Term
    mutationsUploadInvoiceCreditTerm (state, data) {
      state.stateUploadInvoiceCreditTerm = data
    },
    // (YaPalm)List Request New Credit Term
    mutationsListRequestChangeTermBySellerShop (state, data) {
      state.stateListRequestChangeTermBySellerShop = data
    },
    // (YaPalm)List Request New Credit Term
    mutationsUpdateRequestCreditTerm (state, data) {
      state.stateUpdateRequestCreditTerm = data
    },
    // (Ya)List Special Price Seller
    mutationsListSpecialPriceSeller (state, data) {
      state.stateListSpecialPriceSeller = data
    },
    // (Ya)Detail Special Price Seller
    mutationsDetailSpecialPrice (state, data) {
      state.stateDetailSpecialPrice = data
    },
    // (Non) New Detail Special Price Seller
    mutationsDetailSpecialPriceSeller (state, data) {
      state.stateDetailSpecialPriceSeller = data
    },
    // (Ya)Approve Special Price Seller
    mutationsApproveSpecialPrice (state, data) {
      state.stateApproveSpecialPrice = data
    },
    mutationsCalculateSpecialPrice (state, data) {
      state.stateCalculateSpecialPrice = data
      // console.log('mutation pass')
    },
    mutationsEditSpecialPrice (state, data) {
      state.stateEditSpecialPrice = data
    },
    // (Ya)CreateMerchant
    mutationsCheckMerchant (state, data) {
      state.stateCheckMerchant = data
    },
    mutationsCreateMerchant (state, data) {
      state.stateCreateMerchant = data
    },
    mutatonCreateUpdatePositionInShop (state, data) {
      state.stateCreateUpdatePositionInShop = data
    },
    mutatonGetPositionDetailInShop (state, data) {
      state.stateGetPositionDetailInShop = data
    },
    mutatonListPositionInShop (state, data) {
      state.stateListPositionInShop = data
    },
    mutatonManageUserWithRoleInShop (state, data) {
      state.stateManageUserWithRoleInShop = data
    },
    // shop coupon
    mutatonListShopCoupon (state, data) {
      state.stateListShopCoupon = data
    },
    mutatonDetailShopCoupon (state, data) {
      state.stateDetailShopCoupon = data
    },
    mutatonDeleteShopCoupon (state, data) {
      state.stateDeleteShopCoupon = data
    },
    mutationCreateShopCoupon (state, data) {
      state.stateCreateShopCoupon = data
    },
    mutationSearchCoupon (state, data) {
      state.stateSearchCoupon = data
    },
    mutationEditShopCoupon (state, data) {
      state.stateEditShopCoupon = data
    },
    // Credit Term Revenue
    mutationDetailMerchantCrterm (state, data) {
      state.stateDetailMerchantCrtermFromPayment = data
    },
    mutationRefShareCreditTerm (state, data) {
      state.stateRefShareCreditTermToMerchant = data
    },
    mutationNewGetDashboard (state, data) {
      state.stateNewGetDashboard = data
    },
    mutationUpdatePositionStatus (state, data) {
      state.stateUpdatePositionStatus = data
    },
    mutationsNewRefMerchant (state, data) {
      state.stateNewRefMerchant = data
    },
    mutationsListAllShop (state, data) {
      state.stateListAllShop = data
    }
  },
  actions: {
    async actionCategory (context) {
      const response = await AxiosShop.getCategory()
      await context.commit('mutaionsCategory', response)
    },
    // check User Business
    async actionsCheckUserBusiness (context) {
      const responseData = await AxiosShop.CheckUserBusiness()
      await context.commit('mutationsCheckUserBusiness', responseData)
    },
    // Shop Detail Page
    async actionsShopDetailPage (context, access) {
      const responseData = await AxiosShop.GetShopDetailPage(access)
      await context.commit('mutationsShopDetailPage', responseData)
    },
    // Create Shop Page
    async actionCreateSellerShop (context, access) {
      const responseData = await AxiosShop.CreateSellerShop(access)
      await context.commit('mutationsCreateSellerShop', responseData)
    },
    // Get Shop Data
    async actionsGetShopData (context, access) {
      const responseShopData = await AxiosShop.GetSellerShop(access)
      await context.commit('mutationsGetShopData', responseShopData)
    },
    async actionCreateSettingSellerShop (context, access) {
      const responseData = await AxiosShop.CreateSettingSellerShop(access)
      await context.commit('mutationsCreateSettingeSellerShop', responseData)
    },
    // delete Product
    async actionsDeleteProduct (context, access) {
      var responseData = await AxiosShop.deleteProduct(access)
      await context.commit('mutationsDeleteProduct', responseData)
    },
    // product select option
    async actionsSelectOption (context, access) {
      var responseData = await AxiosShop.GetSelectOption(access)
      await context.commit('mutationsSelectOption', responseData)
    },
    // Update Shop address
    async actionsUpdateShopAddress (context, access) {
      var responseData = await AxiosShop.UpShopaddresss(access)
      await context.commit('mutationsUpdateShop', responseData)
    },
    async actionsgetUpdateShopAddress (context, access) {
      var responseData = await AxiosShop.GetUpShopaddresss(access)
      await context.commit('mutationsgetUpdateShop', responseData)
    },
    async actionsDashboard (context, access) {
      // console.log('TEST++Access', access)
      var responseData = await AxiosShop.Dashboard(access)
      // console.log('TEST++responseData', responseData)
      await context.commit('mutationsDashboard', responseData)
    },
    async actionsDashboard2 (context, access) {
      // console.log('SSactionsDashboard2', access)
      var responseData = await AxiosShop.Dashboard2(access)
      await context.commit('mutationsDashboard2', responseData)
    },
    async actionsSetChart (context, access) {
      // console.log('TEST+2', access)
      await context.commit('mutationsSetChart', access)
    },
    async actionsListRefundSeller (context, access) {
      var responseData = await AxiosShop.PostListRefundSeller(access)
      await context.commit('mutationsListRefundSeller', responseData)
    },
    async actionsDetailRefundSeller (context, access) {
      var responseData = await AxiosShop.PostDetailRefundSeller(access)
      await context.commit('mutationsDetailRefundSeller', responseData)
    },
    async actionsNotiRefundSeller (context, access) {
      var responseData = await AxiosShop.PostNotiRefundSeller(access)
      await context.commit('mutationsNotiRefundSeller', responseData)
    },
    async actionsProductBestSeller (context, access) {
      var responseData = await AxiosShop.GetProductBestSeller(access)
      await context.commit('mutationsProductBestSeller', responseData)
    },
    async actionsUserMostBuyProduct (context, access) {
      var responseData = await AxiosShop.GetUserMostBuyProduct(access)
      await context.commit('mutationsUserMostBuyProduct', responseData)
    },
    async actionsListRefMerchant (context, access) {
      var responseData = await AxiosShop.GetListRefMerchant(access)
      await context.commit('mutationsListRefMerchant', responseData)
    },
    async actionsRefMerchant (context, access) {
      var responseData = await AxiosShop.GetRefMerchant(access)
      await context.commit('mutationsRefMerchant', responseData)
    },
    async actionsNewRefMerchant (context, access) {
      var responseData = await AxiosShop.GetNewRefMerchant(access)
      await context.commit('mutationsNewRefMerchant', responseData)
    },
    async actionsCreateShop (context, access) {
      var responseData = await AxiosShop.CreateShop(access)
      await context.commit('mutationsCreateShop', responseData)
    },
    async actionDetailShop (context, access) {
      var responseData = await AxiosShop.DetailShop(access)
      await context.commit('mutationsDetailShop', responseData)
    },
    async actionEditShop (context, access) {
      var responseData = await AxiosShop.EditShop(access)
      await context.commit('mutationsEditShop', responseData)
    },
    async actionShowProductShop (context, access) {
      var responseData = await AxiosShop.ShowProductShop(access)
      await context.commit('mutationsShowProductShop', responseData)
    },
    async actionDetailMerchantFromPayment (context, access) {
      var responseData = await AxiosShop.DetailMerchantFromPayment(access)
      await context.commit('mutationsDetailMerchantFromPayment', responseData)
    },
    // Get Review Prodoct
    async actionsGetReviewProduct (context, access) {
      var responseData = await AxiosShop.GetReviewProduct(access)
      await context.commit('mutationsGetReviewProduct', responseData)
    },
    // List Partner
    async actionsListPartnerBuyer (context, access) {
      var responseData = await AxiosShop.GetListPartnerBuyer(access)
      await context.commit('mutationsGetListPratner', responseData)
    },
    // Post new request partner
    async actionsPostPartnerBuyer (context, access) {
      var responseData = await AxiosShop.PostRequestPartnerBuyer(access)
      await context.commit('mutationsPostPartner', responseData)
    },
    // detail_document_shop
    async actionsDetailDocumentShop (context, access) {
      var responseData = await AxiosShop.DetailDocumentShop(access)
      await context.commit('mutationsDetailDocumentShop', responseData)
    },
    // detail_seller_partner
    async actionsDetailSellerpartner (context, access) {
      var responseData = await AxiosShop.DetailSellerPartner(access)
      await context.commit('mutationsDetailSellerPartner', responseData)
    },
    // edit partner
    async actionsEditPartner (context, access) {
      var responseData = await AxiosShop.EditPartner(access)
      await context.commit('mutationsEditPartner', responseData)
    },
    // List Quotation Seller
    async actionsListQuotationSeller (context, access) {
      var responseData = await AxiosShop.ListQuotationSeller(access)
      await context.commit('mutationsListQuotationSeller', responseData)
    },
    async actionsListUserCompanyPosition (context, access) {
      var responseData = await AxiosShop.ListUserCompany(access)
      await context.commit('mutationsListUserCompany', responseData)
    },
    async actionsDetailUserCompanyPosition (context, access) {
      var responseData = await AxiosShop.DetailUserCompanyPosition(access)
      await context.commit('mutationsDetailUserCompanyPosition', responseData)
    },
    // create business
    async actionsCreatePositon (context, access) {
      var responseData = await AxiosShop.CreatePositon(access)
      await context.commit('mutationsCreatePosition', responseData)
    },
    // Edit Position company
    async avtionsEditPosition (context, access) {
      var responseData = await AxiosShop.EditPosition(access)
      await context.commit('mutationsEditPodition', responseData)
    },
    // List company Position
    async actionsListCompanyPosition (context, access) {
      var responseData = await AxiosShop.ListCompanyPosition(access)
      await context.commit('mutationsListCompanyPosition', responseData)
    },
    // Search User company
    async actionsSearchUsercompany (context, access) {
      var responseData = await AxiosShop.SearchUserCompany(access)
      await context.commit('mutationsSearchUserCompany', responseData)
    },
    // Detail User company
    async actionsDetailUserCompany (context, access) {
      var responseData = await AxiosShop.DetailUserCompany(access)
      await context.commit('mutationsDetailUserCompany', responseData)
    },
    // Edit Position User Company
    async actionsEditPositionCompany (context, access) {
      var responseData = await AxiosShop.EditPositionCompany(access)
      await context.commit('mutationsEditPositionCompany', responseData)
    },
    // Create Position User Company
    async actionCreatePositonUser (context, access) {
      var responseData = await AxiosShop.CreatePositionCompany(access)
      await context.commit('mutationsCreatePositionCompany', responseData)
    },
    // List Manage buyer
    async actionsList_ManageBuyer (context, access) {
      var responseData = await AxiosShop.ListManageBuyer(access)
      await context.commit('mutationsList_ManageBuyer', responseData)
    },
    async actionsReport (context, access) {
      var responseData = await AxiosShop.PostReport(access)
      await context.commit('mutationsReport', responseData)
    },
    async actionsDetail_ManageBuyer (context, access) {
      var responseData = await AxiosShop.DetailManageBuyer(access)
      await context.commit('mutationDetailManageBuyer', responseData)
    },
    // list Apppover
    async actionsList_Appover (context, access) {
      var responseData = await AxiosShop.ListAppover(access)
      await context.commit('mutationListAppover', responseData)
    },
    // add Appover
    async actionAdd_Appover (context, access) {
      var responseData = await AxiosShop.AddAppover(access)
      await context.commit('mutationAddAppover', responseData)
    },
    // Edit Appover
    async actionEdit_Appover (context, access) {
      var responseData = await AxiosShop.EditAppover(access)
      await context.commit('mutationEditAppover', responseData)
    },
    // Approve Model  Qu
    async Approve_QU (context, access) {
      var responseData = await AxiosShop.AppoverQU(access)
      await context.commit('mutationAppoverQU', responseData)
    },
    // (YaPalm)Seller List Order Credit Term
    async actionsSellerListOrderCreditTerm (context, access) {
      var response = await AxiosShop.listOrderCreditTerm(access)
      await context.commit('mutationsListOrderCreditTerm', response)
    },
    // (YaPalm)Seller List Creadit Term
    async actionsUploadInvoiceCreditTerm (context, access) {
      const response = await AxiosShop.uploadInvoiceCreditTerm(access)
      await context.commit('mutationsUploadInvoiceCreditTerm', response)
    },
    // (YaPalm)Seller List Creadit Term
    async actionsSellerListCreditTerm (context, access) {
      const response = await AxiosShop.listCreditTerm(access)
      await context.commit('mutationsListCreditTerm', response)
    },
    // (YaPalm)Seller Request New Creadit Term
    async actionsListRequestChangeTermBySellerShop (context, access) {
      const response = await AxiosShop.listRequestChangeTermBySellerShop(access)
      await context.commit('mutationsListRequestChangeTermBySellerShop', response)
    },
    // (YaPalm)Seller Request New Creadit Term
    async actionsUpdateRequestCreditTerm (context, access) {
      const response = await AxiosShop.updateRequestCreditTerm(access)
      await context.commit('mutationsUpdateRequestCreditTerm', response)
    },
    // (Ya)Seller List Special Price Seller
    async actionsListSpecialPriceSeller (context, access) {
      const response = await AxiosShop.listSpecialPriceSeller(access)
      await context.commit('mutationsListSpecialPriceSeller', response)
    },
    // (Ya)Seller Detail Special Price Seller
    async actionsDetailSpecialPrice (context, access) {
      const response = await AxiosShop.detailSpecialPrice(access)
      await context.commit('mutationsDetailSpecialPrice', response)
    },
    // (Ya) New Seller Detail Special Price Seller
    async actionsDetailSpecialPriceSeller (context, access) {
      const response = await AxiosShop.newDetailSpecialPrice(access)
      await context.commit('mutationsDetailSpecialPriceSeller', response)
    },
    // (Ya)Seller Approve Special Price Seller
    async actionsApproveSpecialPrice (context, access) {
      const response = await AxiosShop.approveSpecialPrice(access)
      await context.commit('mutationsApproveSpecialPrice', response)
    },
    // (Ya)Seller Calculate Special Price Seller
    async actionsCalculateSpecialPrice (context, access) {
      const response = await AxiosShop.calculateSpecialPrice(access)
      await context.commit('mutationsCalculateSpecialPrice', response)
      // console.log('action pass')
    },
    // (Ya)Seller Edit Special Price Seller
    async actionsEditSpecialPrice (context, access) {
      const response = await AxiosShop.editSpecialPrice(access)
      await context.commit('mutationsEditSpecialPrice', response)
      // console.log('action pass')
    },
    // (Ya)Merchant
    async actionsCheckMerchant (context, access) {
      const response = await AxiosShop.checkMerchant(access)
      await context.commit('mutationsCheckMerchant', response)
    },
    async actionsCreateMerchant (context, access) {
      const response = await AxiosShop.createMerchant(access)
      await context.commit('mutationsCreateMerchant', response)
    },
    async actionCreateUpdatePositionInShop (context, access) {
      var responseData = await AxiosShop.createUpdatePositionInShop(access)
      await context.commit('mutatonCreateUpdatePositionInShop', responseData)
    },
    async actionGetPositionDetailInShop (context, access) {
      var responseData = await AxiosShop.getPositionDetailInShop(access)
      await context.commit('mutatonGetPositionDetailInShop', responseData)
    },
    async actionListPositionInShop (context, access) {
      // console.log('actionListPositionInShop2')
      var responseData = await AxiosShop.listPositionInShop(access)
      await context.commit('mutatonListPositionInShop', responseData)
    },
    async actionManageUserWithRoleInShop (context, access) {
      var responseData = await AxiosShop.manageUserWithRoleInShop(access)
      await context.commit('mutatonManageUserWithRoleInShop', responseData)
    },
    // shop coupon
    async actionListShopCoupon (context, access) {
      var responseData = await AxiosShop.listShopCoupon(access)
      await context.commit('mutatonListShopCoupon', responseData)
    },
    async actionDetailShopCoupon (context, access) {
      var responseData = await AxiosShop.detailShopCoupon(access)
      await context.commit('mutatonDetailShopCoupon', responseData)
    },
    async actionDeleteShopCoupon (context, access) {
      var responseData = await AxiosShop.deleteShopCoupon(access)
      await context.commit('mutatonDeleteShopCoupon', responseData)
    },
    async actionCreateShopCoupon (context, access) {
      var responseData = await AxiosShop.createShopCoupon(access)
      await context.commit('mutationCreateShopCoupon', responseData)
    },
    async actionSearchCoupon (context, access) {
      var responseData = await AxiosShop.searchCoupon(access)
      await context.commit('mutationSearchCoupon', responseData)
    },
    async actionEditShopCoupon (context, access) {
      var responseData = await AxiosShop.editShopCoupon(access)
      await context.commit('mutationEditShopCoupon', responseData)
    },
    // Credit Term Revenue
    async actionDetailMerchantCrterm (context, access) {
      var responseData = await AxiosShop.DetailMerchantCrterm(access)
      await context.commit('mutationDetailMerchantCrterm', responseData)
    },
    async actionRefShareCreditTerm (context, access) {
      var responseData = await AxiosShop.RefShareCreditTerm(access)
      await context.commit('mutationRefShareCreditTerm', responseData)
    },
    async actionNewGetDashboard (context, access) {
      var responseData = await AxiosShop.NewGetDashboard(access)
      await context.commit('mutationNewGetDashboard', responseData)
    },
    async actionUpdatePositionStatus (context, access) {
      var responseData = await AxiosShop.UpdatePositionStatus(access)
      await context.commit('mutationUpdatePositionStatus', responseData)
    },
    async actionListAllShop (context, access) {
      var responseData = await AxiosShop.GetListAllShop(access)
      await context.commit('mutationsListAllShop', responseData)
    }
  }
}
export default ModuleShop
