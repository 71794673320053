import axios from 'axios'
import { Decode } from '@/services'

const GetToken = () => {
  if (Object.prototype.hasOwnProperty.call(localStorage, 'oneData')) {
    const oneData = JSON.parse(Decode.decode(localStorage.getItem('oneData')))
    const auth = {
      headers: { Authorization: `Bearer ${oneData.user.access_token}` }
    }
    return auth
  }
}

export default {
  async GraczListEmployee (data) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}api/g/list_employee`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async GraczListEmployeeSales (data) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}api/g/list_employee_sales`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async GraczDetailEmployee (data) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}api/g/detail_employee`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async GraczSearchEmployee (data) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}api/g/search_employee`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async GraczCreateEmployee (data) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}api/g/create_employee`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async GraczUpdateStatusEmployee (data) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}api/g/update_status_employee`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async GraczEditEmployee (data) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}api/g/edit_employee`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async GraczListSalesEmployee (data) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}api/g/list_sales_employee`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async GraczUserDetailEmployee (data) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}api/g/user_detail_employee`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async GraczUpdateProfileEmployee (data) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}api/g/update_profile_employee`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  }
}
