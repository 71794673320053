import AxiosNotification from './axios_notification_api'

const ModuleNotification = {
  state: {
    stateListNotification: [],
    stateUpdateNotification: []
  },
  mutations: {
    mutationsListNotification (state, data) {
      state.stateListNotification = data
    },
    mutationsUpdateNotification (state, data) {
      state.stateUpdateNotification = data
    }
  },
  actions: {
    async actionsListNotification (context, data) {
      var response = await AxiosNotification.ListNotification(data)
      await context.commit('mutationsListNotification', response)
    },
    async actionsUpdateNotification (context, data) {
      var response = await AxiosNotification.UpdateNotification(data)
      await context.commit('mutationsUpdateNotification', response)
    }
  }
}

export default ModuleNotification
