import axios from 'axios'
import { Decode } from '@/services'

const GetToken = () => {
  if (localStorage.getItem('oneData') !== null) {
    const oneData = JSON.parse(Decode.decode(localStorage.getItem('oneData')))
    const auth = {
      headers: { Authorization: `Bearer ${oneData.user.access_token}` }
    }
    return auth
  } else {
    const auth = ''
    return auth
  }
}

export default {
  async transaction (data) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END4}panit/dashboard/transection`, data, auth)
      // console.log(response.data)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async Top10Sellers (data) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END4}panit/dashboard/top10sellers`, data, auth)
      // console.log(response.data)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async Top10Buyer (data) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END4}panit/dashboard/top10buyers`, data, auth)
      // console.log(response.data)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async Top10Purchaser (data) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END4}panit/dashboard/top10purchasers`, data, auth)
      // console.log(response.data)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async ListOrderApprove (data) {
    const auth = await GetToken()
    // const auth = {
    //   headers: { Authorization: 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsImp0aSI6IjY1NzMzZGUyODk3MzYxOGYzYjhhZWNkOTNjMDI4OGQ5YjZiYzZjYmFjZjdhNzdhMzg1NTRkY2I5NzUzYTg4ZjRhMmMyN2QyYTFjZjM2ZmMzIn0.eyJhdWQiOiIzNDAiLCJqdGkiOiI2NTczM2RlMjg5NzM2MThmM2I4YWVjZDkzYzAyODhkOWI2YmM2Y2JhY2Y3YTc3YTM4NTU0ZGNiOTc1M2E4OGY0YTJjMjdkMmExY2YzNmZjMyIsImlhdCI6MTY2MTgzMDA2MywibmJmIjoxNjYxODMwMDYzLCJleHAiOjE3NDgxNDM2NjMsInN1YiI6IjYzMDcwMDcwNDgiLCJzY29wZXMiOlsidGl0bGUiLCJmaXJzdF9uYW1lIiwibGFzdF9uYW1lIiwiaWRfY2FyZCIsImJpcnRoX2RhdGUiLCJlbWFpbCIsInRlbCIsInBpYyJdfQ.c-RejTdnotIUMnDWbDxaWao0MO55ZtTEt5v5mu8_L1hsCB2LhLHdkAMHdPgZKcjI7lWQ5n7MZkHj5SX0kUyMxnn4wxwX-AG79pL1borQ7DRFPo3Qjg4ey6g7h-GHIggfH5tpPOJ4vXHFqedyJRMRhXk9bDOEsB3X1tFQTn49pOjJNpfFHK30-SG-k1WDbbDNqcQltKHDXsHBd3WC3Z-UYEA38wQrdINlVdNvhNlm0i4Fugo7H30WDY8pwkuTvcsnjWx5Y-bC5UqZIcDszLoACGcbjKt9ao699BVSP28BSEUV3yrYqLT1Stl5NkFhhTi2OirQd74r5SW6JiAVivuuMqPQWj78TdKN76gPJcdbp3aoCXzTzJrHpKxu9gyRrDQUR4wd9X9qMVHxUkw-y_dP1hYgdUCLDKqTS9k6AmxCdYlsmrbcgnsm82jM27PBpUidnBvOYHUG9KhZmpqOa7n9oC3ka1Zh4LI06peM33qAg6uNScFem8W8O1nbgzGRL-2oaaIRZ75AcIap7rE1fxqeFcq0M936q7BVJPcNpmvsonEYLkWc7pHaBhYKi4_UbEiTB_xN1QQMCeRQLZNU1u2O5DPLnOq3HiboVw8aN-XQlh8z4-Ir9ypzmih1q5haF7KzyAs4exhMekxChByOPO65XLpe0n2yoFtoSzAWkve-Yww' }
    // }
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}api/approver/list_approve_order`, data, auth)
      // console.log(response.data)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async IframeOrderApprove (data) {
    const auth = await GetToken()
    // const auth = {
    //   headers: { Authorization: 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsImp0aSI6IjY1NzMzZGUyODk3MzYxOGYzYjhhZWNkOTNjMDI4OGQ5YjZiYzZjYmFjZjdhNzdhMzg1NTRkY2I5NzUzYTg4ZjRhMmMyN2QyYTFjZjM2ZmMzIn0.eyJhdWQiOiIzNDAiLCJqdGkiOiI2NTczM2RlMjg5NzM2MThmM2I4YWVjZDkzYzAyODhkOWI2YmM2Y2JhY2Y3YTc3YTM4NTU0ZGNiOTc1M2E4OGY0YTJjMjdkMmExY2YzNmZjMyIsImlhdCI6MTY2MTgzMDA2MywibmJmIjoxNjYxODMwMDYzLCJleHAiOjE3NDgxNDM2NjMsInN1YiI6IjYzMDcwMDcwNDgiLCJzY29wZXMiOlsidGl0bGUiLCJmaXJzdF9uYW1lIiwibGFzdF9uYW1lIiwiaWRfY2FyZCIsImJpcnRoX2RhdGUiLCJlbWFpbCIsInRlbCIsInBpYyJdfQ.c-RejTdnotIUMnDWbDxaWao0MO55ZtTEt5v5mu8_L1hsCB2LhLHdkAMHdPgZKcjI7lWQ5n7MZkHj5SX0kUyMxnn4wxwX-AG79pL1borQ7DRFPo3Qjg4ey6g7h-GHIggfH5tpPOJ4vXHFqedyJRMRhXk9bDOEsB3X1tFQTn49pOjJNpfFHK30-SG-k1WDbbDNqcQltKHDXsHBd3WC3Z-UYEA38wQrdINlVdNvhNlm0i4Fugo7H30WDY8pwkuTvcsnjWx5Y-bC5UqZIcDszLoACGcbjKt9ao699BVSP28BSEUV3yrYqLT1Stl5NkFhhTi2OirQd74r5SW6JiAVivuuMqPQWj78TdKN76gPJcdbp3aoCXzTzJrHpKxu9gyRrDQUR4wd9X9qMVHxUkw-y_dP1hYgdUCLDKqTS9k6AmxCdYlsmrbcgnsm82jM27PBpUidnBvOYHUG9KhZmpqOa7n9oC3ka1Zh4LI06peM33qAg6uNScFem8W8O1nbgzGRL-2oaaIRZ75AcIap7rE1fxqeFcq0M936q7BVJPcNpmvsonEYLkWc7pHaBhYKi4_UbEiTB_xN1QQMCeRQLZNU1u2O5DPLnOq3HiboVw8aN-XQlh8z4-Ir9ypzmih1q5haF7KzyAs4exhMekxChByOPO65XLpe0n2yoFtoSzAWkve-Yww' }
    // }
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}api/approver/iframe_approve_order`, data, auth)
      // console.log(response.data)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async DetailOrderApprove (data) {
    const auth = await GetToken()
    // const auth = {
    //   headers: { Authorization: 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsImp0aSI6IjY1NzMzZGUyODk3MzYxOGYzYjhhZWNkOTNjMDI4OGQ5YjZiYzZjYmFjZjdhNzdhMzg1NTRkY2I5NzUzYTg4ZjRhMmMyN2QyYTFjZjM2ZmMzIn0.eyJhdWQiOiIzNDAiLCJqdGkiOiI2NTczM2RlMjg5NzM2MThmM2I4YWVjZDkzYzAyODhkOWI2YmM2Y2JhY2Y3YTc3YTM4NTU0ZGNiOTc1M2E4OGY0YTJjMjdkMmExY2YzNmZjMyIsImlhdCI6MTY2MTgzMDA2MywibmJmIjoxNjYxODMwMDYzLCJleHAiOjE3NDgxNDM2NjMsInN1YiI6IjYzMDcwMDcwNDgiLCJzY29wZXMiOlsidGl0bGUiLCJmaXJzdF9uYW1lIiwibGFzdF9uYW1lIiwiaWRfY2FyZCIsImJpcnRoX2RhdGUiLCJlbWFpbCIsInRlbCIsInBpYyJdfQ.c-RejTdnotIUMnDWbDxaWao0MO55ZtTEt5v5mu8_L1hsCB2LhLHdkAMHdPgZKcjI7lWQ5n7MZkHj5SX0kUyMxnn4wxwX-AG79pL1borQ7DRFPo3Qjg4ey6g7h-GHIggfH5tpPOJ4vXHFqedyJRMRhXk9bDOEsB3X1tFQTn49pOjJNpfFHK30-SG-k1WDbbDNqcQltKHDXsHBd3WC3Z-UYEA38wQrdINlVdNvhNlm0i4Fugo7H30WDY8pwkuTvcsnjWx5Y-bC5UqZIcDszLoACGcbjKt9ao699BVSP28BSEUV3yrYqLT1Stl5NkFhhTi2OirQd74r5SW6JiAVivuuMqPQWj78TdKN76gPJcdbp3aoCXzTzJrHpKxu9gyRrDQUR4wd9X9qMVHxUkw-y_dP1hYgdUCLDKqTS9k6AmxCdYlsmrbcgnsm82jM27PBpUidnBvOYHUG9KhZmpqOa7n9oC3ka1Zh4LI06peM33qAg6uNScFem8W8O1nbgzGRL-2oaaIRZ75AcIap7rE1fxqeFcq0M936q7BVJPcNpmvsonEYLkWc7pHaBhYKi4_UbEiTB_xN1QQMCeRQLZNU1u2O5DPLnOq3HiboVw8aN-XQlh8z4-Ir9ypzmih1q5haF7KzyAs4exhMekxChByOPO65XLpe0n2yoFtoSzAWkve-Yww' }
    // }
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}api/approver/detail_list_approve_order`, data, auth)
      // console.log(response.data)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async ListPurchaserApproveOrder (data) {
    const auth = await GetToken()
    // const auth = {
    //   headers: { Authorization: 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsImp0aSI6IjY1NzMzZGUyODk3MzYxOGYzYjhhZWNkOTNjMDI4OGQ5YjZiYzZjYmFjZjdhNzdhMzg1NTRkY2I5NzUzYTg4ZjRhMmMyN2QyYTFjZjM2ZmMzIn0.eyJhdWQiOiIzNDAiLCJqdGkiOiI2NTczM2RlMjg5NzM2MThmM2I4YWVjZDkzYzAyODhkOWI2YmM2Y2JhY2Y3YTc3YTM4NTU0ZGNiOTc1M2E4OGY0YTJjMjdkMmExY2YzNmZjMyIsImlhdCI6MTY2MTgzMDA2MywibmJmIjoxNjYxODMwMDYzLCJleHAiOjE3NDgxNDM2NjMsInN1YiI6IjYzMDcwMDcwNDgiLCJzY29wZXMiOlsidGl0bGUiLCJmaXJzdF9uYW1lIiwibGFzdF9uYW1lIiwiaWRfY2FyZCIsImJpcnRoX2RhdGUiLCJlbWFpbCIsInRlbCIsInBpYyJdfQ.c-RejTdnotIUMnDWbDxaWao0MO55ZtTEt5v5mu8_L1hsCB2LhLHdkAMHdPgZKcjI7lWQ5n7MZkHj5SX0kUyMxnn4wxwX-AG79pL1borQ7DRFPo3Qjg4ey6g7h-GHIggfH5tpPOJ4vXHFqedyJRMRhXk9bDOEsB3X1tFQTn49pOjJNpfFHK30-SG-k1WDbbDNqcQltKHDXsHBd3WC3Z-UYEA38wQrdINlVdNvhNlm0i4Fugo7H30WDY8pwkuTvcsnjWx5Y-bC5UqZIcDszLoACGcbjKt9ao699BVSP28BSEUV3yrYqLT1Stl5NkFhhTi2OirQd74r5SW6JiAVivuuMqPQWj78TdKN76gPJcdbp3aoCXzTzJrHpKxu9gyRrDQUR4wd9X9qMVHxUkw-y_dP1hYgdUCLDKqTS9k6AmxCdYlsmrbcgnsm82jM27PBpUidnBvOYHUG9KhZmpqOa7n9oC3ka1Zh4LI06peM33qAg6uNScFem8W8O1nbgzGRL-2oaaIRZ75AcIap7rE1fxqeFcq0M936q7BVJPcNpmvsonEYLkWc7pHaBhYKi4_UbEiTB_xN1QQMCeRQLZNU1u2O5DPLnOq3HiboVw8aN-XQlh8z4-Ir9ypzmih1q5haF7KzyAs4exhMekxChByOPO65XLpe0n2yoFtoSzAWkve-Yww' }
    // }
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}api/approver/list_purchaser_approve_order`, data, auth)
      // console.log(response.data)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  // onlineShopping (gracz)
  async ListGSellerShop (data) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}api/admin_platform/list_g_seller_shop`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async ListSelectNewGSellerShop (data) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}api/admin_platform/list_select_new_g_seller_shop`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async AddGAdminSellerShop (data) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}api/admin_platform/add_g_admin_seller_shop`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async DeleteGSellerShop (data) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}api/admin_platform/delete_g_seller_shop`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  }
}
