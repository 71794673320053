import AxiosGraczEmployee from './axios_gracz_employee_api'

const ModuleGraczEmployee = {
  state: {
    stateGraczListEmployee: [],
    stateGraczListEmployeeSales: [],
    stateGraczDetailEmployee: [],
    stateGraczSearchEmployee: [],
    stateGraczCreateEmployee: [],
    stateGraczUpdateStatusEmployee: [],
    stateGraczEditEmployee: [],
    stateGraczListSalesEmployee: [],
    stateGraczUserDetailEmployee: [],
    stateGraczUpdateProfileEmployee: []
  },
  mutations: {
    mutationsGraczListEmployee (state, data) {
      state.stateGraczListEmployee = data
    },
    mutationsGraczListEmployeeSales (state, data) {
      state.stateGraczListEmployeeSales = data
    },
    mutationsGraczDetailEmployee (state, data) {
      state.stateGraczDetailEmployee = data
    },
    mutationsGraczSearchEmployee (state, data) {
      state.stateGraczSearchEmployee = data
    },
    mutationsGraczCreateEmployee (state, data) {
      state.stateGraczCreateEmployee = data
    },
    mutationsGraczUpdateStatusEmployee (state, data) {
      state.stateGraczUpdateStatusEmployee = data
    },
    mutationsGraczEditEmployee (state, data) {
      state.stateGraczEditEmployee = data
    },
    mutationsGraczListSalesEmployee (state, data) {
      state.stateGraczListSalesEmployee = data
    },
    mutationsGraczUserDetailEmployee (state, data) {
      state.stateGraczUserDetailEmployee = data
    },
    mutationsGraczUpdateProfileEmployee (state, data) {
      state.stateGraczUpdateProfileEmployee = data
    }
  },
  actions: {
    async actionsGraczListEmployee (context, access) {
      var response = await AxiosGraczEmployee.GraczListEmployee(access)
      await context.commit('mutationsGraczListEmployee', response)
    },
    async actionsGraczListEmployeeSales (context, access) {
      var response = await AxiosGraczEmployee.GraczListEmployeeSales(access)
      await context.commit('mutationsGraczListEmployeeSales', response)
    },
    async actionsGraczDetailEmployee (context, access) {
      var response = await AxiosGraczEmployee.GraczDetailEmployee(access)
      await context.commit('mutationsGraczDetailEmployee', response)
    },
    async actionsGraczSearchEmployee (context, access) {
      var response = await AxiosGraczEmployee.GraczSearchEmployee(access)
      await context.commit('mutationsGraczSearchEmployee', response)
    },
    async actionsGraczCreateEmployee (context, access) {
      var response = await AxiosGraczEmployee.GraczCreateEmployee(access)
      await context.commit('mutationsGraczCreateEmployee', response)
    },
    async actionsGraczUpdateStatusEmployee (context, access) {
      var response = await AxiosGraczEmployee.GraczUpdateStatusEmployee(access)
      await context.commit('mutationsGraczUpdateStatusEmployee', response)
    },
    async actionsGraczEditEmployee (context, access) {
      var response = await AxiosGraczEmployee.GraczEditEmployee(access)
      await context.commit('mutationsGraczEditEmployee', response)
    },
    async actionsGraczListSalesEmployee (context, access) {
      var response = await AxiosGraczEmployee.GraczListSalesEmployee(access)
      await context.commit('mutationsGraczListSalesEmployee', response)
    },
    async actionsGraczUserDetailEmployee (context, access) {
      var response = await AxiosGraczEmployee.GraczUserDetailEmployee(access)
      await context.commit('mutationsGraczUserDetailEmployee', response)
    },
    async actionsGraczUpdateProfileEmployee (context, access) {
      var response = await AxiosGraczEmployee.GraczUpdateProfileEmployee(access)
      await context.commit('mutationsGraczUpdateProfileEmployee', response)
    }
  }
}
export default ModuleGraczEmployee
