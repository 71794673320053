import axios from 'axios'
import { Decode } from '@/services'

const GetToken = () => {
  if (localStorage.getItem('oneData') !== null) {
    const oneData = JSON.parse(Decode.decode(localStorage.getItem('oneData')))
    const auth = {
      headers: { Authorization: `Bearer ${oneData.user.access_token}` }
    }
    return auth
  } else {
    const auth = ''
    return auth
  }
}

export default {
  // homepage
  async GetHomepage (val) {
    var response
    var data
    if (val === null) {
      data = {
        role: 'ext_buyer'
      }
    } else {
      data = {
        role: val.role
      }
    }
    try {
      if (localStorage.getItem('oneData') === null) {
        response = await axios.post(`${process.env.VUE_APP_BACK_END}api/homepage_middle`, data)
      } else if (localStorage.getItem('oneData') !== null) {
        const auth = await GetToken()
        response = await axios.post(`${process.env.VUE_APP_BACK_END}api/homepage_middle`, data, auth)
      }
      // console.log('response data Homepage ====>', response)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  // more_new_products_home
  async GetMoreNewProduct (val) {
    var response
    var data
    if (val === null) {
      data = {
        role: 'ext_buyer'
      }
    } else {
      data = {
        role: val.role
      }
    }
    try {
      if (localStorage.getItem('oneData') === null) {
        response = await axios.post(`${process.env.VUE_APP_BACK_END}api/more_new_products_home`, data)
      } else if (localStorage.getItem('oneData') !== null) {
        const auth = await GetToken()
        response = await axios.post(`${process.env.VUE_APP_BACK_END}api/more_new_products_home`, data, auth)
      }
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  // more_bs_products_home
  async GetMoreBestSeller (val) {
    var response
    var data
    if (val === null) {
      data = {
        role: 'ext_buyer'
      }
    } else {
      data = {
        role: val.role
      }
    }
    try {
      if (localStorage.getItem('oneData') === null) {
        response = await axios.post(`${process.env.VUE_APP_BACK_END}api/more_bs_products_home`, data)
      } else if (localStorage.getItem('oneData') !== null) {
        const auth = await GetToken()
        response = await axios.post(`${process.env.VUE_APP_BACK_END}api/more_bs_products_home`, data, auth)
      }
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  // Search Text
  async GetSearchResult (val) {
    // console.log('val data role====>', val)
    var response
    try {
      if (localStorage.getItem('oneData') === null) {
        response = await axios.post(`${process.env.VUE_APP_BACK_END2}products/search`, val)
      } else {
        const auth = await GetToken()
        response = await axios.post(`${process.env.VUE_APP_BACK_END2}products/search`, val, auth)
      }
      // console.log('result text====>', response.data)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  // Search Text New
  async GetSearchResultNew (val) {
    // console.log('val data role====>', val)
    var response
    try {
      if (localStorage.getItem('oneData') === null) {
        response = await axios.post(`${process.env.VUE_APP_BACK_END2}search/product/search_bar`, val)
      } else {
        const auth = await GetToken()
        response = await axios.post(`${process.env.VUE_APP_BACK_END2}search/product/search_bar`, val, auth)
      }
      // console.log('result text====>', response.data)
      return response.data
    } catch (error) {
      return error.response.data
    }
  }
}
