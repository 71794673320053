import AxiosPartner from './axios_partner_api'

const ModulePartner = {
  state: {
    // List Main Documents Partner
    stateDocumentPartnerList: [],
    // Set Main Documents Partner
    stateSetDocumentPartner: [],
    stateSearchCompany: [],
    stateCreatePartner: [],
    stateDetailSettingPartner: [],
    stateSettingPartner: []
  },
  mutations: {
    // list_partner_sellershop
    mutationsListPartner (state, data) {
      state.stateListPartner = data
    },
    // detail_partner_sellershop
    mutationsDetailPartner (state, data) {
      state.stateDetailPartner = data
    },
    // search_company
    mutationsSearchCompany (state, data) {
      state.stateSearchCompany = data
    },
    // create_partner_for_sellershop
    mutationsCreatePartner (state, data) {
      state.stateCreatePartner = data
    },
    // detail_setting_partner_sellershop
    mutationsDetailSettingPartner (state, data) {
      state.stateDetailSettingPartner = data
    },
    // setting_partner_for_sellershop
    mutationsSettingPartner (state, data) {
      state.stateSettingPartner = data
    },
    mutationsDocumentPartnerList (state, data) {
      state.stateDocumentPartnerList = data
    },
    mutationsSetDocumentPartner (state, data) {
      state.stateSetDocumentPartner = data
    }
  },
  actions: {
    // list_partner_sellershop
    async actionsListPartner (context, access) {
      var response = await AxiosPartner.ListPartner(access)
      await context.commit('mutationsListPartner', response)
    },
    // detail_partner_sellershop
    async actionsDetailPartner (context, access) {
      var response = await AxiosPartner.DetailPartner(access)
      await context.commit('mutationsDetailPartner', response)
    },
    // search_company
    async actionsSearchCompany (context, access) {
      var response = await AxiosPartner.SearchCompany(access)
      await context.commit('mutationsSearchCompany', response)
    },
    // create_partner_for_sellershop
    async actionsCreatePartner (context, access) {
      var response = await AxiosPartner.CreatePartner(access)
      await context.commit('mutationsCreatePartner', response)
    },
    // detail_setting_partner_sellershop
    async actionsDetailSettingPartner (context, access) {
      var response = await AxiosPartner.DetailSettingPartner(access)
      await context.commit('mutationsDetailSettingPartner', response)
    },
    // setting_partner_for_sellershop
    async actionsSettingPartner (context, access) {
      var response = await AxiosPartner.SettingPartner(access)
      await context.commit('mutationsSettingPartner', response)
    },
    // List Main Documents Partner
    async actionsDocumentPartnerList (context, access) {
      const response = await AxiosPartner.documentPartnerList(access)
      await context.commit('mutationsDocumentPartnerList', response)
    },
    // Set Main Documents Partner
    async actionsSetDocumentPartner (context, access) {
      const response = await AxiosPartner.setDocumentPartner(access)
      await context.commit('mutationsSetDocumentPartner', response)
    }
  }
}

export default ModulePartner
