import axios from 'axios'
import { Decode } from '@/services'

const GetToken = () => {
  if (localStorage.getItem('oneData') !== null) {
    const oneData = JSON.parse(Decode.decode(localStorage.getItem('oneData')))
    const auth = {
      headers: { Authorization: `Bearer ${oneData.user.access_token}` }
    }
    return auth
  } else {
    const auth = ''
    return auth
  }
}

export default {
  // GetOTP
  async GetOTP (val) {
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}api/get_otp_oauth`, val)
      // console.log('data', response.data)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  // ConfirmOTP
  async ConfirmOTP (val) {
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}api/confirm_otp_oauth`, val)
      // console.log('data orderlist', response.data)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  // FindTaxID
  async FindTaxID (val) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}api/business/find_tax_id_by_one_id`, val, auth)
      // console.log('data orderlist', response.data)
      return response.data
    } catch (error) {
      return error.response.data
    }
  }
}
