import AxiosOrder from './axios_order_api'

const ModuleOrder = {
  state: {
    // Order List
    stateOrderListData: [],
    // Order Detail
    stateOrderDetailData: [],
    // stateListOrderSeller
    stateOrderListSeller: [],
    // Order Detail
    stateOrderDetailSeller: [],
    // update send status seller
    stateUpdateStatusSeller: [],
    // cancel send status seller
    stateCancelStatusSeller: [],
    // update send status buyer
    stateUpdateStatusBuyer: [],
    // Order Detail Buyer
    stateDetailOrder: [],
    // get list order approve
    stateListOrderApprove: [],
    // Approve
    stateApprove: [],
    // acceptProduct
    stateAcceptProduct: [],
    // CheckAcceptProduct
    stateCheckAcceptProduct: [],
    // Tracking Order Flash
    stateTrackingOrderFlash: [],
    // List UPS Order
    stateListOrder: [],
    // Print Smalllabel Flash
    statePrintSmalllabelFlash: [],
    // Print Biglabel Flash
    statePrintBiglabelFlash: [],
    // Refund buyer list
    stateRefundBuyerList: [],
    // Refund order detail
    stateRefundOrderDetail: [],
    // Send Refund Buyer
    stateSendRefundBuyer: [],
    // Approve Refund Seller
    stateApproveRefundSeller: [],
    // Detail Approve Refund Seller
    stateApproveDetailRefundSeller: [],
    stateGetallProductReviewSeller: [],
    stateDetailProductReviewSeller: [],
    stateChangeStatusReview: [],
    stateDetailFeedbackReview: [],
    stateCreateReplyComment: [],
    stateEditReplyComment: [],
    // Check Liist Review Product Buyer
    stateCheckLiistReviewProductBuyer: [],
    // Create Comment Review Product Buyer
    stateCreateCommentReviewProductBuyer: [],
    // Create Comment Review Product Buyer
    stateEditCommentReviewProductBuyer: []

  },
  mutations: {
    // Order List
    mutationsOrderList (state, data) {
      state.stateOrderListData = data
    },
    // Order Detail
    mutationsOrderDetail (state, data) {
      state.stateOrderDetailData = data
    },
    // List Order Seller
    mutationsOrderListSeller (state, data) {
      state.stateOrderListSeller = data
    },
    // Order Detail
    mutationsOrderDetailSeller (state, data) {
      state.stateOrderDetailSeller = data
    },
    // update send status seller
    mutationsUpdateStatusSeller (state, data) {
      state.stateUpdateStatusSeller = data
    },
    // cancel send status seller
    mutationsCancelStatusSeller (state, data) {
      state.stateCancelStatusSeller = data
    },
    // update send status Buyer
    mutationsUpdateStatusBuyer (state, data) {
      state.stateUpdateStatusBuyer = data
    },
    // get list order approve
    mutationListOrderApprove (state, data) {
      state.stateListOrderApprove = data
    },
    // Approve
    mutationApprove (state, data) {
      state.stateApprove = data
    },
    // acceptProduct
    mutationAcceptProduct (state, data) {
      state.stateAcceptProduct = data
    },
    // CheckAcceptProduct
    mutationCheckAcceptProduct (state, data) {
      state.stateCheckAcceptProduct = data
    },
    // Tracking Order Flash
    mutationsTrackingOrderFlash (state, data) {
      state.stateTrackingOrderFlash = data
    },
    // List UPS Order
    mutationsListOrder (state, data) {
      state.stateListOrder = data
    },
    // Refund buyer list
    mutationsRefundListOrder (state, data) {
      state.stateRefundBuyerList = data
    },
    // Refund order detail
    mutationsRefundOrderDetail (state, data) {
      state.stateRefundOrderDetail = data
    },
    // Send Refund Buyer
    mutationsSendRefundBuyer (state, data) {
      state.stateSendRefundBuyer = data
    },
    // Approve Refund Seller
    mutationsApproveRefundSeller (state, data) {
      state.stateApproveRefundSeller = data
    },
    // Detail Approve Refund Seller
    mutationsApproveDetailRefundSeller (state, data) {
      state.stateApproveDetailRefundSeller = data
    },
    mutationsGetallProductReviewSeller (state, data) {
      state.stateGetallProductReviewSeller = data
      // console.log('GetallProductReviewSeller///', state.stateGetallProductReviewSeller)
    },
    mutationsCreateReplyComment (state, data) {
      state.stateCreateReplyComment = data
      // console.log('GetallProductReviewSeller///', state.stateCreateReplyComment)
    },
    mutationsEditReplyComment (state, data) {
      state.stateEditReplyComment = data
      // console.log('GetallProductReviewSeller///', state.stateEditReplyComment)
    },
    // Check Liist Review Product Buyer
    mutationsCheckLiistReviewProductBuyer (state, data) {
      state.stateCheckLiistReviewProductBuyer = data
    },
    // Create Comment Review Product Buyer
    mutationsCreateCommentReviewProductBuyer (state, data) {
      state.stateCreateCommentReviewProductBuyer = data
    },
    // Edit Comment Review Product Buyer
    mutationsEditCommentReviewProductBuyer (state, data) {
      state.stateEditCommentReviewProductBuyer = data
    },
    mutationsDetailProductReviewSeller (state, data) {
      state.stateDetailProductReviewSeller = data
    },
    mutationsChangeStatusReview (state, data) {
      state.stateChangeStatusReview = data
    },
    mutationsDetailFeedbackReview (state, data) {
      state.stateDetailFeedbackReview = data
    }
  },
  actions: {
    // Order Detail
    async actionListOrderBuyer (context, access) {
      const dataOrder = await AxiosOrder.GetOrderList(access)
      await context.commit('mutationsOrderList', dataOrder)
    },
    async actionOrderDetail (context, access) {
      const response = await AxiosOrder.OrderDetail(access)
      await context.commit('mutationsOrderDetail', response)
    },
    async actionListOrderSeller (context, access) {
      const response = await AxiosOrder.OrderListSeller(access)
      await context.commit('mutationsOrderListSeller', response)
    },
    async actionOrderDetailSeller (context, access) {
      const response = await AxiosOrder.OrderDetailSeller(access)
      await context.commit('mutationsOrderDetailSeller', response)
    },
    async actionUpdateStatusSeller (context, access) {
      const response = await AxiosOrder.UpdateStatusSeller(access)
      await context.commit('mutationsUpdateStatusSeller', response)
    },
    // Status Order Detail Seller
    async actionCancelStatusSeller (context, access) {
      const response = await AxiosOrder.CancelStatusSeller(access)
      await context.commit('mutationsCancelStatusSeller', response)
    },
    async actionUpdateStatusBuyer (context, access) {
      const response = await AxiosOrder.UpdateStatusBuyer(access)
      await context.commit('mutationsUpdateStatusBuyer', response)
    },
    // get list order approve
    async actionDetListOrderApprove (context) {
      const response = await AxiosOrder.GetListOrderApprove()
      await context.commit('mutationListOrderApprove', response)
    },
    // Approve
    async actionApprove (context, access) {
      const response = await AxiosOrder.ApproveData(access)
      await context.commit('mutationApprove', response)
    },
    // acceptProduct
    async actionAcceptProduct (context, access) {
      const response = await AxiosOrder.AcceptProduct(access)
      await context.commit('mutationAcceptProduct', response)
    },
    // CheckAcceptProduct
    async actionCheckAcceptProduct (context, access) {
      const response = await AxiosOrder.CheckAcceptProduct(access)
      await context.commit('mutationCheckAcceptProduct', response)
    },
    // Tracking Order Flash
    async actionTrackingOrderFlash (context, access) {
      const response = await AxiosOrder.GetTrackingOrderFlash(access)
      await context.commit('mutationsTrackingOrderFlash', response)
    },
    // List UPS Order
    async actionListOrder (context, access) {
      const response = await AxiosOrder.GetListOrder(access)
      await context.commit('mutationsListOrder', response)
    },
    // Refund buyer list
    async actionsRefundBuyerList (context, access) {
      const response = await AxiosOrder.RefundBuyerList(access)
      await context.commit('mutationsRefundListOrder', response)
    },
    // Refund order detail
    async actionsRefundOrderDetail (context, access) {
      const response = await AxiosOrder.RefundOrderDetail(access)
      await context.commit('mutationsRefundOrderDetail', response)
    },
    // Send Refund Buyer
    async actionsSendRefundBuyer (context, access) {
      const response = await AxiosOrder.SendRefundBuyer(access)
      await context.commit('mutationsSendRefundBuyer', response)
    },
    // Approve Refund Seller
    async actionsApproveRefundSeller (context, access) {
      const response = await AxiosOrder.ApproveRefundSeller(access)
      await context.commit('mutationsApproveRefundSeller', response)
    },
    // Detail Approve Refund Seller
    async actionsApproveDetailRefundSeller (context, access) {
      const response = await AxiosOrder.ApproveDetailRefundSeller(access)
      await context.commit('mutationsApproveDetailRefundSeller', response)
    },
    async actionGetallProductReviewSeller (context, access) {
      var responseData = await AxiosOrder.GetallProductReviewSeller(access)
      await context.commit('mutationsGetallProductReviewSeller', responseData)
    },
    async actionCreateReplyComment (context, access) {
      var responseData = await AxiosOrder.CreateReplyComment(access)
      await context.commit('mutationsCreateReplyComment', responseData)
    },
    async actionEditReplyComment (context, access) {
      var responseData = await AxiosOrder.EditReplyComment(access)
      await context.commit('mutationsEditReplyComment', responseData)
    },
    async actionsDetailProductReviewSeller (context, access) {
      var responseData = await AxiosOrder.DetailReviewSeller(access)
      await context.commit('mutationsDetailProductReviewSeller', responseData)
    },
    async actionsChangeStatusReview (context, access) {
      var responseData = await AxiosOrder.ChangeStatusReview(access)
      await context.commit('mutationsChangeStatusReview', responseData)
    },
    async actionsDetailFeedbackReview (context, access) {
      var responseData = await AxiosOrder.DetailFeedbackReview(access)
      await context.commit('mutationsDetailFeedbackReview', responseData)
    }
  }
}
export default ModuleOrder
