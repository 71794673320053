import axios from 'axios'
import { Decode } from '@/services'

const GetToken = () => {
  if (Object.prototype.hasOwnProperty.call(localStorage, 'oneData')) {
    const oneData = JSON.parse(Decode.decode(localStorage.getItem('oneData')))
    const auth = {
      headers: { Authorization: `Bearer ${oneData.user.access_token}` }
    }
    return auth
  }
}

export default {
  async GetUserAddress (data) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}api/user_detail`, data, auth)
      return response.data.data
    } catch (error) {
      return error.response.data
    }
  },
  async CreateAddressUser (data) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}api/create_user_address`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async CheckAddressUserLocal (data) {
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}api/check_user_address_on_local`, data)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async UpdateAddressCart (data) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}api/update_address_cart`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async UpdateSellerShopDetail (data) {
    const auth = await GetToken()
    // console.log('ก่อนยิง api updateSellerShop', data)
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}api/update_shop`, data, auth)
      // console.log('response UpdateSellerShopDetail', response.data)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async GetDefaultCagegory () {
    const auth = await GetToken()
    try {
      var response = await axios.get(`${process.env.VUE_APP_BACK_END}api/category/get_all_category`, auth)
      return response.data.data
    } catch (error) {
      return error.response.data
    }
  },
  async GetCagegory () {
    const auth = await GetToken()
    try {
      var response = await axios.get(`${process.env.VUE_APP_BACK_END}api/list_category`, auth)
      // console.log('response ====', response)
      return response
    } catch (error) {
      return error.response.data
    }
  },
  async GetBrand () {
    const auth = await GetToken()
    try {
      var response = await axios.get(`${process.env.VUE_APP_BACK_END}api/manufacturer/list_manufacturer`, auth)
      // console.log('GetSupplier', response)
      // var ListSupplier = await CustomBrand(response.data.data)
      return response.data.data
    } catch (error) {
      return error.response.data
    }
  },
  async GetSupplier () {
    const auth = await GetToken()
    try {
      var response = await axios.get(`${process.env.VUE_APP_BACK_END}api/supplier/list_supplier`, auth)
      // console.log('GetSupplier', response)
      // var ListSupplier = await CustomSupplier(response.data.data)
      return response.data.data
    } catch (error) {
      return error.response.data
    }
  },
  async GetProductBySellerID (data) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}api/list_product`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async CreateBrand (data) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}api/manufacturer/create_manufacturer`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async CreateSupplier (data) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}api/supplier/create_supplier`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async CreateInventory (data) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}api/inventory/add_inventory`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async CreateProductShop (data) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}api/add_product_express_v2`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async EditProductShop (data) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}api/edit_product_frontend`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async GetInventory (data) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}api/inventory/list_inventory_v3`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async ListTierAndDoc (data) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}api/list_tier_and_extra_document`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  }
}

// const CustomBrand = (res) => {
//   var Custom = []
//   res.forEach(data => {
//     Custom.push(data.manufacturer_name)
//   })
//   return Custom
// }
// const CustomSupplier = (res) => {
//   var Custom = []
//   res.forEach(data => {
//     Custom.push(data.supplier_name)
//   })
//   return Custom
// }
// const CustomCategory = (res) => {
//   console.log('------>>>>>', res)
//   var MainCategory = ''
//   var SubCategory
//   var KeySubCategory
//   var Custom = []
//   res.forEach(element => {
//     MainCategory = element.main_category
//     SubCategory = Object.values((Object.values(element.sub_category))[0])
//     KeySubCategory = Object.keys((Object.values(element.sub_category))[0])
//     KeySubCategory.forEach(KeySub => {
//       SubCategory.forEach(ForSubCate => {
//         ForSubCate.forEach(ForSubSubCate => {
//           Custom.push(`${MainCategory}>${KeySub}>${ForSubSubCate}`)
//         })
//       })
//     })
//   })
//   return Custom
// }
