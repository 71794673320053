import axios from 'axios'
import { Decode } from '@/services'

const GetToken = () => {
  if (localStorage.getItem('oneData') !== null) {
    const oneData = JSON.parse(Decode.decode(localStorage.getItem('oneData')))
    const auth = {
      headers: { Authorization: `Bearer ${oneData.user.access_token}` }
    }
    return auth
  } else {
    const auth = ''
    return auth
  }
}

export default {
  async ListPartner (data) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}api/2022/list_partner_sellershop`, data, auth)
      // console.log(response.data)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async DetailPartner (data) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}api/2022/detail_partner_sellershop`, data, auth)
      // console.log(response.data)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async SearchCompany (data) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}api/2022/search_company`, data, auth)
      // console.log(response.data)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async CreatePartner (data) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}api/2022/create_partner_for_sellershop`, data, auth)
      // console.log(response.data)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async DetailSettingPartner (data) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}api/2022/detail_setting_partner_sellershop`, data, auth)
      // console.log(response.data)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async SettingPartner (data) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}api/2022/setting_partner_for_sellershop`, data, auth)
      // console.log(response.data)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  // List Main Documents Partner
  async documentPartnerList (data) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}api/list_main_documents_partner`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  // Set Main Documents Partner
  async setDocumentPartner (data) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}api/set_main_documents_partner`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  }
}
