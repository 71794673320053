import axios from 'axios'
import { Decode } from '@/services'

const GetToken = () => {
  if (Object.prototype.hasOwnProperty.call(localStorage, 'oneData')) {
    const oneData = JSON.parse(Decode.decode(localStorage.getItem('oneData')))
    const auth = {
      headers: { Authorization: `Bearer ${oneData.user.access_token}` }
    }
    return auth
  }
}

export default {
  async GraczCheckCanCreate (val) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}api/g/check_can_create_promotion`, val, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async GraczSearchPromotion (val) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}api/g/search_promotion`, val, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async GraczListPromotionCart (val) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}api/g/list_promotion_cart`, val, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async GraczFindProductPromotion (val) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}api/g/find_product_promotion`, val, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  }
}
