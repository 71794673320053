import AxiosSettingTier from './axios_setting_tier'

const ModuleSettingTier = {
  state: {
    stateSettingTierList: [],
    stateCreateTier: [],
    stateEditTier: [],
    stateDetailTier: [],
    stateUpdateStatusTier: [],
    stateTurnOnOffRequessPartner: []
  },
  mutations: {
    mutationsSettingTierList (state, data) {
      state.stateSettingTierList = data
    },
    mutationsCreateTier (state, data) {
      state.stateCreateTier = data
    },
    mutationsEditTier (state, data) {
      state.stateEditTier = data
    },
    mutationsDetailTier (state, data) {
      state.stateDetailTier = data
    },
    mutationsUpdateStatusTier (state, data) {
      state.stateUpdateStatusTier = data
    },
    mutationsTurnOnOffRequessPartner (state, data) {
      state.stateTurnOnOffRequessPartner = data
    }
  },
  actions: {
    // Setting Tier List
    async actionsSettingTierList (context, access) {
      const response = await AxiosSettingTier.settingTierList(access)
      await context.commit('mutationsSettingTierList', response)
    },
    // Create Setting Tier
    async actionsCreateTier (context, access) {
      const response = await AxiosSettingTier.createTier(access)
      await context.commit('mutationsCreateTier', response)
    },
    // Edit Setting Tier
    async actionsEditTier (context, access) {
      const response = await AxiosSettingTier.editTier(access)
      await context.commit('mutationsEditTier', response)
    },
    // Detail Setting Tier
    async actionsDetailTier (context, access) {
      const response = await AxiosSettingTier.detailTier(access)
      await context.commit('mutationsDetailTier', response)
    },
    // Update Status Tier
    async actionsUpdateStatusTier (context, access) {
      const response = await AxiosSettingTier.updateStatusTier(access)
      await context.commit('mutationsUpdateStatusTier', response)
    },
    // Turn on / off request partner Tier List
    async actionsTurnOnOffRequessPartner (context, access) {
      const response = await AxiosSettingTier.turnOnOffRequessPartner(access)
      await context.commit('mutationsTurnOnOffRequessPartner', response)
    }
  }
}

export default ModuleSettingTier
