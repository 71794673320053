import AxiosProduct from '../store_product_api/axios_product_api'

const ModuleProduct = {
  state: {
    // Product Detail
    stateProductDetailData: []
  },
  mutations: {
    // Product Detail
    mutationsProductDetail (state, data) {
      state.stateProductDetailData = data
    }
  },
  actions: {
    // Product Detail
    async actionsProductDetail (context, access) {
      const dataProduct = await AxiosProduct.GetProductDetail(access)
      await context.commit('mutationsProductDetail', dataProduct)
    }
  }
}

export default ModuleProduct
