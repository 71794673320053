import AxiosETax from './axios_Etax_api'

const ModuleETax = {
  state: {
    stateCheckTaxIDShop: [],
    stateCheckEtaxInShop: [],
    stateSendAccessETax: []
  },
  mutations: {
    nutationsCheckTaxIDShop (state, data) {
      state.stateCheckTaxIDShop = data
    },
    mutationsCheckETaxInShop (state, data) {
      state.stateCheckEtaxInShop = data
    },
    mutationsSendAccessETax (state, data) {
      state.stateSendAccessETax = data
    }
  },
  actions: {
    async actionsCheckTaxIDShop (context, access) {
      const responseData = await AxiosETax.CheckTaxIDShop(access)
      await context.commit('nutationsCheckTaxIDShop', responseData)
    },
    async actionsCheckETaxInShop (context, access) {
      const responseData = await AxiosETax.CheckETaxInShop(access)
      await context.commit('mutationsCheckETaxInShop', responseData)
    },
    async actionsSendAccessETax (context, access) {
      const responseData = await AxiosETax.SendAccessETax(access)
      await context.commit('mutationsSendAccessETax', responseData)
    }
  }
}
export default ModuleETax
