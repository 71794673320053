import AxiosCart from '../store_Cart/axios_cart_api'

const ModuleCart = {
  state: {
    stateAddToCart: null,
    stateUpdateCart: null,
    stateDeleteAllProductCart: null,
    stateDetailCart: [],
    stateGetCart: [],
    stateGetCartSpecialPrice: null,
    stateCreateOrder: null,
    stateCreateOrderSpecialprice: null,
    stateCompanyPurchaser: null,
    stateCreateQuManual: null,
    stateGetAdminData: null,
    stateCreateInvoiceAddressPurchaser: null,
    stateCreateOrderSyncTaxaddress: null,
    stateGetPaymentPage: null,
    stateLocalstorageDetailCart: null,
    stateLocalstorageGetCart: null,
    stateLocalstorageCreateOrder: null,
    stateSentDataPPL: null,
    stateLocalstorageCheckAddToCart: null,
    // Preview QU
    statePreviewQU: [],
    stateGetETaxPDF: [],
    // ชำระแบบเงินสด
    statePayCashInStore: []
  },
  mutations: {
    mutationAddToCart (state, data) {
      state.stateAddToCart = data
    },
    mutationUpdateCart (state, data) {
      state.stateUpdateCart = data
    },
    mutationDeleteAllProductCart (state, data) {
      state.stateDeleteAllProductCart = data
    },
    mutationDetailCart (state, data) {
      state.stateDetailCart = data
    },
    mutationGetCart (state, data) {
      state.stateGetCart = data
    },
    mutationGetCartSpecialPrice (state, data) {
      state.stateGetCartSpecialPrice = data
    },
    mutationCreateOrder (state, data) {
      state.stateCreateOrder = data
    },
    mutationCreateOrderSpecialprice (state, data) {
      state.stateCreateOrderSpecialprice = data
    },
    // CreateInvoiceAddressPurchaser
    mutationCreateInvoiceAddressPurchaser (state, data) {
      state.stateCreateInvoiceAddressPurchaser = data
    },
    // confirmOrderLogin add Taxaddress sync CreateOrder
    mutationCreateOrderSyncTaxaddress (state, data) {
      state.stateCreateOrderSyncTaxaddress = data
    },
    mutationUPSCompanyPurchaser (state, data) {
      state.stateCompanyPurchaser = data
    },
    mutationCreateQuManual (state, data) {
      state.stateCreateQuManual = data
    },
    mutationGetAdminData (state, data) {
      state.stateGetAdminData = data
    },
    mutationGetPaymentPage (state, data) {
      state.stateGetPaymentPage = data
    },
    // localStorage
    mutationLocalstorageDetailCart (state, data) {
      state.stateLocalstorageDetailCart = data
    },
    mutationLocalstorageGetCart (state, data) {
      state.stateLocalstorageGetCart = data
    },
    mutationLocalstorageCreateOrder (state, data) {
      state.stateLocalstorageCreateOrder = data
    },
    mutationSentDataPPL (state, data) {
      state.stateSentDataPPL = data
    },
    mutationLocalstorageCheckAddToCart (state, data) {
      state.stateLocalstorageCheckAddToCart = data
    },
    // Preview QU
    mutationsPreviewQU (state, data) {
      state.statePreviewQU = data
    },
    mutationsGetETaxPDF (state, data) {
      state.stateGetETaxPDF = data
    },
    // ชำระแบบเงินสด
    mutationsPayCashInStore (state, data) {
      state.statePayCashInStore = data
    }
  },
  actions: {
    async ActionAddToCart (contaxt, data) {
      const dataFromAxios = await AxiosCart.AddToCart(data)
      await contaxt.commit('mutationAddToCart', dataFromAxios)
    },
    async ActionUpdateCart (contaxt, data) {
      const dataFromAxios = await AxiosCart.UpdateCart(data)
      await contaxt.commit('mutationUpdateCart', dataFromAxios)
    },
    async ActionDeleteAllProductCart (contaxt, data) {
      const dataFromAxios = await AxiosCart.DeleteAllProductCart(data)
      await contaxt.commit('mutationDeleteAllProductCart', dataFromAxios)
    },
    async ActionDetailCart (contaxt, data) {
      const dataFromAxios = await AxiosCart.DetailCart(data)
      await contaxt.commit('mutationDetailCart', dataFromAxios)
    },
    async ActionGetCart (contaxt, data) {
      const dataFromAxios = await AxiosCart.GetCart(data)
      await contaxt.commit('mutationGetCart', dataFromAxios)
    },
    async ActionGetCartSpecialPrice (contaxt, data) {
      const dataFromAxios = await AxiosCart.GetCartSpecialPrice(data)
      await contaxt.commit('mutationGetCartSpecialPrice', dataFromAxios)
    },
    async ActionCreateOrder (contaxt, data) {
      const dataFromAxios = await AxiosCart.CreateOrder(data)
      await contaxt.commit('mutationCreateOrder', dataFromAxios)
    },
    async ActionCreateOrderSpecialprice (contaxt, data) {
      const dataFromAxios = await AxiosCart.CreateOrderSpecialprice(data)
      await contaxt.commit('mutationCreateOrderSpecialprice', dataFromAxios)
    },
    // CreateInvoiceAddressPurchaser
    async ActionCreateInvoiceAddressPurchaser (contaxt, data) {
      const dataFromAxios = await AxiosCart.CreateInvoiceAddressPurchaser(data)
      await contaxt.commit('mutationCreateInvoiceAddressPurchaser', dataFromAxios)
    },
    // confirmOrderLogin add Taxaddress sync CreateOrder
    async ActionCreateOrderSyncTaxaddress (contaxt, access) {
      const responseData = await AxiosCart.CreateOrderSyncTaxaddress(access)
      await contaxt.commit('mutationCreateOrderSyncTaxaddress', responseData)
    },
    async ActionGetCompanyPurchaser (contaxt, data) {
      const dataFromAxios = await AxiosCart.GetCompanyPurchaser(data)
      await contaxt.commit('mutationUPSCompanyPurchaser', dataFromAxios)
    },
    async ActionCreateQuManual (contaxt, data) {
      const dataFromAxios = await AxiosCart.CreateQuManual(data)
      await contaxt.commit('mutationCreateQuManual', dataFromAxios)
    },
    async ActionGetAdminData (contaxt, data) {
      const dataFromAxios = await AxiosCart.GetAdminData(data)
      await contaxt.commit('mutationGetAdminData', dataFromAxios)
    },
    async ActionGetPaymentPage (contaxt, access) {
      const dataFromAxios = await AxiosCart.GetPaymentPage(access)
      await contaxt.commit('mutationGetPaymentPage', dataFromAxios)
    },
    // localsstorage
    async ActionLocalstorageDetailCart (contaxt, data) {
      const dataFromAxios = await AxiosCart.LocalstorageDetailCart(data)
      await contaxt.commit('mutationLocalstorageDetailCart', dataFromAxios)
    },
    async ActionLocalstorageGetCart (contaxt, data) {
      const dataFromAxios = await AxiosCart.LocalstorageGetCart(data)
      await contaxt.commit('mutationLocalstorageGetCart', dataFromAxios)
    },
    async ActionLocalstorageCreateOrder (contaxt, data) {
      const dataFromAxios = await AxiosCart.LocalstorageCreateOrder(data)
      await contaxt.commit('mutationLocalstorageCreateOrder', dataFromAxios)
    },
    // SentDataPaperless
    async ActionSentDataPPL (contaxt, data) {
      const dataFromAxios = await AxiosCart.CreateSentDataPPL(data)
      await contaxt.commit('mutationSentDataPPL', dataFromAxios)
    },
    async ActionLocalstorageCheckAddToCart (contaxt, data) {
      const dataFromAxios = await AxiosCart.LocalstorageCheckAddToCart(data)
      await contaxt.commit('mutationLocalstorageCheckAddToCart', dataFromAxios)
    },
    // Preview QU
    async ActionsPreviewQU (context, data) {
      const response = await AxiosCart.PreviewQU(data)
      await context.commit('mutationsPreviewQU', response)
    },
    async ActionsGetETaxPDF (context, data) {
      const response = await AxiosCart.GetETaxPDF(data)
      await context.commit('mutationsGetETaxPDF', response)
    },
    // ชำระแบบเงินสด
    async actionsPayCashInStore (context, data) {
      const response = await AxiosCart.PayCashInStore(data)
      await context.commit('mutationsPayCashInStore', response)
    }
  }
}

export default ModuleCart
