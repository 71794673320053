import AxiosGraczOrder from './axios_gracz_order_api'

const ModuleGraczOrder = {
  state: {
    stateGraczListOrder: [],
    stateGraczListOrderSaleOrder: [],
    stateGraczDetailOrder: [],
    stateGraczDetailOrderSaleOrder: [],
    stateGraczExportOrderData: [],
    stateGraczRecoverOrder: [],
    stateGraczDeleteOrder: [],
    stateGraczInactiveOrder: [],
    stateGraczInactiveOrderSaleOrder: []
  },
  mutations: {
    mutationsGraczListOrder (state, data) {
      state.stateGraczListOrder = data
    },
    mutationsGraczListOrderSaleOrder (state, data) {
      state.stateGraczListOrderSaleOrder = data
    },
    mutationsGraczDetailOrder (state, data) {
      state.stateGraczDetailOrder = data
    },
    mutationsGraczDetailOrderSaleOrder (state, data) {
      state.stateGraczDetailOrderSaleOrder = data
    },
    mutationsGraczExportOrderData (state, data) {
      state.stateGraczExportOrderData = data
    },
    mutationsGraczRecoverOrder (state, data) {
      state.stateGraczRecoverOrder = data
    },
    mutationsGraczDeleteOrder (state, data) {
      state.stateGraczDeleteOrder = data
    },
    mutationsGraczInactiveOrder (state, data) {
      state.stateGraczInactiveOrder = data
    },
    mutationsGraczInactiveOrderSaleOrder (state, data) {
      state.stateGraczInactiveOrderSaleOrder = data
    }
  },
  actions: {
    async actionsGraczListOrder (context, access) {
      var response = await AxiosGraczOrder.GraczListOrder(access)
      await context.commit('mutationsGraczListOrder', response)
    },
    async actionsGraczListOrderSaleOrder (context, access) {
      var response = await AxiosGraczOrder.GraczListOrderSaleOrder(access)
      await context.commit('mutationsGraczListOrderSaleOrder', response)
    },
    async actionsGraczDetailOrder (context, access) {
      var response = await AxiosGraczOrder.GraczDetailOrder(access)
      await context.commit('mutationsGraczDetailOrder', response)
    },
    async actionsGraczDetailOrderSaleOrder (context, access) {
      var response = await AxiosGraczOrder.GraczDetailOrderSaleOrder(access)
      await context.commit('mutationsGraczDetailOrderSaleOrder', response)
    },
    async actionsGraczExportOrderData (context, access) {
      var response = await AxiosGraczOrder.GraczExportOrderData(access)
      await context.commit('mutationsGraczExportOrderData', response)
    },
    async actionsGraczRecoverOrder (context, access) {
      var response = await AxiosGraczOrder.GraczRecoverOrder(access)
      await context.commit('mutationsGraczRecoverOrder', response)
    },
    async actionsGraczDeleteOrder (context, access) {
      var response = await AxiosGraczOrder.GraczDeleteOrder(access)
      await context.commit('mutationsGraczDeleteOrder', response)
    },
    async actionsGraczInactiveOrder (context, access) {
      var response = await AxiosGraczOrder.GraczInactiveOrder(access)
      await context.commit('mutationsGraczInactiveOrder', response)
    },
    async actionsGraczInactiveOrderSaleOrder (context, access) {
      var response = await AxiosGraczOrder.GraczInactiveOrderSaleOrder(access)
      await context.commit('mutationsGraczInactiveOrderSaleOrder', response)
    }
  }
}
export default ModuleGraczOrder
