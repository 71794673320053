import AxiosGraczShop from './axios_gracz_shop_api'

const ModuleGraczShop = {
  state: {
    stateGraczSellerShopPage: [],
    stateGraczEditSellerShopPage: []
  },
  mutations: {
    mutationsGraczSellerShopPage (state, data) {
      state.stateGraczSellerShopPage = data
    },
    mutationsGraczEditSellerShopPage (state, data) {
      state.stateGraczEditSellerShopPage = data
    }
  },
  actions: {
    async actionsGraczSellerShopPage (context, access) {
      var response = await AxiosGraczShop.GraczSellerShopPage(access)
      await context.commit('mutationsGraczSellerShopPage', response)
    },
    async actionsGraczEditSellerShopPage (context, access) {
      var response = await AxiosGraczShop.GraczEditSellerShopPage(access)
      await context.commit('mutationsGraczEditSellerShopPage', response)
    }
  }
}
export default ModuleGraczShop
