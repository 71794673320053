import AxiosCurier from './axios_curier'

const ModuleCurier = {
  state: {
    stateCurier: [],
    stateMutiCourier: [],
    stateGetCurier: []
  },
  mutations: {
    mutationsCurier (state, data) {
      state.stateCurier = data
    },
    mutationsGetCurier (state, data) {
      state.stateGetCurier = data
    },
    mutationsMutiCurier (state, data) {
      state.stateMutiCourier = data
    }
  },
  actions: {
    async actionCallCurier (context, access) {
      const data = await AxiosCurier.CallCurier(access)
      await context.commit('mutationsCurier', data)
    },
    async actionGetCurier (context, access) {
      const data = await AxiosCurier.GetCurierAll(access)
      await context.commit('mutationsGetCurier', data)
    },
    async actionMutiCurier (context, access) {
      const data = await AxiosCurier.callMutiCourier(access)
      await context.commit('mutationsMutiCurier', data)
    }
  }
}
export default ModuleCurier
