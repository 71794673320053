import AxiosAdminManage from '../store_admin_manage/axios_admin_manage'

const ModuleAdminManage = {
  state: {
    // Create Company
    stateCreateCompany: [],
    // Edit Company
    stateEditCompany: [],
    // Detail Company
    stateDetailCompany: [],
    // List Company
    stateListCompany: [],
    // List User Company
    stateListUserCompany: [],
    // List PO For Buyer
    stateListPOBuyer: [],
    // Detail QU for buyer
    stateDetailQUBuyer: [],
    // update_status_buyer
    stateUpdateStatusBuyer: [],
    // list_status_buyer
    stateListOrderPurchaser: [],
    // detail_status_buyer
    stateDetailOrderPurchaser: [],
    // edit QU for buyer
    stateEditQU: [],
    QuotationformData: {},
    // List Refund Product Company
    stateListRefundPurchaser: [],
    // Detail Refund Product Company
    stateDetailRefundPurchaser: [],
    // (YaPalm) Credit Term
    stateListOrderCreditTerm: [],
    stateListCreditTerm: [],
    stateCalculateRequestCreditTerm: [],
    stateRequestChangeTerm: [],
    stateCheckCalculate: [],
    stateCancelRequestChangeTerm: [],
    stateListSpecialPriceBuyer: [],
    stateDetailSpecialPrice: [],
    stateListProductAttribute: [],
    stateUpdateListDataTableSpecialPrice: [],
    stateUpdateStatusSpecialPriceBuyer: [],
    stateRequestSpecialPrice: [],
    stateEditSpecialPriceBuyer: [],
    ListProductOfShop: [],
    stateEditQuotation: [],
    stateImgQu: '',
    stateeditPositionOfCompany: [],
    statecreatePositionOfCompany: [],
    statedetailPositionOfCompany: [],
    statelistPositionOfCompany: [],
    stateListPositionSellerUser: [],
    stateDetailPositionSellerUser: [],
    stateSearchPositionSellerUser: [],
    stateEditPositionSellerUser: [],
    stateAddPositionSellerUser: [],
    stateListPositionShops: [],
    stateEstimateQu: [],
    stateGetPayment: [],
    stateURLQu: '',
    createQuCart: '',
    stateCreateCompanyByBiz: [],
    stateDashboardAdminAll: [],
    dashboardSummary: '',
    dashboardChart: {
      comparedSeller: '',
      dateSeller: '',
      comparedUser: '',
      dateUser: ''
    },
    stateDateExport: '',
    dashboardListTop: '',
    stateAllGP: [],
    stateGpChart: {
      value: [],
      date: []
    },
    stateGPTable: [],
    stateGetListAdminPlatform: [],
    stateCreateAdminPlatform: [],
    stateEditAdminPlatform: [],
    stateDetailAdminPlatform: [],
    stateDeleteAdminPlatform: [],
    stateSearchAdminPlatform: [],
    stateshopList: [],
    headerDBadmin: {
      seller: [],
      user: []
    }
  },
  getters: {
    dateGraph (state) {
      return state.stateAllGP.data.date_graph
    },
    dataGraph (state) {
      const series = [{ name: 'รายได้ทั้งหมด', data: state.stateAllGP.data.data_graph }]
      return series
    },
    Summary (state) {
      return state.stateAllGP.data
    }
  },
  mutations: {
    // Create Company
    mutationsCreateCompany (state, data) {
      state.stateCreateCompany = data
    },
    // Edit Company
    mutationsEditCompany (state, data) {
      state.stateEditCompany = data
    },
    // Detail Company
    mutationsDetailCompany (state, data) {
      state.stateDetailCompany = data
    },
    // List Company
    mutationsListCompany (state, data) {
      state.stateListCompany = data
    },
    // List User Company
    mutationsListUserCompany (state, data) {
      state.stateListUserCompany = data
    },
    // List PO For Buyer
    mutationsListPOBuyer (state, data) {
      state.stateListPOBuyer = data
    },
    // Detail QU for buyer
    mutationsDetailQUBuyer (state, data) {
      state.stateDetailQUBuyer = data
    },
    // update_status_buyer
    mutationsUpdateStatusBuyer (state, data) {
      state.stateUpdateStatusBuyer = data
    },
    // list_status_buyer
    mutationsListOrderPurchaser (state, data) {
      state.stateListOrderPurchaser = data
    },
    // detail_status_buyer
    mutationsDetailOrderPurchaser (state, data) {
      state.stateDetailOrderPurchaser = data
    },
    // edit QU for buyer
    mutationsEditQU (state, data) {
      state.stateEditQU = data
    },
    // List Refund Product Company
    mutationsListRefundPurchaser (state, data) {
      state.stateListRefundPurchaser = data
    },
    // Detail Refund Product Company
    mutationsDetailRefundPurchaser (state, data) {
      state.stateDetailRefundPurchaser = data
    },
    // (YaPalm) List Order Credit Term
    mutationsListOrderCreditTerm (state, data) {
      state.stateListOrderCreditTerm = data
    },
    // (YaPalm) List Credit Term
    mutationsListCreditTerm (state, data) {
      state.stateListCreditTerm = data
    },
    mutationsCalculateRequestCreditTerm (state, data) {
      state.stateCalculateRequestCreditTerm = data
    },
    mutationsCheckCalculate (state, data) {
      state.stateCheckCalculate = data
    },
    mutationsRequestChangeTerm (state, data) {
      state.stateRequestChangeTerm = data
    },
    mutationsCancelRequestChangeTerm (state, data) {
      state.stateCancelRequestChangeTerm = data
    },
    mutationsListSpecialPriceBuyer (state, data) {
      state.stateListSpecialPriceBuyer = data
    },
    mutationsDetailSpecialPrice (state, data) {
      state.stateDetailSpecialPrice = data
    },
    mutationsListProductAttribute (state, data) {
      state.stateListProductAttribute = data
    },
    mutationsUpdateListDataTableSpecialPrice (state, data) {
      state.stateUpdateListDataTableSpecialPrice = data
    },
    mutationsUpdateStatusSpecialPriceBuyer (state, data) {
      state.stateUpdateStatusSpecialPriceBuyer = data
    },
    mutationsRequestSpecialPrice (state, data) {
      state.stateRequestSpecialPrice = data
    },
    mutationsEditSpecialPriceBuyer (state, data) {
      state.stateEditSpecialPriceBuyer = data
    },
    // Edit Quotation
    mutationsEditQuotation (state, data) {
      state.stateEditQuotation = data
    },
    mutationslistPositionOfCompany (state, data) {
      state.statelistPositionOfCompany = data
    },
    mutationsdetailPositionOfCompany (state, data) {
      state.statedetailPositionOfCompany = data
    },
    mutationscreatePositionOfCompany (state, data) {
      state.statecreatePositionOfCompany = data
    },
    mutationseditPositionOfCompany (state, data) {
      state.stateeditPositionOfCompany = data
    },
    mutatonListPositionSellerUser (state, data) {
      state.stateListPositionSellerUser = data
    },
    mutationsDetailPositionSellerUser (state, data) {
      state.stateDetailPositionSellerUser = data
    },
    mutationsSearchPositionSellerUser (state, data) {
      state.stateSearchPositionSellerUser = data
    },
    mutationsEditPositionSellerUser (state, data) {
      state.stateEditPositionSellerUser = data
    },
    mutationsAddPositionSellerUser (state, data) {
      state.stateAddPositionSellerUser = data
    },
    mutationsListPositionShop (state, data) {
      state.stateListPositionShops = data
    },
    mutationsEstimateQu (state, data) {
      state.stateEstimateQu = data
    },
    mutationsGetPayment (state, data) {
      state.stateGetPayment = data
    },
    mutationsCreateQUCart (state, data) {
      state.createQuCart = data
    },
    mutationsCreateCompanyByBiz (state, data) {
      state.stateCreateCompanyByBiz = data
    },
    mutationsDashboardAdminAll (state, data) {
      state.stateDashboardAdminAll = data
    },
    mutationsAllGP (state, data) {
      state.stateAllGP = data
    },
    // start admin panit manage
    mutationsGetListAdminPlatform (state, data) {
      state.stateGetListAdminPlatform = data
    },
    mutationsCreateAdminPlatform (state, data) {
      state.stateCreateAdminPlatform = data
    },
    mutationsEditAdminPlatform (state, data) {
      state.stateEditAdminPlatform = data
    },
    mutationsDetailAdminPlatform (state, data) {
      state.stateDetailAdminPlatform = data
    },
    mutationsDeleteAdminPlatform (state, data) {
      state.stateDeleteAdminPlatform = data
    },
    mutationsSearchAdminPlatform (state, data) {
      state.stateSearchAdminPlatform = data
    }
    // end admin panit manage
  },
  actions: {
    // Create Company
    async actionsCreateCompany (context, access) {
      const response = await AxiosAdminManage.createCompany(access)
      await context.commit('mutationsCreateCompany', response)
    },
    // Edit Company
    async actionsEditCompany (context, access) {
      const response = await AxiosAdminManage.editCompany(access)
      await context.commit('mutationsEditCompany', response)
    },
    // Detail Company
    async actionsDetailCompany (context, access) {
      const response = await AxiosAdminManage.detailCompany(access)
      await context.commit('mutationsDetailCompany', response)
    },
    // List Company
    async actionsListCompany (context) {
      const response = await AxiosAdminManage.listCompany()
      await context.commit('mutationsListCompany', response)
    },
    // List User Company
    async actionsListUserCompany (context) {
      const response = await AxiosAdminManage.listUserCompany()
      await context.commit('mutationsListUserCompany', response)
    },
    // List PO For Buyer
    async actionsListPOBuyer (context, access) {
      const response = await AxiosAdminManage.listPOBuyer(access)
      await context.commit('mutationsListPOBuyer', response)
    },
    // Detail QU for buyer
    async actionsDetailQUBuyer (context, access) {
      const response = await AxiosAdminManage.DetailQUBuyer(access)
      await context.commit('mutationsDetailQUBuyer', response)
    },
    // update_status_buyer
    async actionsUpdateStatusBuyer (context, access) {
      const response = await AxiosAdminManage.UpdateStatusBuyer(access)
      await context.commit('mutationsUpdateStatusBuyer', response)
    },
    // list_order_purchaser
    async actionsListOrderPurchaser (context, access) {
      const response = await AxiosAdminManage.listOrderPurchaser(access)
      await context.commit('mutationsListOrderPurchaser', response)
    },
    // detail_order_purchaser
    async actionsDetailOrderPurchaser (context, access) {
      const response = await AxiosAdminManage.detailOrderPurchaser(access)
      await context.commit('mutationsDetailOrderPurchaser', response)
    },
    // edit QU for buyer
    async actionsEditQU (context, access) {
      const response = await AxiosAdminManage.EditQU(access)
      await context.commit('mutationsEditQU', response)
    },
    // List Refund Product Company
    async actionsListRefundPurchaser (context, access) {
      const response = await AxiosAdminManage.ListRefundPurchaser(access)
      await context.commit('mutationsListRefundPurchaser', response)
    },
    // Detail Refund Product Company
    async actionsDetailRefundPurchaser (context, access) {
      const response = await AxiosAdminManage.DetailRefundPurchaser(access)
      await context.commit('mutationsDetailRefundPurchaser', response)
    },
    // (yaPalm) Credit Term
    async actionsListOrderCreditTerm (context, access) {
      const response = await AxiosAdminManage.listOrderCreditTerm(access)
      await context.commit('mutationsListOrderCreditTerm', response)
    },
    async actionsListCreditTerm (context, access) {
      const response = await AxiosAdminManage.listCreditTerm(access)
      await context.commit('mutationsListCreditTerm', response)
    },
    async actionsCalculateRequestCreditTerm (context, access) {
      const response = await AxiosAdminManage.calculateRequestCreditTerm(access)
      await context.commit('mutationsCalculateRequestCreditTerm', response)
    },
    async actionsCheckCalculate (context, access) {
      const response = await AxiosAdminManage.checkCalculate(access)
      await context.commit('mutationsCheckCalculate', response)
    },
    async actionsRequestChangeTerm (context, access) {
      const response = await AxiosAdminManage.requestChangeTerm(access)
      await context.commit('mutationsRequestChangeTerm', response)
    },
    async actionsCancelRequestChangeTerm (context, access) {
      const response = await AxiosAdminManage.cancelRequestChangeTerm(access)
      await context.commit('mutationsCancelRequestChangeTerm', response)
    },
    async actionsListSpecialPriceBuyer (context, access) {
      const response = await AxiosAdminManage.ListSpecialPriceBuyer(access)
      await context.commit('mutationsListSpecialPriceBuyer', response)
    },
    async actionsDetailSpecialPrice (context, access) {
      const response = await AxiosAdminManage.DetailSpecialPrice(access)
      await context.commit('mutationsDetailSpecialPrice', response)
    },
    // search product in modal special request buyer
    async actionsListProductAttribute (context, access) {
      const response = await AxiosAdminManage.ListProductAttribute(access)
      await context.commit('mutationsListProductAttribute', response)
    },
    // update ข้อมูลในตาราง
    async actionsUpdateListDataTableSpecialPrice (context, access) {
      const response = await AxiosAdminManage.UpdateListDataTableSpecialPrice(access)
      await context.commit('mutationsUpdateListDataTableSpecialPrice', response)
    },
    // อัปเดตสถานะกการร้องขอราคาพิเศษของบริษัท
    async actionsUpdateStatusSpecialPriceBuyer (context, access) {
      const response = await AxiosAdminManage.UpdateStatusSpecialPriceBuyer(access)
      await context.commit('mutationsUpdateStatusSpecialPriceBuyer', response)
    },
    async actionsRequestSpecialPrice (context, access) {
      const response = await AxiosAdminManage.RequestSpecialPrice(access)
      await context.commit('mutationsRequestSpecialPrice', response)
    },
    async actionsEditSpecialPriceBuyer (context, access) {
      const response = await AxiosAdminManage.EditSpecialPriceBuyer(access)
      await context.commit('mutationsEditSpecialPriceBuyer', response)
    },
    // Edit Quotation
    async actionsEditQuotation (context, access) {
      const response = await AxiosAdminManage.EditQuotation(access)
      await context.commit('mutationsEditQuotation', response)
    },
    async actionslistPositionOfCompany (context, access) {
      const response = await AxiosAdminManage.listPositionOfCompany(access)
      await context.commit('mutationslistPositionOfCompany', response)
    },
    async actionsdetailPositionOfCompany (context, access) {
      const response = await AxiosAdminManage.detailPositionOfCompany(access)
      await context.commit('mutationsdetailPositionOfCompany', response)
    },
    async actionscreatePositionOfCompany (context, access) {
      const response = await AxiosAdminManage.createPositionOfCompany(access)
      await context.commit('mutationscreatePositionOfCompany', response)
    },
    async actionseditPositionOfCompany (context, access) {
      const response = await AxiosAdminManage.editPositionOfCompany(access)
      await context.commit('mutationseditPositionOfCompany', response)
    },
    // List User In Seller
    async actionsListPositionSellerUser (context, access) {
      var responseData = await AxiosAdminManage.ListPositionUserSeller(access)
      await context.commit('mutatonListPositionSellerUser', responseData)
    },
    async actionsDetailUserPositionSeller (context, access) {
      var responseData = await AxiosAdminManage.DetailPositionUserSeller(access)
      await context.commit('mutationsDetailPositionSellerUser', responseData)
    },
    async actionsSearchUserPositionSeller (context, access) {
      var responseData = await AxiosAdminManage.SearchPositionUserSeller(access)
      await context.commit('mutationsSearchPositionSellerUser', responseData)
    },
    async actionsEditUserPositionSeller (context, access) {
      var responseData = await AxiosAdminManage.EditPositionUserSeller(access)
      await context.commit('mutationsEditPositionSellerUser', responseData)
    },
    async actionsAddUserPositionSeller (context, access) {
      var responseData = await AxiosAdminManage.AddPositionUserSeller(access)
      await context.commit('mutationsAddPositionSellerUser', responseData)
    },
    async actionsListPositionShop (context, access) {
      var responseData = await AxiosAdminManage.ListPositionShop(access)
      await context.commit('mutationsListPositionShop', responseData)
    },
    async actionsEstimateQu (context, access) {
      var responseData = await AxiosAdminManage.estimateQu(access)
      await context.commit('mutationsEstimateQu', responseData)
    },
    async actionsGetPayment (context, access) {
      var responseData = await AxiosAdminManage.GetPayment(access)
      await context.commit('mutationsGetPayment', responseData)
    },
    async actionsCreateQuCart (context, access) {
      var responseData = await AxiosAdminManage.CreateQUCart(access)
      await context.commit('mutationsCreateQUCart', responseData)
    },
    async actionsCreateCompanyByBiz (context) {
      var responseData = await AxiosAdminManage.CreateCompanyByBiz()
      await context.commit('mutationsCreateCompanyByBiz', responseData)
    },
    async actionsDashboardAdminAll (context, access) {
      console.log('เข้า 1', access)
      var responseData = await AxiosAdminManage.DashBorardAdmin(access)
      await context.commit('mutationsDashboardAdminAll', responseData)
    },
    async actionsAllGP (context, access) {
      console.log('เข้า 1', access)
      var responseData = await AxiosAdminManage.allGpOfsystem(access)
      await context.commit('mutationsAllGP', responseData)
    },
    // start admin panit manage
    async actionsGetListAdminPlatform (context) {
      var responseData = await AxiosAdminManage.GetListAdminPlatform()
      await context.commit('mutationsGetListAdminPlatform', responseData)
    },
    async actionsCreateAdminPlatform (context, access) {
      var responseData = await AxiosAdminManage.CreateAdminPlatform(access)
      await context.commit('mutationsCreateAdminPlatform', responseData)
    },
    async actionsEditAdminPlatform (context, access) {
      var responseData = await AxiosAdminManage.EditAdminPlatform(access)
      await context.commit('mutationsEditAdminPlatform', responseData)
    },
    async actionsDetailAdminPlatform (context, access) {
      var responseData = await AxiosAdminManage.DetailAdminPlatform(access)
      await context.commit('mutationsDetailAdminPlatform', responseData)
    },
    async actionsDeleteAdminPlatform (context, access) {
      var responseData = await AxiosAdminManage.DeleteAdminPlatform(access)
      await context.commit('mutationsDeleteAdminPlatform', responseData)
    },
    async actionsSearchAdminPlatform (context, access) {
      var responseData = await AxiosAdminManage.SearchAdminPlatform(access)
      await context.commit('mutationsSearchAdminPlatform', responseData)
    }
    // end admin panit manage
  }
}

export default ModuleAdminManage
