import axios from 'axios'
import { Decode } from '@/services'

const GetToken = () => {
  if (localStorage.getItem('oneData') !== null) {
    const oneData = JSON.parse(Decode.decode(localStorage.getItem('oneData')))
    const auth = {
      headers: { Authorization: `Bearer ${oneData.user.access_token}` }
    }
    return auth
  } else {
    const auth = ''
    return auth
  }
}

export default {
  async reviewListBuyer (data) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}api/check_list_review`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async listOrderReviewBuyer (data) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}api/list_order_review_buyer`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  // async actionsDetailReviewBuyer (data) {
  //   const auth = await GetToken()
  //   try {
  //     var response = await axios.post(`${process.env.VUE_APP_BACK_END}api/detail_tier_and_extra_document`, data, auth)
  //     return response.data
  //   } catch (error) {
  //     return error.response.data
  //   }
  // },
  // Check list review product buyer
  async checkListReviewProductBuyer (val) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}api/check_list_review`, val, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  // Create Comment review product buyer
  async createReviewProductBuyer (val) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}api/create_comment_review_v2`, val, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  // Edit Comment review product buyer
  async editReviewProductBuyer (val) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}api/edit_comment_review`, val, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  }
}
