import axios from 'axios'
import { Decode } from '@/services'

const GetToken = () => {
  if (Object.prototype.hasOwnProperty.call(localStorage, 'oneData')) {
    const oneData = JSON.parse(Decode.decode(localStorage.getItem('oneData')))
    const auth = {
      headers: { Authorization: `Bearer ${oneData.user.access_token}` }
    }
    return auth
  }
}

export default {
  async SaleOrderDetailSettingEtax (val) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}api/g/detail_setting_etax`, val, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async SaleOrderCheckETaxInShop (val) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}api/g/check_shop_etax_credentials`, val, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async SaleOrderSendAccessETax (val) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END2}sale-order/etax/shop/setCredentials`, val, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async SaleOrderGetInvoice (data) {
    try {
      // var response = await axios.post(`${process.env.VUE_APP_BACK_END2}invoice/getInvoice`, data)
      var response = await axios.post(`${process.env.VUE_APP_BACK_END2}sale-order/invoice/getInvoice`, data)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async SaleOrderUpsertInvoice (data) {
    try {
      // var response = await axios.post(`${process.env.VUE_APP_BACK_END2}invoice/upsert`, data)
      var response = await axios.post(`${process.env.VUE_APP_BACK_END2}sale-order/invoice/upsert`, data)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async SaleOrderAddInvoice (data) {
    try {
      // var response = await axios.post(`${process.env.VUE_APP_BACK_END2}invoice/create/v2`, data)
      var response = await axios.post(`${process.env.VUE_APP_BACK_END2}sale-order/invoice/create/v2`, data)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async GetInvoiceAddress (data) {
    try {
      // var response = await axios.post(`${process.env.VUE_APP_BACK_END2}invoice/getInvoice/v2`, data)
      var response = await axios.post(`${process.env.VUE_APP_BACK_END2}sale-order/invoice/getInvoice/v2`, data)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async CreateaEtax (data) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}api/g/create_etax`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async DownloadEtaxDocument (data) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END2}sale-order/etax/document`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async CheckTaxId (data) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}api/g/check_tax_id`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async ListDocumentEtax (data) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}api/g/list_document_etax`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async DetailDocumentEtax (data) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}api/g/detail_document_etax`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async CreateDocumentEtax (data) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}api/g/create_document_etax`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async EditDocumentEtax (data) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}api/g/edit_document_etax`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async UpdateStatusDocumentEtax (data) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}api/g/update_status_document_etax`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async LoginBySharedTokenEfac () {
    const auth = await GetToken()
    try {
      var response = await axios.get(`${process.env.VUE_APP_BACK_END}api/login_by_shared_token_efac`, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async UpdateEtaxDocument (data) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}api/g/update_etax_document`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async DetailEtaxDocument (val) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}api/g/detail_etax_document`, val, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async ListEtaxDocumentOrder (val) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}api/g/list_etax_document_order`, val, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async CreateCreditNote (val) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}api/g/create_credit_note`, val, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  }
}
