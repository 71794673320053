import axios from 'axios'
import { Decode } from '@/services'

const GetToken = () => {
  if (Object.prototype.hasOwnProperty.call(localStorage, 'oneData')) {
    const oneData = JSON.parse(Decode.decode(localStorage.getItem('oneData')))
    // console.log('ONEDATA NAAAa', oneData)
    const auth = {
      headers: { Authorization: `Bearer ${oneData.user.access_token}` }
    }
    return auth
  }
}

export default {
  // ติดตามสถานะส่วนขอผู้ขาย(start)
  async GetTackingDataAlls (val) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END2}mobilyst/getOrderForShop`, val, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  // async GetTackingDataAlls(val) {
  //   try {
  //     var response = await axios.post(`${process.env.VUE_APP_BACK_END2}mobilyst/getAllOrderByShopID`, val)
  //     return response.data
  //   } catch (error) {
  //     return error.response.data
  //   }
  // },
  async GetTackingDataReturn (data) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END2}mobilyst/getOrderReturn`, data, auth)
      // console.log('response user', response.data)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  // ติดตามสถานะส่วนขอผู้ขาย(End)

  // ติดตามสถานะส่วนขอผู้ซื้อ(start)
  async GetTackingAll (val) {
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END2}mobilyst/getOrderForUser`, val)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async GetTackingReturn (data) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END2}mobilyst/getOrderReturn`, data, auth)
      // console.log('response user', response.data)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  // ติดตามสถานะส่วนของผู้ซื้อ(End)

  // ตืดตามสถานะส่วนของบริษัท(start)
  async GetTackingAllCompany (val) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END2}mobilyst/getOrderForCompany`, val, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  }
  // ตืดตามสถานะส่วนของบริษัท(End)
}
