import AxiosRegister from './axios_register_api'

const ModuleRegister = {
  state: {
    stateGetSellerShop: [],
    stateRegisterbuyer: [],
    stateConfirmOTPOneID: []
  },
  mutations: {
    mutationsGetSellerShop (state, data) {
      state.stateGetSellerShop = data
    },
    mutationsRegisterbuyer (state, data) {
      state.stateRegisterbuyer = data
    },
    mutationsConfirmOTPOneID (state, data) {
      state.stateConfirmOTPOneID = data
    }
  },
  actions: {
    async actionsGetSellerShop (context, access) {
      const responseGetSellerShop = await AxiosRegister.GetSellerShop(access)
      // console.log('test', responseGetSellerShop)
      await context.commit('mutationsGetSellerShop', responseGetSellerShop)
    },
    async actionsRegisterbuyer (context, access) {
      const responseGetSellerShop = await AxiosRegister.PostRegisterbuyer(access)
      await context.commit('mutationsRegisterbuyer', responseGetSellerShop)
    },
    async actionsConfirmOTPOneID (context, access) {
      const confirmOTPOneID = await AxiosRegister.ConfirmOTPOneID(access)
      await context.commit('mutationsConfirmOTPOneID', confirmOTPOneID)
    }
  }
}
export default ModuleRegister
