import axios from 'axios'
import { Decode } from '@/services'

const GetToken = () => {
  if (localStorage.getItem('oneData') !== null) {
    const oneData = JSON.parse(Decode.decode(localStorage.getItem('oneData')))
    const auth = {
      headers: { Authorization: `Bearer ${oneData.user.access_token}` }
    }
    return auth
  } else {
    const auth = ''
    return auth
  }
}

export default {
  // Setting Tier List
  async settingTierList (data) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}api/list_tier_and_extra_document`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  // Create Tier List
  async createTier (data) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}api/add_tier_and_extra_document`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  // Edit Tier List
  async editTier (data) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}api/edit_tier_and_extra_document`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  // Detail Tier List
  async detailTier (data) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}api/detail_tier_and_extra_document`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  // Update status Tier List
  async updateStatusTier (data) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}api/update_status_tier_and_extra_document`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  // Turn on / off request partner Tier List
  async turnOnOffRequessPartner (data) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}api/turn_on_off_request_partner`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  }
}
