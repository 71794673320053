import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'

Vue.use(Vuetify)

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: '#27AB9C',
        backgroundTable: '#E6F5F3',
        fontTable: '#27AB9C'
      }
    }
  }
})
