import AxiosGraczProduct from './axios_gracz_product_api'

const ModuleGraczProduct = {
  state: {
    stateGraczProductList: [],
    stateGraczSearchProduct: [],
    stateGraczProductDetail: [],
    stateGraczProductSelectOption: []
  },
  mutations: {
    mutationsGraczProductList (state, data) {
      state.stateGraczProductList = data
    },
    mutationsGraczSearchProduct (state, data) {
      state.stateGraczSearchProduct = data
    },
    mutationsGraczProductDetail (state, data) {
      state.stateGraczProductDetail = data
    },
    mutationsGraczProductSelectOption (state, data) {
      state.stateGraczProductSelectOption = data
    }
  },
  actions: {
    async actionsGraczProductList (context, access) {
      var response = await AxiosGraczProduct.GraczProductList(access)
      await context.commit('mutationsGraczProductList', response)
    },
    async actionsGraczSearchProduct (context, access) {
      var response = await AxiosGraczProduct.GraczSearchProduct(access)
      await context.commit('mutationsGraczSearchProduct', response)
    },
    async actionsGraczProductDetail (context, access) {
      var response = await AxiosGraczProduct.GraczProductDetail(access)
      await context.commit('mutationsGraczProductDetail', response)
    },
    async actionsGraczProductSelectOption (context, access) {
      var response = await AxiosGraczProduct.GraczProductSelectOption(access)
      await context.commit('mutationsGraczProductSelectOption', response)
    }
  }
}
export default ModuleGraczProduct
