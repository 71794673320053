import AxiosShop from './axios_department'

const ModuleDepartment = {
  state: {
    //  List Department
    stateListDepartment: [],
    // detail Deparment
    stateDetailDepartment: [],
    // Create Department
    stateCreateDepartment: [],
    // Edit Depoartment
    stateEditDepartment: [],
    // Delect Department
    stateDeleteDepartment: []
  },
  mutations: {
    //  List Department
    mutationsListDepartment (state, data) {
      state.stateListDepartment = data
    },
    // Detail  Department
    mutationsDetailDepartment (state, data) {
      state.stateDetailDepartment = data
    },
    // Create Department
    mutationsCreateDepartment (state, data) {
      state.stateCreateDepartment = data
    },
    //  Edit Department
    mutationsEditDepartment (state, data) {
      state.stateEditDepartment = data
    },
    mutationsDeleteDepartment (state, data) {
      state.stateDeleteDepartment = data
    }
  },
  actions: {
    // Shop  List Department
    async actionsListDepartment (context, access) {
      const responseData = await AxiosShop.GetListDepartment(access)
      await context.commit('mutationsListDepartment', responseData)
    },
    // Detail Department
    async actionsDetailDepartment (context, access) {
      const responseData = await AxiosShop.DetailDepartment(access)
      await context.commit('mutationsDetailDepartment', responseData)
    },
    // Create Department
    async actionsCreateDepartment (context, access) {
      const responseData = await AxiosShop.CreateDepartment(access)
      await context.commit('mutationsCreateDepartment', responseData)
    },
    // Edit Department
    async actionsEditDepartment (context, access) {
      const responseData = await AxiosShop.EditDepartment(access)
      await context.commit('mutationsEditDepartment', responseData)
    },
    async actionsDeleteDepartment (context, access) {
      const responseData = await AxiosShop.DeleteDepartment(access)
      await context.commit('mutationsDeleteDepartment', responseData)
    }
  }
}
export default ModuleDepartment
