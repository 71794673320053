<template>
  <v-app>
    <ModalPDPA ref="ModalPDPA"/>
    <router-view/>
    <Loader/>
  </v-app>
</template>

<script>
import '@/styles/main.scss'
import Loader from '@/components/sharedComponent/Loader'
import { Decode } from '@/services'
export default {
  components: {
    Loader,
    ModalPDPA: () => import('@/components/Modal/PDPA')
  },
  metaInfo () {
    return {
      title: 'Panit | สินค้า e-commerce หลากหลาย',
      titleTemplate: '%s',
      htmlAttrs: {
        lang: 'th-TH'
      },
      meta: [
        { charset: 'utf-8' },
        { name: 'viewport', content: 'width=device-width, initial-scale=1' },
        { property: 'og:title', content: 'Panit | สินค้า e-commerce หลากหลาย', template: 'Panit | สินค้า e-commerce หลากหลาย', vmid: 'og:title' }
      ],
      link: [
        { rel: 'canonical', href: 'https://testsitepanit.one.th/' }
      ]
    }
  },
  async created () {
    this.$EventBus.$on('checkPDPA', this.checkPDPA)
    if (localStorage.getItem('oneData') !== null) {
      this.checkOneData()
    }
  },
  methods: {
    async checkOneData () {
      const oneData = JSON.parse(Decode.decode(localStorage.getItem('oneData')))
      console.log(oneData)
      if (oneData.user !== '' && oneData.user !== undefined) {
        await this.checkPDPA(oneData.user.one_id)
      }
    },
    async checkPDPA (id) {
      var data = { user_id: id }
      var res = await this.axios.post(`${process.env.VUE_APP_BACK_END2}consent/create`, data)
      if (res.data.data.consent_form.status === 'pending' || res.data.data.consent_form.status === 'rejected') {
        this.$refs.ModalPDPA.open(id)
      }
    }
  }
}
</script>

<style>
@import './assets/styles.css';
.swal2-container {
  z-index: 100000000 !important;
}
</style>
