import AxiosGraczChannel from './axios_gracz_channel_api'

const ModuleGraczChannel = {
  state: {
    stateGraczListChannel: [],
    stateGraczDetailChannel: [],
    stateGraczCreateChannel: [],
    stateGraczEditChannel: [],
    stateGraczTurnOnOffChannel: [],
    stateGraczDeleteChannel: []
  },
  mutations: {
    mutationsGraczListChannel (state, data) {
      state.stateGraczListChannel = data
    },
    mutationsGraczDetailChannel (state, data) {
      state.stateGraczDetailChannel = data
    },
    mutationsGraczCreateChannel (state, data) {
      state.stateGraczCreateChannel = data
    },
    mutationsGraczEditChannel (state, data) {
      state.stateGraczEditChannel = data
    },
    mutationsGraczTurnOnOffChannel (state, data) {
      state.stateGraczTurnOnOffChannel = data
    },
    mutationsGraczDeleteChannel (state, data) {
      state.stateGraczDeleteChannel = data
    }
  },
  actions: {
    async actionsGraczListChannel (context, access) {
      var response = await AxiosGraczChannel.GraczListChannel(access)
      await context.commit('mutationsGraczListChannel', response)
    },
    async actionsGraczDetailChannel (context, access) {
      var response = await AxiosGraczChannel.GraczDetailChannel(access)
      await context.commit('mutationsGraczDetailChannel', response)
    },
    async actionsGraczCreateChannel (context, access) {
      var response = await AxiosGraczChannel.GraczCreateChannel(access)
      await context.commit('mutationsGraczCreateChannel', response)
    },
    async actionsGraczEditChannel (context, access) {
      var response = await AxiosGraczChannel.GraczEditChannel(access)
      await context.commit('mutationsGraczEditChannel', response)
    },
    async actionsGraczTurnOnOffChannel (context, access) {
      var response = await AxiosGraczChannel.GraczTurnOnOffChannel(access)
      await context.commit('mutationsGraczTurnOnOffChannel', response)
    },
    async actionsGraczDeleteChannel (context, access) {
      var response = await AxiosGraczChannel.GraczDeleteChannel(access)
      await context.commit('mutationsGraczDeleteChannel', response)
    }
  }
}
export default ModuleGraczChannel
