import AxiosManageShop from './axios_manage_api'

const ModuleManageShop = {
  state: {
    Category: [],
    Supplier: [],
    Brand: [],
    ProductBySellerID: [],
    EditProduct: '',
    GetUserAddress: '',
    CreateAddressUser: '',
    CheckAddressUserLocal: '',
    UpdateSellerShopDetail: '',
    CreateInventory: '',
    CreateProductShop: '',
    GetInventory: '',
    EditProductShop: '',
    CreateSupplier: '',
    CreateBrand: '',
    listTierAndDoc: []
  },
  mutations: {
    SetCategory (state, data) {
      state.Category = data
    },
    SetSupplier (state, data) {
      state.Supplier = data
    },
    SetBrand (state, data) {
      state.Brand = data
    },
    SetProductBySellerID (state, data) {
      state.ProductBySellerID = data
    },
    SetEditProduct (state, data) {
      state.EditProduct = data
    },
    SetGetUserAddress (state, data) {
      state.GetUserAddress = data
    },
    SetCreateAddressUser (state, data) {
      state.CreateAddressUser = data
    },
    SetCheckAddressUserLocal (state, data) {
      state.CheckAddressUserLocal = data
    },
    SetUpdateSellerShopDetail (state, data) {
      state.UpdateSellerShopDetail = data
    },
    SetCreateInventory (state, data) {
      state.CreateInventory = data
    },
    SetCreateProductShop (state, data) {
      state.CreateProductShop = data
    },
    SetGetInventory (state, data) {
      state.GetInventory = data
    },
    SetEditProductShop (state, data) {
      state.EditProductShop = data
    },
    SetCreateSupplier (state, data) {
      state.CreateSupplier = data
    },
    SetCreateBrand (state, data) {
      state.CreateBrand = data
    },
    SetlistTierAndDoc (state, data) {
      state.listTierAndDoc = data
    }
  },
  actions: {
    async GetCagegory (context) {
      var res = await AxiosManageShop.GetCagegory()
      await context.commit('SetCategory', res)
    },
    async GetSupplier (context) {
      var res = await AxiosManageShop.GetSupplier()
      await context.commit('SetSupplier', res)
    },
    async GetBrand (context) {
      var res = await AxiosManageShop.GetBrand()
      await context.commit('SetBrand', res)
    },
    async GetProductBySellerID (context, data) {
      var res = await AxiosManageShop.GetProductBySellerID(data)
      await context.commit('SetProductBySellerID', res)
    },
    async CreateBrand (context, data) {
      var res = await AxiosManageShop.CreateBrand(data)
      await context.commit('SetCreateBrand', res)
    },
    async CreateSupplier (context, data) {
      var res = await AxiosManageShop.CreateSupplier(data)
      await context.commit('SetCreateSupplier', res)
    },
    async GetDefaultCagegory (context) {
      var res = await AxiosManageShop.GetDefaultCagegory()
      await context.commit('SetCategory', res)
    },
    async GetUserAddress (context, data) {
      var res = await AxiosManageShop.GetUserAddress(data)
      await context.commit('SetGetUserAddress', res)
    },
    async CreateAddressUser (context, data) {
      var res = await AxiosManageShop.CreateAddressUser(data)
      await context.commit('SetCreateAddressUser', res)
    },
    async CheckAddressUserLocal (context, data) {
      var res = await AxiosManageShop.CheckAddressUserLocal(data)
      await context.commit('SetCheckAddressUserLocal', res)
    },
    async UpdateAddressCart (context, data) {
      await AxiosManageShop.UpdateAddressCart(data)
      // await context.commit('SetCreateAddressUser', res)
    },
    async UpdateSellerShopDetail (context, data) {
      var res = await AxiosManageShop.UpdateSellerShopDetail(data.dataShop)
      await context.commit('SetUpdateSellerShopDetail', res)
    },
    async CreateInventory (context, data) {
      var res = await AxiosManageShop.CreateInventory(data)
      await context.commit('SetCreateInventory', res)
    },
    async CreateProductShop (context, data) {
      var res = await AxiosManageShop.CreateProductShop(data)
      await context.commit('SetCreateProductShop', res)
    },
    async EditProductShop (context, data) {
      var res = await AxiosManageShop.EditProductShop(data)
      await context.commit('SetEditProductShop', res)
    },
    async GetInventory (context, data) {
      var res = await AxiosManageShop.GetInventory(data)
      await context.commit('SetGetInventory', res)
    },
    async GetListTierAndDoc (context, data) {
      var res = await AxiosManageShop.ListTierAndDoc(data)
      await context.commit('SetlistTierAndDoc', res)
    }
  }
}
export default ModuleManageShop
