import axios from 'axios'
import { Decode } from '@/services'

const GetToken = () => {
  if (localStorage.getItem('oneData') !== null) {
    const oneData = JSON.parse(Decode.decode(localStorage.getItem('oneData')))
    const auth = {
      headers: { Authorization: `Bearer ${oneData.user.access_token}` }
    }
    return auth
  } else {
    const auth = ''
    return auth
  }
}

export default {
  // Setting Quotation List
  async settingQuotationList (data) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}api/qu_setting/list`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  // Create Quotation List
  async createQuotation (data) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}api/qu_setting/create`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  // Edit Quotation List
  async editQuotation (data) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}api/qu_setting/edit`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  // Detail Quotation List
  async detailQuotation (data) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}api/qu_setting/detail`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  // Update status Quotation List
  async updateStatusQuotation (data) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}api/qu_setting/update_status`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  }
}
