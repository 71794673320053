<template lang="html">
  <div>
    <v-overlay :value="Loader" style="z-index: 99999;">
      <v-progress-circular indeterminate size="64" color="#00B500"></v-progress-circular>
    </v-overlay>
  </div>
</template>

<script>
export default {
  computed: {
    Loader () {
      return this.$store.state.ModuleGlobal.Loader
    }
  }
}
</script>

<style lang="css" scoped>
</style>
