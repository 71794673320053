import axios from 'axios'
import { Decode } from '@/services'

const GetToken = () => {
  if (Object.prototype.hasOwnProperty.call(localStorage, 'oneData')) {
    const oneData = JSON.parse(Decode.decode(localStorage.getItem('oneData')))
    const auth = {
      headers: { Authorization: `Bearer ${oneData.user.access_token}` }
    }
    return auth
  }
}

export default {
  async GraczDashbord (data) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END2}sale-order/dashboard/admin`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async GraczDashbordTopTen (data) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END2}sale-order/dashboard_top10/admin`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async GraczDashbordSales (data) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END2}sale-order/dashboard/sales`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async GraczDashbordTopTenSales (data) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END2}sale-order/dashboard_top10/sales`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  }
}
