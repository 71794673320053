import Vue from 'vue'
import Vuex from 'vuex'
import ModuleGlobal from './store_global'
import ModuleHompage from './store_homepage_api/vuex_homepage_api'
import ModuleProduct from './store_product_api/vuex_product_api'
import ModuleShop from './store_shop/vuex_shop_api'
import ModuleCart from './store_Cart/vuex_cart_api'
import ModuleManageShop from './store_manage_shop/vuex_manage_api'
import ModuleUser from './store_user/vuex_user_api'
import ModuleOrder from './store_order/vuex_order_api'
import ModuleBusiness from './store_Business/vuex_business'
import ModuleRegister from './store_Register/vuex_register_api'
import ModuleInventory from './store_inventory/vuex_inventory_api'
import ModuleProductNode from './store_product_api_node/vuex_product_api_node'
import ModuleFavoriteProduct from './store_favorite_product/vuex_ups_favorite_product_api'
import ModuleAdminManage from './store_admin_manage/vuex_admin_manage'
import ModuleDepartment from './store_Departments/vuex_deparment'
import ModuleCurier from './store_Curier/vuex_curier'
import ModuleTacking from './store_Tacking/vuex_tacking_api'
import ModuleRegisMorpromt from './store_regis_morpromt/vuex_regis_morpromt'
import ModuleNotification from './store_notification/vuex_notification_api'
import ModulePartner from './store_Partner/vuex_partner_api'
import ModuleSettingQuotation from './store_setting_quotation/vuex_setting_quotation_api'
import ModuleSettingTier from './store_setting_tier/vuex_setting_tier_api'
import ModuleReviewBuyer from './store_review_buyer/vuex_review_buyer_api'
import ModuleETax from './store_Etax/vuex_Etax_api'
import ModuleMyCouponsPoints from './store_MyCuoponsAndPoints/vuex_mycoupnsandpoints'
import ModuleAdminPanit from './store_admin_panit/vuex_admin_panit'
import ModuleApprove from './store_approve/vuex_approve'
import ModuleGraczUser from './store_gracz_user/vuex_gracz_user_api'
import ModuleGraczProduct from './store_gracz_product/vuex_gracz_product_api'
import ModuleGraczProductPHP from './store_gracz_product_php/vuex_gracz_product_php_api'
import ModuleGraczCategory from './store_gracz_category/vuex_gracz_category_api'
import ModuleGraczBrand from './store_grazc_brand/vuex_gracz_brand_api'
import ModuleGraczSupplier from './store_gracz_supplier/vuex_gracz_supplier_api'
import ModuleGraczAdmin from './store_gracz_admin/vuex_gracz_admin_api'
import ModuleGraczChannel from './store_gracz_channel/vuex_gracz_channel_api'
import ModuleGraczEmployee from './store_gracz_employee/vuex_gracz_employee_api'
import ModuleGraczCart from './store_gracz_cart/vuex_gracz_cart_api'
import ModuleGraczShop from './store_gracz_shop/vuex_gracz_shop_api'
import ModuleGraczCustomer from './store_gracz_customer/vuex_gracz_customer_api'
import ModuleGraczPromotion from './store_gracz_promotion/vuex_gracz_promotion_api'
import ModuleGraczPromotionJS from './store_gracz_promotion_js/vuex_gracz_promotion_js_api'
import ModuleGraczOrder from './store_gracz_order/vuex_gracz_order_api'
import ModuleGraczDashbord from './store_gracz_dashbord/vuex_gracz_dashbord_api'
import ModuleSaleOrderEtax from './store_gracz_etax/vuex_gracz_etax_api'

Vue.use(Vuex)

export default new Vuex.Store({
  namespaced: true,
  getters: {
  },
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    ModuleGlobal,
    ModuleHompage,
    ModuleProduct,
    ModuleShop,
    ModuleCart,
    ModuleManageShop,
    ModuleUser,
    ModuleOrder,
    ModuleBusiness,
    ModuleRegister,
    ModuleInventory,
    ModuleProductNode,
    ModuleFavoriteProduct,
    ModuleAdminManage,
    ModuleDepartment,
    ModuleCurier,
    ModuleTacking,
    ModuleRegisMorpromt,
    ModuleNotification,
    ModulePartner,
    ModuleSettingQuotation,
    ModuleSettingTier,
    ModuleReviewBuyer,
    ModuleETax,
    ModuleMyCouponsPoints,
    ModuleAdminPanit,
    ModuleApprove,
    ModuleGraczUser,
    ModuleGraczProduct,
    ModuleGraczProductPHP,
    ModuleGraczCategory,
    ModuleGraczChannel,
    ModuleGraczBrand,
    ModuleGraczSupplier,
    ModuleGraczAdmin,
    ModuleGraczEmployee,
    ModuleGraczCart,
    ModuleGraczShop,
    ModuleGraczCustomer,
    ModuleGraczPromotion,
    ModuleGraczPromotionJS,
    ModuleGraczOrder,
    ModuleGraczDashbord,
    ModuleSaleOrderEtax
  }
})
