import axios from 'axios'
import { Decode } from '@/services'

const GetToken = () => {
  if (Object.prototype.hasOwnProperty.call(localStorage, 'oneData')) {
    const oneData = JSON.parse(Decode.decode(localStorage.getItem('oneData')))
    // console.log('ONEDATA NAAAa', oneData)
    const auth = {
      headers: { Authorization: `Bearer ${oneData.user.access_token}` }
    }
    return auth
  }
}

export default {
  // get_MyCuoponsAndPointt
  async GetMyCuoponsAndPoint (data) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}api/get_coupon_and_point`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  // get All Coupons In My Company
  async GetCompanyCuoponsAndPoint (data) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}api/coupon_and_point_purchaser`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async GetHomeCouponsAll (data) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}api/coupon_and_point_purchaser`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async GetAllCouponInShop (data) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}api/coupon_ngs/get_all_coupon_in_shop`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async KeepCouponInShop (data) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}api/coupon_ngs/collect_coupons`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async GetCouponCart (data) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}api/get_coupon_with_cart`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async GetPointsWithCart (data) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}api/get_points_with_cart`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async ReserveCoupon (data) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}api/reserve_coupon`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async CancelCoupon (data) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}api/cancel_coupon`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async ReservePoint (data) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}api/reserve_point`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async CancelPoint (data) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}api/cancel_point`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async SearchCoupon (data) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}api/search_code`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  //  Use Code From NGS
  async UseCodeFromNGS (data) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}api/use_code_ngs`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  }
}
