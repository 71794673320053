import axios from 'axios'
import { Decode } from '@/services'

const GetToken = () => {
  if (Object.prototype.hasOwnProperty.call(localStorage, 'oneData')) {
    const oneData = JSON.parse(Decode.decode(localStorage.getItem('oneData')))
    const auth = {
      headers: { Authorization: `Bearer ${oneData.user.access_token}` }
    }
    return auth
  }
}

export default {
  async GraczListOrder (data) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END2}onlineOrder/listOrder`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async GraczListOrderSaleOrder (data) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END2}onlineOrder/listOrder/v2`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async GraczDetailOrder (data) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}api/g/detail_order`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async GraczDetailOrderSaleOrder (data) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}api/g/so_detail_order`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async GraczExportOrderData (data) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END2}onlineOrder/listOrder/exportToExcel`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async GraczRecoverOrder (data) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END2}onlineOrder/recoverOrder`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async GraczDeleteOrder (data) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END2}onlineOrder/deleteOrder`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async GraczInactiveOrder (data) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END2}onlineOrder/listOrdersInactive`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async GraczInactiveOrderSaleOrder (data) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END2}onlineOrder/listOrdersInactive/v2`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  }
}
