import Vue from 'vue'
import VueGtm from '@gtm-support/vue2-gtm'
import VueRouter from 'vue-router'
import VueMeta from 'vue-meta'

Vue.use(VueRouter)
Vue.use(VueMeta, {
  keyName: 'metaInfo', // the component option name that vue-meta looks for meta info on.
  attribute: 'data-vue-meta', // the attribute name vue-meta adds to the tags it observes
  ssrAttribute: 'data-vue-meta-server-rendered', // the attribute name that lets vue-meta know that meta info has already been server-rendered
  tagIDKeyName: 'vmid' // the property name that vue-meta uses to determine whether to overwrite or append a tag
})

const routes = [
  // {
  //   path: '/',
  //   component: () => import('@/views/Home'),
  //   children: [
  //     { path: '/', name: 'Home', component: () => import('@/views/HomeProduct') },
  //     { path: '/ListProduct/:data', name: 'ListProduct', component: () => import('@/views/ListProduct') },
  //     { path: '/DetailProduct/:data', name: 'DetailProduct', component: () => import('@/views/DetailProduct') },
  //     { path: '/ManageShop', name: 'ManageShop', component: () => import('@/views/CreateShop') },
  //     { path: '/MyShop', name: 'MyShop', component: () => import('@/views/MyShop') },
  //     { path: '/Shoppage/:data', name: 'Shoppage', component: () => import('@/components/Shop/ShopHomepage.vue') },
  //     { path: '/shoppingcart', name: 'ShoppingCart', component: () => import('@/views/Cart/ShoppingCart') },
  //     { path: '/checkout', name: 'Checkout', component: () => import('@/views/Cart/Checkout') },
  //     { path: '/search/:data', name: 'search', component: () => import('@/components/Search/SearchResult.vue') },
  //     { path: '/userprofile', name: 'UserProfile', component: () => import('@/views/UserProfile') },
  //     { path: '/search-shop/:data', name: 'search-shop', component: () => import('@/components/Search/AllShopSearch.vue') },
  //     { path: '/pobuyer', name: 'pobuyer', component: () => import('@/views/POBuyer') },
  //     { path: '/pobuyer', name: 'pobuyer', component: () => import('@/views/POBuyer') },
  //     {
  //       path: '/seller',
  //       component: () => import('../views/SellerShop'),
  //       children: [
  //         { path: '/seller', name: 'seller', component: () => import('@/components/Shop/ShopProduct/ShopProduct') },
  //         { path: '/manageproduct', name: 'manageproduct', component: () => import('@/views/CreateProduct') },
  //         { path: '/shop', name: 'shop', component: () => import('@/components/Shop/CreateShop') },
  //         { path: '/poseller', name: 'poseller', component: () => import('@/views/POSeller') },
  //         { path: '/orderdetailseller', name: 'orderdetailseller', component: () => import('@/views/OrderDetailSeller') },
  //         { path: '/inventory', name: 'inventory', component: () => import('@/views/Inventory') }
  //       ]
  //     },
  //     { path: '/createbusinesssid', name: 'createbusinesssid', component: () => import('@/views/Business/CreateBusinessId') },
  //     { path: '/detailbusinesssid', name: 'detailbusinesssid', component: () => import('@/views/Business/DetailBusinessId') }
  //   ]
  // },
  // ,{ path: '/quotationpagecart', name: 'QuotationPageCart', component: () => import('../views/QuotationPageCart.vue') },
  // {
  //   path: '/seller',
  //   component: () => import('../views/SellerShop'),
  //   children: [
  //     { path: '/seller', name: 'seller', component: () => import('@/components/Shop/ShopProduct/ShopProduct') },
  //     { path: '/manageproduct', name: 'manageproduct', component: () => import('../views/CreateProduct') },
  //     { path: '/shop', name: 'shop', component: () => import('@/components/Shop/CreateShop') },
  //     { path: '/poseller', name: 'poseller' component: () => import('../views/POSeller') },
  //     { path: '/orderdetailseller', name: 'orderdetailseller', component: () => import('../views/OrderDetailSeller') }
  //   ]
  // },
  // { path: '/admin', component: () => import('@/views/AdminProduct') },
  // { path: '/quotation', name: 'Quotation', component: () => import('@/views/Quotation') },
  // { path: '/quotation1shop', name: 'Quotation1', component: () => import('@/views/Quotation1shop') },
  // { path: '/quotationSeller', name: 'QuotationSeller', component: () => import('@/views/QuotationSeller') },
  // {
  //   path: '/companyadmin',
  //   component: () => import('@/views/CompanyAdmin'),
  //   children: [
  //     { path: '/detail', component: () => import('@/views/DepartmentAndDivision/DetailUser') },
  //     { path: '/departmentanddivision', component: () => import('@/views/DepartmentAndDivision/TableDepartment') },
  //     { path: '/createdepartment', component: () => import('@/views/DepartmentAndDivision/CreateDepartment') },
  //     { path: '/detaildepartment', component: () => import('@/views/DepartmentAndDivision/DetailDepartment') },
  //     { path: '/user', name: 'user', component: () => import('@/views/User/UserTable') },
  //     { path: '/createuser', name: 'createuser', component: () => import('@/components/User/CreateUser') },
  //     { path: '/detailuser', name: 'detailuser', component: () => import('@/components/User/DetailUser') },
  //     { path: '/edituser', name: 'edituser', component: () => import('@/components/User/EditUser') },
  //     { path: '/sittingBuyer', name: 'sittingBuyer', component: () => import('@/components/User/SettingBuyer') },
  //     { path: '/editBuyer', name: 'editBuyer', component: () => import('@/components/User/EditBuyer') },
  //     { path: '/hidecategory', name: 'hidecategory', component: () => import('@/views/HideCategory') }
  //   ]
  // },
  // { path: '/Register', name: 'Register', component: () => import('@/views/learning/Register') },
  { path: '/RedirectPaymentPage', component: () => import('@/components/Cart/RedirectPayment') },
  { path: '/Order/RedirectPaymentPage', component: () => import('@/components/Gracz/Sales/Cart/RedirectPayment') },
  { path: '/redirect', component: () => import('@/views/redirect') },
  // { path: '/404', component: () => import('@/views/404') },
  // { path: '/System-offline', component: () => import('@/views/Renovations') },
  {
    path: '/',
    name: 'Home',
    component: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "Home" */ '@/views/HomeUI'),
    children: [
      { path: '/404', component: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "home-chunk" */ '@/views/404') },
      { path: '/System-offline', component: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "home-chunk" */ '@/views/Renovations') },
      { path: '/', name: 'HomeProduct', component: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "home-chunk" */ '@/views/HomeProductUI') },
      { path: '/AllCoupons', name: 'AllCoupons', component: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "home-chunk" */ '@/components/Home/CouponHome/AllCouponHome') },
      { path: '/otpLogin', name: 'otpLogin', component: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "home-chunk" */ '@/components/Home/LoginWithOTP/OtpLogin') },
      { path: '/otpVerificationLogin', name: 'otpVerification', component: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "home-chunk" */ '@/components/Home/LoginWithOTP/OtpVerificationLogin') },
      { path: '/Login', name: 'LoginMobile', component: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "home-chunk" */ '@/components/Home/LoginMobile') },
      { path: '/Register', name: 'RegisterMobile', component: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "home-chunk" */ '@/components/Home/RegisterMobile') },
      { path: '/ListProduct/:data', name: 'ListProduct', component: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "listproduct-chunk" */ '@/views/ListProductUI') },
      { path: '/AllCouponInShop/:data', name: 'AllCouponInShop', component: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "cupon-chunk" */ '@/components/Shop/AllCouponInShop') },
      { path: '/ListProductShop/:data', name: 'ListProductShop', component: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "listproduct-chunk" */ '@/views/ListProductShop') },
      { path: '/ListShopProduct/:data', name: 'ListShopProductUI', component: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "listproduct-chunk" */ '@/components/Shop/ListProductUI') },
      { path: '/DetailProduct/:data', name: 'DetailProduct', component: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "detailProduct-chunk" */ '@/views/DetailProductUI') },
      // { path: '/CategoryPage', name: 'CategoryPage', component: () => import('@/views/CategoryPage') },
      // { path: '/ManageProduct', name: 'ManageProduct', component: () => import('../views/CreateProduct') },
      { path: '/ManageShop', name: 'ManageShop', component: () => import('@/views/CreateShop') },
      { path: '/MyShop', name: 'MyShop', component: () => import('@/views/MyShop') },
      // { path: '/Shoppage/:data', name: 'Shoppage', component: () => import('@/components/Shop/ShopHomepageUI.vue') },
      { path: '/Shoppage/:data', name: 'ShoppageUI', component: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "shopHomePage-chunk" */ '@/components/Shop/ShopHomepageUI.vue') },
      // { path: '/shoppingcart', name: 'shoppingcartui', component: () => import('@/views/Cart/ShoppingCartUI') },
      // { path: '/checkout', name: 'Checkout', component: () => import('@/views/Cart/Checkout') },
      { path: '/allShop', name: 'allShop', component: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "shopHomePage-chunk" */ '@/components/Home/ShopAll/AllShop.vue') },
      { path: '/buyAgain', name: 'buyAgain', component: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "shopHomePage-chunk" */ '@/components/Home/ShopAll/BuyAgain.vue') },
      { path: '/search/:data', name: 'search', component: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "search-chunk" */ '@/components/Search/SearchResultUI.vue') },
      { path: '/AboutPanit', name: 'AboutPanit', component: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "shopHomePage-chunk" */ '@/components/AboutPanit/AboutPanit.vue') },
      { path: '/userprofileMobile', name: 'userprofileMobile', component: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "userprofile-chunk" */ '@/views/UserProfileMobileUI') },
      { path: '/userprofileDetail', name: 'userprofileDetail', component: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "userprofile-chunk" */ '@/components/UserProfile/UserProfileUI') },
      { path: '/favoriteMobile', name: 'favoriteMobile', component: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "userprofile-chunk" */ '@/components/UserProfile/Favorite') },
      { path: '/addressProfileMobile', name: 'addressProfileMobile', component: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "userprofile-chunk" */ '@/components/UserProfile/AddressProfile') },
      { path: '/pobuyerProfileMobile', name: 'pobuyerProfileMobile', component: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "userprofile-chunk" */ '@/views/POBuyerProfileUI') },
      { path: '/pobuyerdetailapproveMobile', name: 'pobuyerdetailapproveMobileui', component: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "userprofile-chunk" */ '@/components/UserProfile/POBuyerDetailApproveUI') },
      { path: '/pobuyerdetailMobile', name: 'pobuyerdetailMobileui', component: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "userprofile-chunk" */ '@/components/UserProfile/POBuyerDetailUI') },
      // { path: '/creditProfile', name: 'creditProfile', component: () => import('@/components/UserProfile/CreditProfile') },
      // { path: '/changePassword', name: 'changePassword', component: () => import('@/components/UserProfile/ChangePassword') },
      { path: '/tackingbuyerMobile', name: 'tackingbuyerMobile', component: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "userprofile-chunk" */ '@/views/TackingBuyerMobile') },
      { path: '/refundProductMobile', name: 'refundProductMobile', component: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "userprofile-chunk" */ '@/views/RefundProductProfile') },
      { path: '/refundDetailMobile', name: 'refundDetailMobile', component: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "userprofile-chunk" */ '@/components/UserProfile/RefundProductProfile/RefundProductDetail') },
      { path: '/reviewBuyerMobile', name: 'reviewBuyerMobile', component: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "userprofile-chunk" */ '@/components/UserProfile/ReviewListBuyer') },
      { path: '/Quotation', name: 'Quotation', component: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "Quotation-chunk" */ '@/components/PurchaseOrder/PurchaseOrder') },
      // { path: '/EtaxCredentailMobile', name: 'EtaxCredentailMobile', component: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "shop-chunk" */ '@/components/Shop/EtaxCredential') },
      { path: '/QuotationAllMobile', name: 'QuotationAllMobile', component: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "shop-chunk" */ '@/components/Quotation/QuotationSeller/ListQuotationSeller') },
      { path: '/QuotationDetailMobile', name: 'QuotationDetailMobile', component: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "shop-chunk" */ '@/components/Quotation/QuotationSeller/DetailQuotationSeller') },
      { path: '/Order/Product', name: 'GraczProduct', component: () => import('@/views/Gracz/Product/ProductPage') },
      { path: '/Order/ProductDetail/:data', name: 'GraczProductDetail', component: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "Gracz-chunk" */ '@/views/Gracz/Product/ProductDetail') },
      { path: '/Order/ShoppingCart', name: 'GraczShoppingCart', component: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "Gracz-chunk" */ '@/views/Gracz/Cart/ShoppingCart') },
      { path: '/Order/Checkout', name: 'GraczCheckout', component: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "Gracz-chunk" */ '@/views/Gracz/Cart/Checkout') },
      { path: '/Order/UserProfileDetailMobile', name: 'GraczUserProfileDetailMobile', component: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "Gracz-chunk" */ '@/components/Gracz/UserProfile') },
      { path: '/Order/UserProfile', name: 'GraczUserProfile', component: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "Gracz-chunk" */ '@/views/Gracz/SalesSidebarMenu') },
      { path: '/Order/UserProfileMobile', name: 'GraczUserProfileMobile', component: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "Gracz-chunk" */ '@/views/Gracz/SalesSidebarMenuMobile') },
      { path: '/Order/SalesOrderMobile', name: 'GraczSalesOrderMobile', component: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "Gracz-chunk" */ '@/components/Gracz/Sales/Order/OrderList') },
      { path: '/Order/SalesOrderDetailMobile', name: 'GraczSalesOrderDetailMobile', component: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "Gracz-chunk" */ '@/components/Gracz/Sales/Order/OrderDetail') },
      { path: '/Order/SalesCustomerListMobile', name: 'GraczSalesCustomerListMobile', component: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "Gracz-chunk" */ '@/components/Gracz/Sales/Customer/SalesCustomerList') },
      { path: '/Order/SalesCustomerDetailMobile', name: 'GraczSalesCustomerDetailMobile', component: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "Gracz-chunk" */ '@/components/Gracz/Sales/Customer/SalesCustomerDetail') },
      { path: '/Order/SellerShopDetailMobile/:data', name: 'GraczSellerShopDetailMobile', component: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "Gracz-chunk" */ '@/components/Gracz/Admin/Shop/SellerShop') },
      { path: '/Order/SellerShop', name: 'GraczSellerShop', component: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "Gracz-chunk" */ '@/views/Gracz/AdminSidebarMenu') },
      { path: '/Order/EditSellerShopMobile', name: 'GraczEditEditSellerShopMobile', component: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "Gracz-chunk" */ '@/components/Gracz/Admin/Shop/EditShop') },
      { path: '/Order/EditPictureShopMobile', name: 'GraczEditPictureShopMobile', component: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "Gracz-chunk" */ '@/components/Gracz/Admin/Shop/EditPicture') },
      { path: '/Order/SellerShopMobile', name: 'GraczSellerShopMobile', component: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "Gracz-chunk" */ '@/views/Gracz/AdminSidebarMenuMobile') },
      { path: '/Order/ProductListMobile', name: 'GraczProductListMobile', component: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "Gracz-chunk" */ '@/components/Gracz/Admin/Product/Product') },
      { path: '/Order/ProductListSaleOrderMobile', name: 'GraczProductSaleOrderListMobile', component: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "Gracz-chunk" */ '@/components/Gracz/Admin/Product/ProductSaleOrder') },
      { path: '/Order/ImportProductMobile', name: 'GraczImportProductMobile', component: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "Gracz-chunk" */ '@/components/Gracz/Admin/Product/PreviewImportProduct') },
      { path: '/Order/ImportPanitProductMobile', name: 'GraczImportPanitProductMobile', component: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "Gracz-chunk" */ '@/components/Gracz/Admin/Product/ImportPanitProduct') },
      { path: '/Order/ManageProductMobile', name: 'GraczManageProductMobile', component: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "Gracz-chunk" */ '@/components/Gracz/Admin/Product/ManageProduct') },
      { path: '/Order/ManageProductSaleOrderMobile', name: 'GraczManageProductSaleOrderMobile', component: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "Gracz-chunk" */ '@/components/Gracz/Admin/Product/ManageProductSaleOrder') },
      { path: '/Order/OrderListMobile', name: 'GraczOrderListMobile', component: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "Gracz-chunk" */ '@/components/Gracz/Admin/Order/OrderList') },
      { path: '/Order/OrderDetailMobile', name: 'GraczOrderDetailMobile', component: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "Gracz-chunk" */ '@/components/Gracz/Admin/Order/OrderDetail') },
      { path: '/Order/DeletedOrderListMobile', name: 'GraczDeletedOrderListMobile', component: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "Gracz-chunk" */ '@/components/Gracz/Admin/Order/DeletedOrderList') },
      { path: '/Order/DeletedOrderDetailMobile', name: 'GraczDeletedOrderDetailMobile', component: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "Gracz-chunk" */ '@/components/Gracz/Admin/Order/DeletedOrderDetail') },
      { path: '/Order/ManageGroupCustomerMobile', name: 'GraczManageGroupCustomerMobile', component: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "Gracz-chunk" */ '@/components/Gracz/Admin/Customer/ManageGroupCustomer') },
      { path: '/Order/CustomerListMobile', name: 'GraczCustomerListMobile', component: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "Gracz-chunk" */ '@/components/Gracz/Admin/Customer/CustomerList') },
      { path: '/Order/CustomerDetailMobile', name: 'GraczCustomerDetailMobile', component: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "Gracz-chunk" */ '@/components/Gracz/Admin/Customer/CustomerDetail') },
      { path: '/Order/ImportCustomerDataMobile', name: 'GraczImportCustomerDataMobile', component: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "Gracz-chunk" */ '@/components/Gracz/Admin/Customer/ImportCustomerData') },
      { path: '/Order/CategoryMobile', name: 'GraczCategoryMobile', component: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "Gracz-chunk" */ '@/components/Gracz/Admin/Category/Category') },
      { path: '/Order/PromotionMobile', name: 'GraczPromotionMobile', component: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "Gracz-chunk" */ '@/components/Gracz/Admin/Promotion/Promotion') },
      { path: '/Order/ManagePromotionMobile', name: 'GraczManagePromotionMobile', component: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "Gracz-chunk" */ '@/components/Gracz/Admin/Promotion/ManagePromotion') },
      { path: '/Order/ManageUserMobile', name: 'GraczManageUserMobile', component: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "Gracz-chunk" */ '@/components/Gracz/Admin/User/ManageUser') },
      { path: '/Order/search/:data', name: 'GraczSearch', component: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "Gracz-chunk" */ '@/components/Gracz/Sales/Product/ProductSearch') },
      { path: '/Order/AdminDashbordMobile', name: 'AdminGraczDashbordMobile', component: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "Gracz-chunk" */ '@/components/Gracz/Admin/Dashbord/Dashbord') },
      { path: '/Order/SalesDashbordMobile', name: 'SalesGraczDashbordMobile', component: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "Gracz-chunk" */ '@/components/Gracz/Sales/Dashbord/Dashbord') },
      { path: '/Order/EtaxCredentialMobile', name: 'GraczEtaxCredentialMobile', component: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "Gracz-chunk" */ '@/components/Gracz/Admin/Etax/EtaxCredential') },
      { path: '/Order/ManageDocumentEtaxMobile', name: 'GraczManageDocumentEtaxMobile', component: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "Gracz-chunk" */ '@/components/Gracz/Admin/Etax/ManageDocumentEtax') },
      { path: '/Order/CreditNoteMobile', name: 'SaleOrderCreditNoteMobile', component: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "Gracz-chunk" */ '@/components/Gracz/Admin/CreditNote/CreditNote') },
      {
        path: '/userprofile',
        component: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "userprofile-chunk" */ '../views/UserProfileUI'),
        children: [
          { path: '/userprofile', name: 'userprofileUI', component: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "userprofile-chunk" */ '@/components/UserProfile/UserProfileUI') },
          { path: '/favorite', name: 'favorite', component: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "userprofile-chunk" */ '@/components/UserProfile/Favorite') },
          { path: '/MyCouponsAndPoints', name: 'MyCouponsAndPoints', component: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "userprofile-chunk" */ '@/components/UserProfile/Coupons/Coupons') },
          { path: '/addressProfile', name: 'addressProfile', component: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "userprofile-chunk" */ '@/components/UserProfile/AddressProfile') },
          { path: '/pobuyerProfile', name: 'pobuyeruiProfile', component: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "userprofile-chunk" */ '@/views/POBuyerProfileUI') },
          { path: '/tackingbuyer', name: 'tackingbuyer', component: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "userprofile-chunk" */ '@/views/TackingBuyer') },
          { path: '/pobuyerdetailapprove', name: 'pobuyerdetailapproveui', component: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "userprofile-chunk" */ '@/components/UserProfile/POBuyerDetailApproveUI') },
          { path: '/pobuyerdetail', name: 'pobuyerdetailui', component: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "userprofile-chunk" */ '@/components/UserProfile/POBuyerDetailUI') },
          { path: '/creditProfile', name: 'creditProfile', component: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "userprofile-chunk" */ '@/components/UserProfile/CreditProfile') },
          { path: '/changePassword', name: 'changePassword', component: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "userprofile-chunk" */ '@/components/UserProfile/ChangePassword') },
          { path: '/refundProduct', name: 'refundProduct', component: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "userprofile-chunk" */ '@/views/RefundProductProfile') },
          { path: '/refundDetail', name: 'refundDetail', component: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "userprofile-chunk" */ '@/components/UserProfile/RefundProductProfile/RefundProductDetail') },
          { path: '/reviewBuyer', name: 'reviewBuyer', component: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "userprofile-chunk" */ '@/components/UserProfile/ReviewListBuyer') }
        ]
      },
      { path: '/search-shop/:data', name: 'search-shop', component: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "search-chunk" */ '@/components/Search/AllShopSearchUI.vue') },
      // { path: '/pobuyer', name: 'pobuyer', component: () => import('@/views/POBuyer') },
      // Mobile Shop Homepage //////////////////////////////////////////////////////////////
      { path: '/sellerMobile', name: 'sellerMobile', component: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "sellers-chunk" */ '../views/SellerShopMobileUI') },
      { path: '/sellerShopMobile', name: 'sellerMobileUI', component: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "sellers-chunk" */ '@/components/Shop/ShopProduct/ShopProductUI') },
      { path: '/sellersMobile', name: 'sellersMobile', component: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "sellers-chunk" */ '@/components/Shop/FirstTimeSellerMobile') },
      { path: '/manageproductMobile', name: 'manageproductMobileUI', component: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "sellers-chunk" */ '@/views/CreateProductUI') },
      { path: '/posellerMobile', name: 'posellerMobileUI', component: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "sellers-chunk" */ '@/views/POSellerUI') },
      { path: '/SettingShopMibile', name: 'SettingShop', component: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "sellers-chunk" */ '@/components/Shop/SettingShop') },
      { path: '/posellerDetailMobile', name: 'posellerDetailMobileUI', component: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "sellers-chunk" */ '@/components/Quotation/POSellerDetailUI') },
      { path: '/CurierMobile', name: 'CurierMobile', component: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "sellers-chunk" */ '@/components/Curier/Curier') },
      { path: '/designShopMobile', name: 'designShopMobileUI', component: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "sellers-chunk" */ '@/components/Shop/SettingShop') },
      { path: '/inventoryMobile', name: 'inventoryMobileUI', component: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "sellers-chunk" */ '@/views/InventoryUI') },
      { path: '/DownloadFilesMobile', name: 'DownloadFilesMobile', component: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "sellers-chunk" */ '@/components/Shop/DownloadFileMobile') },
      { path: '/TackingorderMobile', name: 'TackingorderMobile', component: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "sellers-chunk" */ '@/components/Shop/Tackingorder') },
      { path: '/createShopMobile', name: 'createShopMobile', component: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "sellers-chunk" */ '@/components/Shop/CreateShop/CreateShop') },
      { path: '/stepCreateShopMobile', name: 'stepCreateShopMobile', component: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "sellers-chunk" */ '@/components/Shop/CreateShop/stepCreateShop') },
      { path: '/SettingPartnerRequestMobile', name: 'SettingPartnerRequestMobile', component: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "sellers-chunk" */ '@/views/SettingPartnerRequest') },
      { path: '/SettingTierMobile', name: 'SettingTierMobile', component: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "sellers-chunk" */ '@/views/SettingTier') },
      { path: '/QuotationSettingSellerMobile', name: 'QuotationSettingSellerMobile', component: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "sellers-chunk" */ '@/views/QuotationSettingSeller') },
      { path: '/dashboardMobile', name: 'dashboardMobile', component: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "sellers-chunk" */ '@/views/MobileUI/NewDashboard') },
      { path: '/revenueMobile', name: 'revenueMobile', component: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "sellers-chunk" */ '@/views/RevenueUI') },
      { path: '/returnMobile', name: 'returnMobile', component: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "sellers-chunk" */ '@/views/ReturnUI') },
      { path: '/MyCouponsAndPointsMobile', name: 'MyCouponsAndPointsMobile', component: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "sellers-chunk" */ '@/components/UserProfile/Coupons/CouponsMobile') },
      { path: '/returnDetailMobile', name: 'returnDetailMobile', component: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "sellers-chunk" */ '@/components/return/returnDetail') },
      // { path: '/quotationMobile', name: 'quotationMobile', component: () => import('@/components/PurchaseOrder/QuotationMobile') },
      { path: '/EtaxCredentailMobile', name: 'EtaxCredentailMobile', component: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "sellers-chunk" */ '@/components/Shop/EtaxCredential') },
      { path: '/MerchantShopMobile', name: 'MerchantShopMobile', component: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "sellers-chunk" */ '@/components/Shop/Merchant/Merchant') },
      { path: '/ManagaCuponMobile', mname: 'ManagaCuponMobile', component: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "sellers-chunk" */ '@/components/Shop/Merchant/ManageCupon') },
      { path: '/sellerlistCreditOrderMobile', name: 'sellerlistCreditOrderMobile', component: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "sellers-chunk" */ '@/components/Shop/CreditTerm/ListCreditOrder') },
      { path: '/sellerlistCreditTermMobile', name: 'creditTermMobile', component: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "sellers-chunk" */ '@/components/Shop/CreditTerm/ListCreditTerm') },
      { path: '/sellerInvoicePDFMobile', name: 'sellerInvoicePDFMobile', component: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "sellers-chunk" */ '@/components/Shop/CreditTerm/InvoicePDF') },
      { path: '/manageCommentsMobile', name: 'manageCommentsMobile', component: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "sellers-chunk" */ '@/components/Shop/ManageComments/SellerManageComment') },
      { path: '/specialPriceMobile', name: 'specialPriceMobile', component: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "sellers-chunk" */ '@/components/Shop/Partner/SpecialPrice') },
      { path: '/partnerSellerMobile', name: 'partnerSellerMobile', component: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "sellers-chunk" */ '@/components/Shop/Partner/PartnerShop') },
      { path: '/dashboardAdminMobile', name: 'dashboardAdminMobile', component: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "adminPanit-chunk" */ '@/views/DashboardAdminUI') },
      { path: '/dashboardAdminForGPMobile', name: 'dashboardAdminForGPMobile', component: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "adminPanit-chunk" */ '@/views/MobileUI/DashboardForGPUI') },
      { path: '/listShopPositionMobile', name: 'listShopPositionMobile', component: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "sellers-chunk" */ '@/components/Shop/SetPermission/Position/listTablePosition') },
      { path: '/listShopUserMobile', name: 'listShopUserMobile', component: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "sellers-chunk" */ '@/components/Shop/SetPermission/User/listTableUser') },
      { path: '/AdminPanitMobile', name: 'AdminPanitMobile', component: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "adminPanit-chunk" */ '@/views/AdminPanit/HomeMobile') },
      { path: '/ListRequestNewTermMobile', name: 'ListRequestNewTermMobile', component: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "sellers-chunk" */ '@/components/Shop/CreditTerm/ListRequestNewTerm') },
      { path: '/TransactionMobile', name: 'TransactionMobile', component: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "adminPanit-chunk" */ '@/views/AdminPanit/Transaction') },
      { path: '/adminPanitManageMobile', name: 'adminPanitManageMobile', component: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "company-chunk" */ '@/components/AdminPanit/AdminManage/AdminPanitManage') },
      { path: '/AllShopTopTenMobile', name: 'AllShopTopTenMobile', component: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "adminPanit-chunk" */ '@/components/AdminPanit/Transaction/AllPageShopTopTen') },
      { path: '/companyMobile', name: 'companyMobile', component: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "company-chunk" */ '../views/Company/HomeMobile.vue') },
      { path: '/detailCompanyMobile', name: 'detailCompanyMobile', component: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "company-chunk" */ '@/components/AdminManage/Company/DetailCompany') },
      { path: '/ManagePositionMobile', name: 'ManagePositionMobile', component: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "company-chunk" */ '@/components/AdminManage/BuyerManage/ManagePositionCompany') },
      { path: '/ManageCompanyPostionUserMobile', name: 'ManageCompanyPostionUserMobile', component: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "company-chunk" */ '@/components/AdminManage/BuyerManage/ManagePositionUserCompany') },
      { path: '/PartnerMobile', name: 'PartnerMobile', component: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "sellers-chunk" */ '@/components/Partner/Partner_shop') },
      { path: '/ReportMobile', name: 'ReportMobile', component: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "sellers-chunk" */ '@/views/ReportUI') },
      { path: '/CompanyCouposAndPointsMobile', name: 'CompanyCouposAndPointsMobile', component: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "company-chunk" */ '@/components/AdminManage/Coupons/CompanyCouposAndPoints') },
      { path: '/orderCompanyMobile', name: 'orderCompanyMobile', component: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "company-chunk" */ '@/components/AdminManage/Order/Order') },
      { path: '/listApprovePositionMobile', name: 'listApprovePositionMobile', component: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "company-chunk" */ '@/components/AdminManage/ManageApprover/ListApprovePosition') },
      { path: '/detailPositionMobile', name: 'detailPositionMobile', component: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "company-chunk" */ '@/components/AdminManage/ManageApprover/DetailPosition') },
      { path: '/ManageBuyerApproveMobile', name: 'ManageBuyerApproveMobile', component: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "company-chunk" */ '@/components/AdminManage/ManageBuyerApprove/ManageBuyer') },
      { path: '/listApproveOrderMobile', name: 'listApproveOrderMobile', component: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "company-chunk" */ '@/components/AdminManage/ManageApproveOrder/ListApproveOrder') },
      { path: '/DetailApproveOrderMobile', name: 'DetailApproveOrderMobile', component: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "company-chunk" */ '@/components/AdminManage/ManageApproveOrder/DetailApproveOrder') },
      { path: '/listApproveMobile', name: 'ListApproveMobile', component: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "company-chunk" */ '@/components/AdminManage/ManageApproveOrder/ListApprove') },
      { path: '/DetailListApproveMobile', name: 'DetailListApproveMobile', component: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "company-chunk" */ '@/components/AdminManage/ManageApproveOrder/DetailApprove') },
      { path: '/specialPriceBuyerRequestMobile', name: 'specialPriceBuyerRequestMobile', component: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "company-chunk" */ '@/components/AdminManage/SpecialPrice/SpecialPriceBuyerRequest') },
      { path: '/companyListCreditOrderMobile', name: 'listCreaditOrderMobile', component: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "company-chunk" */ '@/components/AdminManage/CreditTerm/ListCreditOrder') },
      { path: '/companyListCreditTermMobile', name: 'listCreaditTermMobile', component: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "company-chunk" */ '@/components/AdminManage/CreditTerm/ListCreditTerm') },
      { path: '/invoicePDFMobile', name: 'invoicePDFMobile', component: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "company-chunk" */ '@/components/AdminManage/CreditTerm/InvoicePDF') },
      { path: '/QUCompanyMobile', name: 'QUCompanyMobile', component: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "company-chunk" */ '@/components/AdminManage/ManagePO/ListManagePO') },
      { path: '/TackingCompanyMobile', name: 'TackingCompanyMobile', component: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "company-chunk" */ '@/components/AdminManage/Tacking/TackingCompany') },
      { path: '/refundCompanyMobile', name: 'refundCompanyMobile', component: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "company-chunk" */ '@/components/AdminManage/RefundCompany/RefundProductCompany') },
      { path: '/reviewCompanyMobile', name: 'reviewCompanyMobile', component: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "company-chunk" */ '@/components/AdminManage/Review/ReviewPageCompany') },
      { path: '/orderDetailCompanyMobile', name: 'orderDetailCompanyMobile', component: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "company-chunk" */ '@/components/AdminManage/Order/OrderDetail') },
      { path: '/refundDetailCompanyMobile', name: 'refundDetailCompanyMobile', component: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "company-chunk" */ '@/components/AdminManage/RefundCompany/RefundProductDetailCompany') },
      { path: '/manageCompanyMobile', name: 'manageCompanyMobile', component: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "company-chunk" */ '@/components/AdminManage/Company/CreateCompany') },
      { path: '/QUCompanyDetailMobile', name: 'QUCompanyDetailMobile', component: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "company-chunk" */ '@/components/AdminManage/ManagePO/DetailPOCompany') },
      { path: '/EditShopMobile', name: 'EditShopMobile', component: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "sellers-chunk" */ '@/components/Shop/EditShop') },
      { path: '/EditPicturesShopMobile', name: 'EditPicturesShopMobile', component: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "sellers-chunk" */ '@/components/Shop/EditPictures') },
      {
        path: '/sellers',
        component: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "sellers-chunk" */ '../views/SellerShopUI'),
        children: [
          { path: '/sellers', name: 'sellerUIs', component: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "sellers-chunk" */ '@/components/Shop/FirstTimeSeller') },
          { path: '/seller', name: 'sellerUI', component: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "sellers-chunk" */ '@/components/Shop/ShopProduct/ShopProductUI') },
          { path: '/manageproduct', name: 'manageproductUI', component: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "sellers-chunk" */ '@/views/CreateProductUI') },
          { path: '/shop', name: 'shopUI', component: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "sellers-chunk" */ '@/components/Shop/CreateShop') },
          { path: '/poseller', name: 'posellerUI', component: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "sellers-chunk" */ '@/views/POSellerUI') },
          { path: '/Curier', name: 'Curier', component: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "sellers-chunk" */ '@/components/Curier/Curier') },
          { path: '/POSellerDetail', name: 'POSellerDetailUI', component: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "sellers-chunk" */ '@/components/Quotation/POSellerDetailUI') },
          { path: '/orderdetailseller', name: 'orderdetailseller', component: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "sellers-chunk" */ '@/views/OrderDetailSeller') },
          { path: '/inventory', name: 'inventoryUI', component: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "sellers-chunk" */ '@/views/InventoryUI') },
          // { path: '/Partner', name: 'Partner', component: () => import('@/components/Partner/Partner_shop') },
          // { path: '/designShop', name: 'designShopUI', component: () => import('@/views/DesignShopUI') },
          { path: '/QuotationAll', name: 'QuotationAll', component: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "sellers-chunk" */ '@/components/Quotation/QuotationSeller/ListQuotationSeller') },
          { path: '/QuotationDetail', name: 'QuotationDetail', component: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "sellers-chunk" */ '@/components/Quotation/QuotationSeller/DetailQuotationSeller') },
          { path: '/designShop', name: 'designShopUI', component: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "sellers-chunk" */ '@/components/Shop/SettingShop') },
          { path: '/EditShop', name: 'EditShop', component: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "sellers-chunk" */ '@/components/Shop/EditShop') },
          { path: '/EditPicturesShop', name: 'EditPicturesShop', component: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "sellers-chunk" */ '@/components/Shop/EditPictures') },
          // { path: '/dashboard', name: 'dashboardUI', component: () => import('@/views/DashBoardUI') },
          { path: '/dashboard', name: 'dashboardUI', component: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "sellers-chunk" */ '@/views/NewDashboard') },
          { path: '/return', name: 'returnUI', component: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "sellers-chunk" */ '@/views/ReturnUI') },
          { path: '/returnDetail', name: 'returndetailUI', component: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "sellers-chunk" */ '@/components/return/returnDetail') },
          { path: '/DownloadFiles', name: 'DownloadFiles', component: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "sellers-chunk" */ '@/components/Shop/DownloadFile') },
          { path: '/Tackingorder', name: 'Tackingorder', component: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "sellers-chunk" */ '@/components/Shop/Tackingorder') },
          { path: '/partnerSeller', name: 'partnerSeller', component: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "sellers-chunk" */ '@/components/Shop/Partner/PartnerShop') },
          { path: '/revenue', name: 'RevenueUI', component: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "sellers-chunk" */ '@/views/RevenueUI') },
          { path: '/createShop', name: 'createShop', component: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "sellers-chunk" */ '@/views/CreateShop') },
          { path: '/stepCreateShop', name: 'stepCreateShop', component: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "sellers-chunk" */ '@/components/Shop/CreateShop/stepCreateShop') },
          { path: '/manageComments', name: 'manageComments', component: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "sellers-chunk" */ '@/components/Shop/ManageComments/SellerManageComment') },
          { path: '/listShopPosition', name: 'listShopPosition', component: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "sellers-chunk" */ '@/components/Shop/SetPermission/Position/listTablePosition') },
          { path: '/listShopUser', name: 'listShopUser', component: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "sellers-chunk" */ '@/components/Shop/SetPermission/User/listTableUser') },
          { path: '/ReviewSeller', name: 'ReviewSeller', component: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "sellers-chunk" */ '@/views/ReviewSellerUI') },
          { path: '/SettingPartnerRequest', name: 'SettingPartnerRequest', component: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "sellers-chunk" */ '@/views/SettingPartnerRequest') },
          { path: '/SettingTier', name: 'SettingTier', component: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "sellers-chunk" */ '@/views/SettingTier') },
          { path: '/QuotationSettingSeller', name: 'QuotationSettingSeller', component: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "sellers-chunk" */ '@/views/QuotationSettingSeller') },
          { path: '/specialPrice', name: 'specialPrice', component: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "sellers-chunk" */ '@/components/Shop/Partner/SpecialPrice') },
          { path: '/sellerlistCreditOrder', name: 'sellerlistCreditOrder', component: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "sellers-chunk" */ '@/components/Shop/CreditTerm/ListCreditOrder') },
          { path: '/sellerlistCreditTerm', name: 'creditTerm', component: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "sellers-chunk" */ '@/components/Shop/CreditTerm/ListCreditTerm') },
          { path: '/sellerInvoicePDF', name: 'sellerInvoicePDF', component: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "sellers-chunk" */ '@/components/Shop/CreditTerm/InvoicePDF') },
          { path: '/ListRequestNewTerm', name: 'ListRequestNewTerm', component: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "sellers-chunk" */ '@/components/Shop/CreditTerm/ListRequestNewTerm') },
          // { path: '/sellerlistCreditOrder', name: 'sellerlistCreditOrder', component: () => import('@/components/Shop/CreditTerm/ListCreditOrder') },
          // { path: '/billingDetail', name: 'listCreaditOrder', component: () => import('@/components/Shop/CreditTerm/invoiceDetail') },
          // { path: '/companylistCreditOrder', name: 'listCreaditOrder', component: () => import('@/components/Shop/CreditTermComp/ListCreditOrder') },
          // { path: '/sellerlistRequestCreditTerm', name: 'creditTerm', component: () => import('@/components/Shop/CreditTerm/ListRequestNewCreditTerm') },
          { path: '/EtaxCredentail', name: 'EtaxCredentail', component: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "sellers-chunk" */ '@/components/Shop/EtaxCredential') },
          { path: '/MerchantShop', name: 'MerchantShop', component: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "sellers-chunk" */ '@/components/Shop/Merchant/Merchant') },
          { path: '/ManagaCupon', mname: 'ManagaCupon', component: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "sellers-chunk" */ '@/components/Shop/Merchant/ManageCupon') }
        ]
      },
      { path: '/createbusinesssid', name: 'createbusinesssidui', component: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "businesss-chunk" */ '@/views/Business/CreateBusinessIdUI') },
      { path: '/detailbusinesssid', name: 'detailbusinesssidui', component: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "businesss-chunk" */ '@/views/Business/DetailBusinessIdUI') },
      { path: '/shoppingcart', name: 'shoppingcartui', component: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "checkout-chunk" */ '@/views/Cart/ShoppingCartUI') },
      { path: '/checkout', name: 'checkoutui', component: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "checkout-chunk" */ '@/views/Cart/CheckoutUI') },
      { path: '/pobuyer', name: 'pobuyer', component: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "userprofile-chunk" */ '@/views/POBuyerUI') },
      {
        path: '/approved',
        component: () => import('@/views/Approved'),
        children: [
          { path: '/approved', name: 'approved', component: () => import('@/components/Approved/Approved') },
          { path: '/approveDetail', name: 'approveDetail', component: () => import('@/components/Approved/ApproveDetail') }
        ]
      },
      {
        path: '/Company',
        component: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "company-chunk" */ '@/views/Company/Home'),
        children: [
          { path: '/Company', name: 'Company', component: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "company-chunk" */ '@/components/AdminManage/Company/Company') },
          { path: '/manageCompany', name: 'manageCompany', component: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "company-chunk" */ '@/components/AdminManage/Company/CreateCompany') },
          { path: '/detailCompany', name: 'detailCompany', component: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "company-chunk" */ '@/components/AdminManage/Company/DetailCompany') },
          { path: '/QUCompany', name: 'QUCompany', component: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "company-chunk" */ '@/components/AdminManage/ManagePO/ListManagePO') },
          { path: '/QUCompanyDetail', name: 'QUCompanyDetail', component: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "company-chunk" */ '@/components/AdminManage/ManagePO/DetailPOCompany') },
          { path: '/Partner', name: 'Partner', component: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "company-chunk" */ '@/components/Partner/Partner_shop') },
          { path: '/TackingCompany', name: 'TackingCompany', component: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "company-chunk" */ '@/components/AdminManage/Tacking/TackingCompany') },
          { path: '/ManagePosition', name: 'ManagePosition', component: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "company-chunk" */ '@/components/AdminManage/BuyerManage/ManagePositionCompany') },
          { path: '/ManageCompanyPostionUser', name: 'ManageCompanyPostionUser', component: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "company-chunk" */ '@/components/AdminManage/BuyerManage/ManagePositionUserCompany') },
          // { path: '/ManageUserInCompany', name: 'ManageUserInCompany', component: () => import('@/components/AdminManage/BuyerManage/ManegeSubCompanyUser') },
          { path: '/orderCompany', name: 'orderCompany', component: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "company-chunk" */ '@/components/AdminManage/Order/Order') },
          { path: '/orderDetailCompany', name: 'orderDetailCompany', component: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "company-chunk" */ '@/components/AdminManage/Order/OrderDetail') },
          { path: '/reviewCompany', name: 'reviewCompany', component: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "company-chunk" */ '@/components/AdminManage/Review/ReviewPageCompany') },
          { path: '/refundCompany', name: 'refundCompany', component: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "company-chunk" */ '@/components/AdminManage/RefundCompany/RefundProductCompany') },
          { path: '/refundDetailCompany', name: 'refundDetailCompany', component: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "company-chunk" */ '@/components/AdminManage/RefundCompany/RefundProductDetailCompany') },
          { path: '/Report', name: 'Report', component: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "company-chunk" */ '@/views/ReportUI') },
          { path: '/CompanyCouposAndPoints', name: 'CompanyCouposAndPoints', component: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "company-chunk" */ '@/components/AdminManage/Coupons/CompanyCouposAndPoints') },
          { path: '/specialPriceBuyerRequest', name: 'specialPriceBuyerRequest', component: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "company-chunk" */ '@/components/AdminManage/SpecialPrice/SpecialPriceBuyerRequest') },
          { path: '/companyListCreditOrder', name: 'listCreaditOrder', component: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "company-chunk" */ '@/components/AdminManage/CreditTerm/ListCreditOrder') },
          { path: '/companyListCreditTerm', name: 'listCreaditTerm', component: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "company-chunk" */ '@/components/AdminManage/CreditTerm/ListCreditTerm') },
          { path: '/invoicePDF', name: 'invoicePDF', component: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "company-chunk" */ '@/components/AdminManage/CreditTerm/InvoicePDF') },
          { path: '/ManageBuyerApprove', name: 'ManageBuyerApprove', component: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "company-chunk" */ '@/components/AdminManage/ManageBuyerApprove/ManageBuyer') },
          { path: '/listApprovePosition', name: 'listApprovePosition', component: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "company-chunk" */ '@/components/AdminManage/ManageApprover/ListApprovePosition') },
          { path: '/detailPosition', name: 'detailPosition', component: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "company-chunk" */ '@/components/AdminManage/ManageApprover/DetailPosition') },
          { path: '/listApproveOrder', name: 'listApproveOrder', component: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "company-chunk" */ '@/components/AdminManage/ManageApproveOrder/ListApproveOrder') },
          { path: '/DetailApproveOrder', name: 'DetailApproveOrder', component: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "company-chunk" */ '@/components/AdminManage/ManageApproveOrder/DetailApproveOrder') },
          { path: '/listApprove', name: 'ListApprove', component: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "company-chunk" */ '@/components/AdminManage/ManageApproveOrder/ListApprove') },
          { path: '/DetailListApprove', name: 'DetailListApprove', component: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "company-chunk" */ '@/components/AdminManage/ManageApproveOrder/DetailApprove') }
          // { path: '/Quotation', name: 'QuotationCompany', component: () => import('@/components/PurchaseOrder/PurchaseOrder') }
        ]
      },
      {
        path: '/AdminPanit',
        component: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "adminPanit-chunk" */ '@/views/AdminPanit/Home'),
        children: [
          { path: '/dashboardAdmin', name: 'dashboardAdmin', component: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "adminPanit-chunk" */ '@/views/DashboardAdminUI') },
          { path: '/Transaction', name: 'Transaction', component: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "adminPanit-chunk" */ '@/views/AdminPanit/Transaction') },
          { path: '/AllShopTopTen', name: 'AllShopTopTen', component: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "adminPanit-chunk" */ '@/components/AdminPanit/Transaction/AllPageShopTopTen') },
          { path: '/dashboardAdminForGP', name: 'dashboardAdminForGP', component: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "adminPanit-chunk" */ '@/views/DashboardForGPUI') },
          { path: '/adminPanitManage', name: 'adminPanitManage', component: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "adminPanit-chunk" */ '@/components/AdminPanit/AdminManage/AdminPanitManage') },
          { path: '/manageGracz', name: 'manageGracz', component: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "adminPanit-chunk" */ '@/components/AdminPanit/AdminManage/ManageGracz') }
        ]
      },
      {
        path: '/Order/UserProfileDetail',
        component: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "Gracz-chunk" */ '@/views/Gracz/Home'),
        children: [
          { path: '/Order/UserProfileDetail', name: 'GraczUserProfileDetail', component: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "Gracz-chunk" */ '@/components/Gracz/UserProfile') },
          { path: '/Order/SalesOrder', name: 'GraczSalesOrder', component: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "Gracz-chunk" */ '@/components/Gracz/Sales/Order/OrderList') },
          { path: '/Order/SalesOrderDetail', name: 'GraczSalesOrderDetail', component: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "Gracz-chunk" */ '@/components/Gracz/Sales/Order/OrderDetail') },
          { path: '/Order/SalesCustomerList', name: 'GraczSalesCustomerList', component: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "Gracz-chunk" */ '@/components/Gracz/Sales/Customer/SalesCustomerList') },
          { path: '/Order/SalesCustomerDetail', name: 'GraczSalesCustomerDetail', component: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "Gracz-chunk" */ '@/components/Gracz/Sales/Customer/SalesCustomerDetail') },
          { path: '/Order/SellerShopDetail/:data', name: 'GraczSellerShopDetail', component: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "Gracz-chunk" */ '@/components/Gracz/Admin/Shop/SellerShop') },
          { path: '/Order/ListProductShop/:data', name: 'GraczListProductShop', component: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "Gracz-chunk" */ '@/components/Gracz/Admin/Shop/ListProductShop') },
          { path: '/Order/EditSellerShop', name: 'GraczEditEditSellerShop', component: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "Gracz-chunk" */ '@/components/Gracz/Admin/Shop/EditShop') },
          { path: '/Order/EditPictureShop', name: 'GraczEditPictureShop', component: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "Gracz-chunk" */ '@/components/Gracz/Admin/Shop/EditPicture') },
          { path: '/Order/ProductList', name: 'GraczProductList', component: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "Gracz-chunk" */ '@/components/Gracz/Admin/Product/Product') },
          { path: '/Order/ProductListSaleOrder', name: 'GraczProductListSaleOrder', component: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "Gracz-chunk" */ '@/components/Gracz/Admin/Product/ProductSaleOrder') },
          { path: '/Order/ImportProduct', name: 'GraczImportProduct', component: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "Gracz-chunk" */ '@/components/Gracz/Admin/Product/PreviewImportProduct') },
          { path: '/Order/ImportPanitProduct', name: 'GraczImportPanitProduct', component: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "Gracz-chunk" */ '@/components/Gracz/Admin/Product/ImportPanitProduct') },
          { path: '/Order/ManageProduct', name: 'GraczManageProduct', component: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "Gracz-chunk" */ '@/components/Gracz/Admin/Product/ManageProduct') },
          { path: '/Order/ManageProductSaleOrder', name: 'GraczManageProductSaleOrder', component: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "Gracz-chunk" */ '@/components/Gracz/Admin/Product/ManageProductSaleOrder') },
          { path: '/Order/OrderList', name: 'GraczOrderList', component: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "Gracz-chunk" */ '@/components/Gracz/Admin/Order/OrderList') },
          { path: '/Order/OrderDetail', name: 'GraczOrderDetail', component: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "Gracz-chunk" */ '@/components/Gracz/Admin/Order/OrderDetail') },
          { path: '/Order/DeletedOrderList', name: 'GraczDeletedOrderList', component: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "Gracz-chunk" */ '@/components/Gracz/Admin/Order/DeletedOrderList') },
          { path: '/Order/DeletedOrderDetail', name: 'GraczDeletedOrderDetail', component: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "Gracz-chunk" */ '@/components/Gracz/Admin/Order/DeletedOrderDetail') },
          { path: '/Order/ManageGroupCustomer', name: 'GraczManageGroupCustomer', component: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "Gracz-chunk" */ '@/components/Gracz/Admin/Customer/ManageGroupCustomer') },
          { path: '/Order/CustomerList', name: 'GraczCustomerList', component: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "Gracz-chunk" */ '@/components/Gracz/Admin/Customer/CustomerList') },
          { path: '/Order/CustomerDetail', name: 'GraczCustomerDetail', component: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "Gracz-chunk" */ '@/components/Gracz/Admin/Customer/CustomerDetail') },
          { path: '/Order/ImportCustomerData', name: 'GraczImportCustomerData', component: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "Gracz-chunk" */ '@/components/Gracz/Admin/Customer/ImportCustomerData') },
          { path: '/Order/Category', name: 'GraczCategory', component: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "Gracz-chunk" */ '@/components/Gracz/Admin/Category/Category') },
          { path: '/Order/Promotion', name: 'GraczPromotion', component: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "Gracz-chunk" */ '@/components/Gracz/Admin/Promotion/Promotion') },
          { path: '/Order/ManagePromotion', name: 'GraczManagePromotion', component: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "Gracz-chunk" */ '@/components/Gracz/Admin/Promotion/ManagePromotion') },
          { path: '/Order/ManageUser', name: 'GraczManageUser', component: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "Gracz-chunk" */ '@/components/Gracz/Admin/User/ManageUser') },
          { path: '/Order/AdminDashbord', name: 'AdminGraczDashbord', component: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "Gracz-chunk" */ '@/components/Gracz/Admin/Dashbord/Dashbord') },
          { path: '/Order/SalesDashbord', name: 'SalesGraczDashbord', component: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "Gracz-chunk" */ '@/components/Gracz/Sales/Dashbord/Dashbord') },
          { path: '/Order/EtaxCredential', name: 'GraczEtaxCredential', component: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "Gracz-chunk" */ '@/components/Gracz/Admin/Etax/EtaxCredential') },
          { path: '/Order/ManageDocumentEtax', name: 'GraczManageDocumentEtax', component: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "Gracz-chunk" */ '@/components/Gracz/Admin/Etax/ManageDocumentEtax') },
          { path: '/Order/CreditNote', name: 'SaleOrderCreditNote', component: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "Gracz-chunk" */ '@/components/Gracz/Admin/CreditNote/CreditNote') }
        ]
      }
    ]
  },
  // {
  //   path: '/adminManage',
  //   component: () => import('@/views/AdminManage/Home'),
  //   children: [
  //     { path: '/adminManage', name: 'admimManage', component: () => import('@/components/AdminManage/Company/Company') },
  //     { path: '/manageCompany', name: 'manageCompany', component: () => import('@/components/AdminManage/Company/CreateCompany') },
  //     { path: '/detailCompany', name: 'detailCompany', component: () => import('@/components/AdminManage/Company/DetailCompany') },
  //     { path: '/usersCompany', name: 'usersCompany', component: () => import('@/components/AdminManage/User/Users') },
  //     { path: '/departmentsCompany', name: 'departmentsCompany', component: () => import('@/components/AdminManage/Department/Departments') },
  //     { path: '/createDepartment', name: 'CreateDepartment', component: () => import('@/components/AdminManage/Department/CreateDepartment') },
  //     { path: '/editDepartment?:id', name: 'EditDepartment', component: () => import('@/components/AdminManage/Department/EditDepartment') },
  //     { path: '/departmentsCompany/detaildepartmentsCompany?:id', name: 'detaildepartmentsCompany', component: () => import('@/components/AdminManage/Department/DetailDepartment') },
  //     { path: '/manageUserCompany', name: 'manageUserCompany', component: () => import('@/components/AdminManage/User/ManageUser') },
  //     { path: '/detailUserCompany', name: 'detailUserCompany', component: () => import('@/components/AdminManage/User/DetailUser') },
  //     { path: '/settingUserCompany', name: 'settingUserCompany', component: () => import('@/components/AdminManage/User/SettingUser') },
  //     { path: '/partnerCompany', name: 'partnerCompany', component: () => import('@/components/AdminManage/Partner/Partner') },
  //     { path: '/partnerDetail', name: 'partnerDetail', component: () => import('@/components/AdminManage/Partner/PartnerDetail') },
  //     { path: '/orderCompany', name: 'orderCompany', component: () => import('@/components/AdminManage/Order/Order') },
  //     { path: '/orderDetailCompany', name: 'orderDetailCompany', component: () => import('@/components/AdminManage/Order/OrderDetail') }
  //   ]
  // },
  {
    path: '/regisMorhpromt',
    component: () => import('@/views/RegisterMorhpromt/register'),
    children: [
      { path: '/regisMorhpromt', name: 'regisMorhpromt', component: () => import('@/components/RegisterMorhpromt/register') },
      { path: '/otp', name: 'otp', component: () => import('@/components/RegisterMorhpromt/OTP') },
      { path: '/findTax', name: 'findTax', component: () => import('@/components/RegisterMorhpromt/FindTaxID') },
      { path: '/Registerbuyer', name: 'Registerbuyer', component: () => import('@/components/PopOver/Registerbuyer') },
      { path: '/ListRegister', name: 'ListRegister', component: () => import('@/components/RegisterMorhpromt/ListRegister') }
    ]
  },
  {
    path: '/adminTest',
    component: () => import('@/views/AdminTest/Home'),
    children: [
      { path: '/adminManage', name: 'adminmanage', component: () => import('@/components/AdminTest/adminManage') }
    ]
  },
  { path: '*', component: () => import('@/views/404') }
  // {
  //   path: '/UPS',
  //   component: () => import('@/views/UPS/Home')
  //   // children: [
  //   //   { path: '/UPS', name: 'Home', component: () => import('@/views/HomeProductUI') },
  //   // ]
  // }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

Vue.use(VueGtm, {
  id: 'GTM-KG3476F', // Your GTM single container ID, array of container ids ['GTM-xxxxxx', 'GTM-yyyyyy'] or array of objects [{id: 'GTM-xxxxxx', queryParams: { gtm_auth: 'abc123', gtm_preview: 'env-4', gtm_cookies_win: 'x'}}, {id: 'GTM-yyyyyy', queryParams: {gtm_auth: 'abc234', gtm_preview: 'env-5', gtm_cookies_win: 'x'}}], // Your GTM single container ID or array of container ids ['GTM-xxxxxx', 'GTM-yyyyyy']
  queryParams: {
    // Add URL query string when loading gtm.js with GTM ID (required when using custom environments)
    gtm_auth: 'ZwqHb0tQWN5GKqcWv7pXsg',
    gtm_preview: 'env-1',
    gtm_cookies_win: 'x'
  },
  defer: false, // Script can be set to `defer` to speed up page load at the cost of less accurate results (in case visitor leaves before script is loaded, which is unlikely but possible). Defaults to false, so the script is loaded `async` by default
  compatibility: false, // Will add `async` and `defer` to the script tag to not block requests for old browsers that do not support `async`
  nonce: '2726c7f26c', // Will add `nonce` to the script tag
  enabled: true, // defaults to true. Plugin can be disabled by setting this to false for Ex: enabled: !!GDPR_Cookie (optional)
  debug: true, // Whether or not display console logs debugs (optional)
  loadScript: true, // Whether or not to load the GTM Script (Helpful if you are including GTM manually, but need the dataLayer functionality in your components) (optional)
  vueRouter: router, // Pass the router instance to automatically sync with router (optional)
  ignoredViews: ['homepage'], // Don't trigger events for specified router names (case insensitive) (optional)
  trackOnNextTick: false // Whether or not call trackView in Vue.nextTick
})

export default router
