import AxiosAdminPanit from '../store_admin_panit/axios_admin_panit'

const ModuleAdminPanit = {
  state: {
    // Transaction
    stateTransaction: [],
    // top10sellers
    stateTop10Sellers: [],
    // top10buyers
    stateTop10Buyer: [],
    // top10purchasers
    stateTop10Purchaser: [],
    // List Order Approve
    stateListOrderApprove: [],
    // Iframe Order Approve
    stateIframeOrderApprove: [],
    // Detail Order Approve
    stateDetailOrderApprove: [],
    // List Purchaser Approve
    stateListPurchaserApproveOrder: [],
    stateListGSellerShop: [],
    stateListSelectNewGSellerShop: [],
    stateAddGAdminSellerShop: [],
    stateDeleteGSellerShop: []
  },
  mutations: {
    mutationsTransaction (state, data) {
      state.stateTransaction = data
    },
    mutationsTop10Sellers (state, data) {
      state.stateTop10Sellers = data
    },
    mutationsTop10Buyer (state, data) {
      state.stateTop10Buyer = data
    },
    mutationsTop10Purchaser (state, data) {
      state.stateTop10Purchaser = data
    },
    mutationsListOrderApprove (state, data) {
      state.stateListOrderApprove = data
    },
    mutationsIframeOrderApprove (state, data) {
      state.stateIframeOrderApprove = data
    },
    mutationsDetailOrderApprove (state, data) {
      state.stateDetailOrderApprove = data
    },
    mutationsListPurchaserApproveOrder (state, data) {
      state.stateListPurchaserApproveOrder = data
    },
    mutationsListGSellerShop (state, data) {
      state.stateListGSellerShop = data
    },
    mutationsListSelectNewGSellerShop (state, data) {
      state.stateListSelectNewGSellerShop = data
    },
    mutationsAddGAdminSellerShop (state, data) {
      state.stateAddGAdminSellerShop = data
    },
    mutationsDeleteGSellerShop (state, data) {
      state.stateDeleteGSellerShop = data
    }
  },
  actions: {
    async actionsTransaction (context, access) {
      var response = await AxiosAdminPanit.transaction(access)
      await context.commit('mutationsTransaction', response)
    },
    async actionsTop10Sellers (context, access) {
      var response = await AxiosAdminPanit.Top10Sellers(access)
      await context.commit('mutationsTop10Sellers', response)
    },
    async actionsTop10Buyer (context, access) {
      var response = await AxiosAdminPanit.Top10Buyer(access)
      await context.commit('mutationsTop10Buyer', response)
    },
    async actionsTop10Purchaser (context, access) {
      var response = await AxiosAdminPanit.Top10Purchaser(access)
      await context.commit('mutationsTop10Purchaser', response)
    },
    async actionsListOrderApprove (context, access) {
      var response = await AxiosAdminPanit.ListOrderApprove(access)
      await context.commit('mutationsListOrderApprove', response)
    },
    async actionsIframeOrderApprove (context, access) {
      var response = await AxiosAdminPanit.IframeOrderApprove(access)
      await context.commit('mutationsIframeOrderApprove', response)
    },
    async actionsDetailOrderApprove (context, access) {
      var response = await AxiosAdminPanit.DetailOrderApprove(access)
      await context.commit('mutationsDetailOrderApprove', response)
    },
    async actionsListPurchaserApproveOrder (context, access) {
      var response = await AxiosAdminPanit.ListPurchaserApproveOrder(access)
      await context.commit('mutationsListPurchaserApproveOrder', response)
    },
    async actionsListGSellerShop (context) {
      var response = await AxiosAdminPanit.ListGSellerShop()
      await context.commit('mutationsListGSellerShop', response)
    },
    async actionsListSelectNewGSellerShop (context) {
      var response = await AxiosAdminPanit.ListSelectNewGSellerShop()
      await context.commit('mutationsListSelectNewGSellerShop', response)
    },
    async actionsAddGAdminSellerShop (context, access) {
      var response = await AxiosAdminPanit.AddGAdminSellerShop(access)
      await context.commit('mutationsAddGAdminSellerShop', response)
    },
    async actionsDeleteGSellerShop (context, access) {
      var response = await AxiosAdminPanit.DeleteGSellerShop(access)
      await context.commit('mutationsDeleteGSellerShop', response)
    }
  }
}

export default ModuleAdminPanit
