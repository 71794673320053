import AxiosGraczCart from './axios_gracz_cart_api'

const ModuleGraczCart = {
  state: {
    stateGraczAddToCart: [],
    stateGraczGetDetailCart: [],
    stateGraczUpdateCart: [],
    stateGraczDeleteSelect: [],
    stateGraczGetCartCheckout: [],
    stateGraczCreateOrder: [],
    stateGraczGetListPromotionCart: [],
    stateGraczFindProductPromotionCart: [],
    stateGraczSearchPromotionCart: [],
    stateGraczCheckProductPromotionCart: [],
    stateSaleOrderGetPaymentPage: []
  },
  mutations: {
    mutationsAddToCart (state, data) {
      state.stateGraczAddToCart = data
    },
    mutationsGetDetailCart (state, data) {
      state.stateGraczGetDetailCart = data
    },
    mutationsGraczUpdateCart (state, data) {
      state.stateGraczUpdateCart = data
    },
    mutationsGraczDeleteSelectItem (state, data) {
      state.stateGraczDeleteSelectItem = data
    },
    mutationsGraczGetCartCheckout (state, data) {
      state.stateGraczGetCartCheckout = data
    },
    mutationsGraczCreateOrder (state, data) {
      state.stateGraczCreateOrder = data
    },
    mutationsGraczGetListPromotionCart (state, data) {
      state.stateGraczGetListPromotionCart = data
    },
    mutationsGraczFindProductPromotionCart (state, data) {
      state.stateGraczFindProductPromotionCart = data
    },
    mutationsGraczSearchPromotionCart (state, data) {
      state.stateGraczSearchPromotionCart = data
    },
    mutationsGraczCheckProductPromotionCart (state, data) {
      state.stateGraczCheckProductPromotionCart = data
    },
    mutationsSaleOrderGetPaymentPage (state, data) {
      state.stateSaleOrderGetPaymentPage = data
    }
  },
  actions: {
    async actionsGraczAddToCart (context, access) {
      var response = await AxiosGraczCart.GraczAddToCart(access)
      await context.commit('mutationsAddToCart', response)
    },
    async actionsGraczGetDetailCart (context, access) {
      var response = await AxiosGraczCart.GraczGetDetailCart(access)
      await context.commit('mutationsGetDetailCart', response)
    },
    async actionsGraczUpdateCart (context, access) {
      var response = await AxiosGraczCart.GraczUpdateCart(access)
      await context.commit('mutationsGraczUpdateCart', response)
    },
    async actionsGraczDeleteSelectItem (context, access) {
      var response = await AxiosGraczCart.GraczDeleteSelectItem(access)
      await context.commit('mutationsGraczDeleteSelectItem', response)
    },
    async actionsGraczGetCartCheckout (context, access) {
      var response = await AxiosGraczCart.GraczGetCartCheckout(access)
      await context.commit('mutationsGraczGetCartCheckout', response)
    },
    async actionsGraczCreateOrder (context, access) {
      var response = await AxiosGraczCart.GraczCreateOrder(access)
      await context.commit('mutationsGraczCreateOrder', response)
    },
    async actionsGraczGetListPromotionCart (context, access) {
      var response = await AxiosGraczCart.GraczGetListPromotionCart(access)
      await context.commit('mutationsGraczGetListPromotionCart', response)
    },
    async actionsGraczFindProductPromotionCart (context, access) {
      var response = await AxiosGraczCart.GraczFindProductPromotionCart(access)
      await context.commit('mutationsGraczFindProductPromotionCart', response)
    },
    async actionsGraczSearchPromotionCart (context, access) {
      var response = await AxiosGraczCart.GraczSearchPromotionCart(access)
      await context.commit('mutationsGraczSearchPromotionCart', response)
    },
    async actionsGraczCheckProductPromotionCart (context, access) {
      var response = await AxiosGraczCart.GraczCheckProductPromotionCart(access)
      await context.commit('mutationsGraczCheckProductPromotionCart', response)
    },
    async actionsSaleOrderGetPaymentPage (context, access) {
      var response = await AxiosGraczCart.SaleOrderGetPaymentPage(access)
      await context.commit('mutationsSaleOrderGetPaymentPage', response)
    }
  }
}
export default ModuleGraczCart
