import axios from 'axios'
import { Decode } from '@/services'

const GetToken = () => {
  if (localStorage.getItem('oneData') !== null) {
    const oneData = JSON.parse(Decode.decode(localStorage.getItem('oneData')))
    const auth = {
      headers: { Authorization: `Bearer ${oneData.user.access_token}` }
    }
    return auth
  } else {
    const auth = ''
    return auth
  }
}

export default {
  // Product Detail
  async GetProductDetail (data) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}api/product/get_product_detail`, data, auth)
      // console.log(response.data)
      return response.data
    } catch (error) {
      return error.response.data
    }
    // var response
    // const data = {
    //   product_id: val.product_id,
    //   role: val.role
    // }
    // try {
    //   if (localStorage.getItem('oneData') === null) {
    //     response = await axios.post(`${process.env.VUE_APP_BACK_END}api/detail_product`, data)
    //   } else if (localStorage.getItem('oneData') !== null) {
    //     const auth = await GetToken()
    //     response = await axios.post(`${process.env.VUE_APP_BACK_END}api/detail_product`, data, auth)
    //   }
    //   return response.data
    // } catch (error) {
    //   return error.response.data
    // }
  }
}
