import AxiosGraczUser from './axios_gracz_user_api'

const ModuleGraczUser = {
  state: {
    stateGraczUserDetail: []
  },
  mutations: {
    mutationsGraczUserDetail (state, data) {
      state.stateGraczUserDetail = data
    }
  },
  actions: {
    async actionsGraczUserDetail (context, access) {
      var response = await AxiosGraczUser.GraczUserDetail(access)
      await context.commit('mutationsGraczUserDetail', response)
    }
  }
}
export default ModuleGraczUser
