import AxiosFavoriteProduct from './axios_ups_favorite_product_api'

const UPSModuleFavoriteProduct = {
  state: {
    // add_user_favorite_product
    stateAddFavoriteProduct: [],
    // get_user_favorite_product
    stateGetFavoriteProductList: []
  },
  mutations: {
    // add_user_favorite_product
    mutationsUPSAddFavoriteProduct (state, data) {
      state.stateAddFavoriteProduct = data
    },
    // get_user_favorite_product
    mutationsUPSGetFavoriteProductList (state, data) {
      state.stateGetFavoriteProductList = data
    }
  },
  actions: {
    // add_user_favorite_product
    async actionsUPSAddFavoriteProduct (context, access) {
      var response = await AxiosFavoriteProduct.AddFavoriteProduct(access)
      await context.commit('mutationsUPSAddFavoriteProduct', response)
    },
    // get_user_favorite_product
    async actionsUPSGetFavoriteProductList (context, access) {
      var response = await AxiosFavoriteProduct.GetFavoriteProductList(access)
      await context.commit('mutationsUPSGetFavoriteProductList', response)
    }
  }
}
export default UPSModuleFavoriteProduct
