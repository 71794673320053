import axios from 'axios'
import { Decode } from '@/services'

const GetToken = () => {
  if (Object.prototype.hasOwnProperty.call(localStorage, 'oneData')) {
    const oneData = JSON.parse(Decode.decode(localStorage.getItem('oneData')))
    // console.log('ONEDATA NAAAa', oneData)
    const auth = {
      headers: { Authorization: `Bearer ${oneData.user.access_token}` }
    }
    return auth
  }
}

export default {
  // Shop Userdetail Page
  async GetUserDetailPage (data) {
    const auth = await GetToken()
    // console.log('GetUserDetailPage', data)
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}api/user_detail`, data, auth)
      // console.log(response.data)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  // Bind Account and Auto Add OneChat Bot + Create Shop
  async SetBindAccount (data) {
    const auth = await GetToken()
    // console.log('SetBindAccount', data)
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}api/bind_account_with_one_id`, data, auth)
      // console.log(response.data)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async ChangePasswordMarket (data) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}api/reset_password`, data, auth)
      // console.log(response.data)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async axiosListUserAddress () {
    // console.log(val)
    const auth = await GetToken()
    try {
      var response = await axios.get(`${process.env.VUE_APP_BACK_END}api/list_user_address_ups`, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async UpdateUserAddress (val) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}api/update_user_address_v2`, val, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async CreateUserAddress (val) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}api/create_user_address_v2`, val, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async DeleteUserAddress (val) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}api/delete_user_address`, val, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async DefaultUserAddress (val) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}api/set_default_address`, val, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async CreatedAddressTaxinvoice (data) {
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END2}taxInvoices/insert`, data)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async GetAddressTaxinvoice (data) {
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END2}taxInvoices`, data)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async GetAddressTaxinvoicePurchase () {
    const auth = await GetToken()
    try {
      var response = await axios.get(`${process.env.VUE_APP_BACK_END}api/get_corporate_address`, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async axiosUPSListUserAddressTex () {
    // console.log(val)
    const auth = await GetToken()
    try {
      var response = await axios.get(`${process.env.VUE_APP_BACK_END}api/list_invoice_address_ups`, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async UpdateUserProfile (data) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}api/update_user_profile`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async AuthorityUserAccount (data) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}api/user_detail_mp`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async GetInvoice (data) {
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END2}invoice/getInvoice`, data)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async UpsertInvoice (data) {
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END2}invoice/upsert`, data)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async GetBusiness () {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}api/check_business_approved`, '', auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async AddInvoice (data) {
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END2}invoice/create/v2`, data)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async GetInvoiceAddress (data) {
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END2}invoice/getInvoice/v2`, data)
      return response.data
    } catch (error) {
      return error.response.data
    }
  }
}
