import AxiosGraczCustomer from './axios_gracz_customer_api'

const ModuleGraczCustomer = {
  state: {
    stateGraczCustomerSellerList: [],
    stateGraczCustomerDetail: [],
    stateGraczCreateCustomer: [],
    stateGraczEditCustomer: [],
    stateGraczDeleteCustomer: [],
    stateGraczImportCustomerData: [],
    stateGraczExportCustomerForm: [],
    stateGraczGetCustomerBySalesCode: [],
    stateGraczEditSalesOfCustomer: [],
    stateGraczGetListCustomerAddress: [],
    stateGraczDetailCustomerAddress: [],
    stateGraczCreateCustomerAddress: [],
    stateGraczEditCustomerAddress: [],
    stateGraczDeleteCustomerAddress: [],
    stateGraczListCustomerOfSales: [],
    stateGraczDetailCustomerOfSales: [],
    stateGraczListCustomerAddressOfSales: [],
    stateGraczCustomerExportGetLoading: [],
    stateGraczCreateCustomerForSales: [],
    stateGraczGetCustomerAndSalesList: []
    // setFile: ''
  },
  mutations: {
    // mutationsGraczSetFile (state, data) {
    //   state.setFile = data
    // },
    mutationsGraczCustomerSellerList (state, data) {
      state.stateGraczCustomerSellerList = data
    },
    mutationsGraczCustomerDetail (state, data) {
      state.stateGraczCustomerDetail = data
    },
    mutationsGraczCreateCustomer (state, data) {
      state.stateGraczCreateCustomer = data
    },
    mutationsGraczEditCustomer (state, data) {
      state.stateGraczEditCustomer = data
    },
    mutationsGraczDeleteCustomer (state, data) {
      state.stateGraczDeleteCustomer = data
    },
    mutationsGraczImportCustomerData (state, data) {
      state.stateGraczImportCustomerData = data
    },
    mutationsGraczExportCustomerForm (state, data) {
      state.stateGraczExportCustomerForm = data
    },
    mutationsGraczGetCustomerBySalesCode (state, data) {
      state.stateGraczGetCustomerBySalesCode = data
    },
    mutationsGraczEditSalesOfCustomer (state, data) {
      state.stateGraczEditSalesOfCustomer = data
    },
    mutationsGraczGetListCustomerAddress (state, data) {
      state.stateGraczGetListCustomerAddress = data
    },
    mutationsGraczDetailCustomerAddress (state, data) {
      state.stateGraczDetailCustomerAddress = data
    },
    mutationsGraczCreateCustomerAddress (state, data) {
      state.stateGraczCreateCustomerAddress = data
    },
    mutationsGraczEditCustomerAddress (state, data) {
      state.stateGraczEditCustomerAddress = data
    },
    mutationsGraczDeleteCustomerAddress (state, data) {
      state.stateGraczDeleteCustomerAddress = data
    },
    // Sale
    mutationsGraczListCustomerOfSales (state, data) {
      state.stateGraczListCustomerOfSales = data
    },
    mutationsGraczDetailCustomerOfSales (state, data) {
      state.stateGraczDetailCustomerOfSales = data
    },
    mutationsGraczListCustomerAddressOfSales (state, data) {
      state.stateGraczListCustomerAddressOfSales = data
    },
    mutationsGraczCustomerExcelExportGetLoading (state, data) {
      state.stateGraczCustomerExportGetLoading = data
    },
    mutationsGraczCreateCustomerForSales (state, data) {
      state.stateGraczCreateCustomerForSales = data
    },
    mutationsGraczGetCustomerAndSalesList (state, data) {
      state.stateGraczGetCustomerAndSalesList = data
    }
  },
  actions: {
    async actionsGraczSetFile (context, access) {
      // var response = await AxiosGraczCustomer.GraczCustomerSellerList(access)
      await context.commit('mutationsGraczSetFile', access)
    },
    async actionsGraczCustomerSellerList (context, access) {
      var response = await AxiosGraczCustomer.GraczCustomerSellerList(access)
      await context.commit('mutationsGraczCustomerSellerList', response)
    },
    async actionsGraczCustomerDetail (context, access) {
      var response = await AxiosGraczCustomer.GraczCustomerDetail(access)
      await context.commit('mutationsGraczCustomerDetail', response)
    },
    async actionsGraczCreateCustomer (context, access) {
      var response = await AxiosGraczCustomer.GraczCreateCustomer(access)
      await context.commit('mutationsGraczCreateCustomer', response)
    },
    async actionsGraczEditCustomer (context, access) {
      var response = await AxiosGraczCustomer.GraczEditCustomer(access)
      await context.commit('mutationsGraczEditCustomer', response)
    },
    async actionsGraczDeleteCustomer (context, access) {
      var response = await AxiosGraczCustomer.GraczDeleteCustomer(access)
      await context.commit('mutationsGraczDeleteCustomer', response)
    },
    async actionsGraczImportCustomerData (context, access) {
      var response = await AxiosGraczCustomer.GraczImportCustomerData(access)
      await context.commit('mutationsGraczImportCustomerData', response)
    },
    async actionsGraczExportCustomerForm (context, access) {
      var response = await AxiosGraczCustomer.GraczExportCustomerForm(access)
      await context.commit('mutationsGraczExportCustomerForm', response)
    },
    async actionsGraczGetCustomerBySalesCode (context, access) {
      var response = await AxiosGraczCustomer.GraczGetCustomerBySalesCode(access)
      await context.commit('mutationsGraczGetCustomerBySalesCode', response)
    },
    async actionsGraczEditSalesOfCustomer (context, access) {
      var response = await AxiosGraczCustomer.GraczEditSalesOfCustomer(access)
      await context.commit('mutationsGraczEditSalesOfCustomer', response)
    },
    async actionsGraczGetListCustomerAddress (context, access) {
      var response = await AxiosGraczCustomer.GraczGetListCustomerAddress(access)
      await context.commit('mutationsGraczGetListCustomerAddress', response)
    },
    async actionsGraczDetailCustomerAddress (context, access) {
      var response = await AxiosGraczCustomer.GraczDetailCustomerAddress(access)
      await context.commit('mutationsGraczDetailCustomerAddress', response)
    },
    async actionsGraczCreateCustomerAddress (context, access) {
      var response = await AxiosGraczCustomer.GraczCreateCustomerAddress(access)
      await context.commit('mutationsGraczCreateCustomerAddress', response)
    },
    async actionsGraczEditCustomerAddress (context, access) {
      var response = await AxiosGraczCustomer.GraczEditCustomerAddress(access)
      await context.commit('mutationsGraczEditCustomerAddress', response)
    },
    async actionsGraczDeleteCustomerAddress (context, access) {
      var response = await AxiosGraczCustomer.GraczDeleteCustomerAddress(access)
      await context.commit('mutationsGraczDeleteCustomerAddress', response)
    },
    // Sale
    async actionsGraczListCustomerOfSales (context, access) {
      var response = await AxiosGraczCustomer.GraczListCustomerOfSales(access)
      await context.commit('mutationsGraczListCustomerOfSales', response)
    },
    async actionsGraczDetailCustomerOfSales (context, access) {
      var response = await AxiosGraczCustomer.GraczDetailCustomerOfSales(access)
      await context.commit('mutationsGraczDetailCustomerOfSales', response)
    },
    async actionsGraczListCustomerAddressOfSales (context, access) {
      var response = await AxiosGraczCustomer.GraczListCustomerAddressOfSales(access)
      await context.commit('mutationsGraczListCustomerAddressOfSales', response)
    },
    async actionsGraczCustomerExcelExportGetLoading (context, access) {
      var response = await AxiosGraczCustomer.GraczCustomerExcelExportGetLoading(access)
      await context.commit('mutationsGraczCustomerExcelExportGetLoading', response)
    },
    async actionsGraczCreateCustomerForSales (context, access) {
      var response = await AxiosGraczCustomer.GraczCreateCustomerForSales(access)
      await context.commit('mutationsGraczCreateCustomerForSales', response)
    },
    async actionsGraczGetCustomerAndSalesList (context, access) {
      var response = await AxiosGraczCustomer.GraczGetCustomerAndSalesList(access)
      await context.commit('mutationsGraczGetCustomerAndSalesList', response)
    }
  }
  // getters: {
  //   getFileGetter: (state) => {
  //     return state.setFile
  //   }
  // }
}
export default ModuleGraczCustomer
