import AxiosGraczDashbord from './axios_gracz_dashbord_api'

const ModuleGraczDashbord = {
  state: {
    stateGraczDashbord: [],
    stateGraczDashbordTopTen: [],
    stateGraczDashbordSales: [],
    stateGraczDashbordTopTenSales: []
  },
  mutations: {
    mutationsGraczDashbord (state, data) {
      state.stateGraczDashbord = data
    },
    mutationsGraczDashbordTopTen (state, data) {
      state.stateGraczDashbordTopTen = data
    },
    mutationsGraczDashbordSales (state, data) {
      state.stateGraczDashbordSales = data
    },
    mutationsGraczDashbordTopTenSales (state, data) {
      state.stateGraczDashbordTopTenSales = data
    }
  },
  actions: {
    async actionsGraczDashbord (context, access) {
      var response = await AxiosGraczDashbord.GraczDashbord(access)
      await context.commit('mutationsGraczDashbord', response)
    },
    async actionsGraczDashbordTopTen (context, access) {
      var response = await AxiosGraczDashbord.GraczDashbordTopTen(access)
      await context.commit('mutationsGraczDashbordTopTen', response)
    },
    async actionsGraczDashbordSales (context, access) {
      var response = await AxiosGraczDashbord.GraczDashbordSales(access)
      await context.commit('mutationsGraczDashbordSales', response)
    },
    async actionsGraczDashbordTopTenSales (context, access) {
      var response = await AxiosGraczDashbord.GraczDashbordTopTenSales(access)
      await context.commit('mutationsGraczDashbordTopTenSales', response)
    }
  }
}
export default ModuleGraczDashbord
