import AxiosUser from './axios_user_api'

const ModuleUser = {
  state: {
    // User Detail Page
    stateUserDetailPage: [],
    stateBindAccount: [],
    stateChangePasswordMarket: [],
    stateListUserAddress: [],
    stateUpdateUserAddress: [],
    stateCreateUserAddress: [],
    stateDeleteUserAddress: [],
    stateSetDefaultUserAddress: [],
    stateEditUserProfile: [],
    // Created AddressTaxinvoice Shoppingcart And Confirm order
    stateUPSCreatedAddressTaxinvoice: [],
    // Get AddressTaxinvoice Shoppingcart And Confirm order
    stateUPSGetAddressTaxinvoice: [],
    stateUPSGetAddressTaxinvoicePurchase: [],
    stateUPSListUserAddressTex: [],
    stateAuthorityUser: [],
    // getInvoice
    stategetInvoice: [],
    // upsertInvoice
    stateupsertInvoice: [],
    // get business
    stateGetBusiness: [],
    // add Invoice
    stateAddInvoice: [],
    // Get Invoice Address
    stateGetInvoiceAddress: []
  },
  mutations: {
    // User Detail Page
    mutationsUserDetailPage (state, data) {
      state.stateUserDetailPage = data
    },
    // Bind Account and Auto Add OneChat Bot + Create Shop
    mutationsBindAccount (state, data) {
      state.stateBindAccount = data
    },
    // Change Password Market
    mutationsChangePasswordMarket (state, data) {
      state.stateChangePasswordMarket = data
    },
    mutationListUserAddress (state, data) {
      state.stateListUserAddress = data
    },
    mutationUpdateUserAddress (state, data) {
      state.stateUpdateUserAddress = data
    },
    mutationCreateUserAddress (state, data) {
      state.stateCreateUserAddress = data
    },
    mutationDeleteUserAddress (state, data) {
      state.stateDeleteUserAddress = data
    },
    mutationSetDefaultUserAddress (state, data) {
      state.stateSetDefaultUserAddress = data
    },
    // Created AddressTaxinvoice Shoppingcart And Confirm order
    mutationsUPSCreatedAddressTaxinvoice (state, data) {
      state.stateUPSCreatedAddressTaxinvoice = data
    },
    // Get AddressTaxinvoice Shoppingcart And Confirm order
    mutationsUPSGetAddressTaxinvoice (state, data) {
      state.stateUPSGetAddressTaxinvoice = data
    },
    mutationsUPSGetAddressTaxinvoicePurchase (state, data) {
      state.stateUPSGetAddressTaxinvoicePurchase = data
    },
    mutationUPSListUserAddressTex (state, data) {
      state.stateUPSListUserAddressTex = data
    },
    // Edit User Profile
    mutationsEditUserProfile (state, data) {
      state.stateEditUserProfile = data
    },
    // Authority User
    mutationsAuthorityUser (state, data) {
      state.stateAuthorityUser = data
    },
    // getInvoice
    mutationsGetInvoice (state, data) {
      state.stategetInvoice = data
    },
    // upsertInvoice
    mutationsUpsertInvoice (state, data) {
      state.stateupsertInvoice = data
    },
    mutationsGetBusiness (state, data) {
      state.stateGetBusiness = data
    },
    // Add Invoice
    mutationsAddInvoice (state, data) {
      state.stateAddInvoice = data
    },
    // Get Invoice Address
    mutationsGetInvoiceAddress (state, data) {
      state.stateGetInvoiceAddress = data
    }
  },
  actions: {
    // User Detail Page
    async actionsUserDetailPage (context, access) {
      const responseData = await AxiosUser.GetUserDetailPage(access)
      // console.log('response', responseData)
      await context.commit('mutationsUserDetailPage', responseData)
    },
    // Bind Account and Auto Add OneChat Bot + Create Shop
    async actionBindAccount (context, access) {
      const responseData = await AxiosUser.SetBindAccount(access)
      await context.commit('mutationsBindAccount', responseData)
    },
    // Change Password Market
    async actionChangePasswordMarket (context, access) {
      const responseData = await AxiosUser.ChangePasswordMarket(access)
      await context.commit('mutationsChangePasswordMarket', responseData)
    },
    async actionListUserAddress (context, access) {
      const dataFromAxios = await AxiosUser.axiosListUserAddress(access)
      await context.commit('mutationListUserAddress', dataFromAxios)
    },
    async actionUpdateUserAddress (context, access) {
      const dataFromAxios = await AxiosUser.UpdateUserAddress(access)
      await context.commit('mutationUpdateUserAddress', dataFromAxios)
    },
    async actionCreateUserAddress (context, access) {
      const dataFromAxios = await AxiosUser.CreateUserAddress(access)
      await context.commit('mutationCreateUserAddress', dataFromAxios)
    },
    async actionDeleteUserAddress (context, access) {
      const dataFromAxios = await AxiosUser.DeleteUserAddress(access)
      await context.commit('mutationDeleteUserAddress', dataFromAxios)
    },
    async actionDefaultUserAddress (context, access) {
      const dataFromAxios = await AxiosUser.DefaultUserAddress(access)
      await context.commit('mutationSetDefaultUserAddress', dataFromAxios)
    },
    // Created AddressTaxinvoice Shoppingcart And Confirm order
    async actionUPSCreatedAddressTaxinvoice (context, access) {
      const responseData = await AxiosUser.CreatedAddressTaxinvoice(access)
      await context.commit('mutationsUPSCreatedAddressTaxinvoice', responseData)
    },
    // Get AddressTaxinvoice Shoppingcart And Confirm order
    async actionUPSGetAddressTaxinvoice (context, access) {
      const responseData = await AxiosUser.GetAddressTaxinvoice(access)
      await context.commit('mutationsUPSGetAddressTaxinvoice', responseData)
    },
    async actionUPSGetAddressTaxinvoicePurchase (context) {
      const responseData = await AxiosUser.GetAddressTaxinvoicePurchase()
      await context.commit('mutationsUPSGetAddressTaxinvoicePurchase', responseData)
    },
    async actionUPSListUserAddressTex (context, access) {
      const dataFromAxios = await AxiosUser.axiosUPSListUserAddressTex(access)
      await context.commit('mutationUPSListUserAddressTex', dataFromAxios)
    },
    async actionsEditUserProfile (context, access) {
      const dataResponse = await AxiosUser.UpdateUserProfile(access)
      await context.commit('mutationsEditUserProfile', dataResponse)
    },
    // เช็คสิทธิการแสดงแถบเมนู
    async actionsAuthorityUser (context, access) {
      const dataResponse = await AxiosUser.AuthorityUserAccount(access)
      await context.commit('mutationsAuthorityUser', dataResponse)
    },
    // getInvoice
    async actionsGetInvoice (context, access) {
      const dataResponse = await AxiosUser.GetInvoice(access)
      await context.commit('mutationsGetInvoice', dataResponse)
    },
    // upsertInvoice
    async actionsUpsertInvoice (context, access) {
      const dataResponse = await AxiosUser.UpsertInvoice(access)
      await context.commit('mutationsUpsertInvoice', dataResponse)
    },
    async actionsGetBusinessData (context) {
      const response = await AxiosUser.GetBusiness()
      await context.commit('mutationsGetBusiness', response)
    },
    // upsertInvoice
    async actionsAddInvoice (context, access) {
      const dataResponse = await AxiosUser.AddInvoice(access)
      await context.commit('mutationsAddInvoice', dataResponse)
    },
    // Get Invoice Address
    async actionsGetInvoiceAddress (context, access) {
      const dataResponse = await AxiosUser.GetInvoiceAddress(access)
      await context.commit('mutationsGetInvoiceAddress', dataResponse)
    }
  }
}
export default ModuleUser
