import AxiosTacking from './axios_tacking_api'

const ModuleTacking = {
  state: {
    stateAllOrder: [],
    stateRetern: [],
    stateAllOrderBuyer: [],
    stateAllOrderCompany: []
  },
  mutations: {
    mutationsAllOrder (state, data) {
      state.stateAllOrder = data
    },
    mutationsReturn (state, data) {
      state.stateRetern = data
    },
    mutationsAllOrderBuyer (state, data) {
      state.stateAllOrderBuyer = data
    },
    mutationsAllOrderCompany (state, data) {
      state.stateAllOrderCompany = data
    }
  },
  actions: {
    // ติดตามสถานะส่วนขอผู้ขาย(start)
    async actionGetTackingAll (context, access) {
      const data = await AxiosTacking.GetTackingDataAlls(access)
      await context.commit('mutationsAllOrder', data)
    },
    async actionGetReturn (context, access) {
      const data = await AxiosTacking.GetTackingDataReturn(access)
      await context.commit('mutationsReturn', data)
    },
    // ติดตามสถานะส่วนขอผู้ขาย(End)

    // ติดตามสถานะส่วนของผู้ซื้อ(start)
    async actionGetTackingAllBuyer (context, access) {
      const data = await AxiosTacking.GetTackingAll(access)
      await context.commit('mutationsAllOrderBuyer', data)
    },
    // async actionGetReturnBuyer (context, access) {
    //   const data = await AxiosTacking.GetTackingDataReturn(access)
    //   await context.commit('mutationsReturn', data)
    // }
    // ติดตามสถานะส่วนของผู้ซื้อ(End)

    // ติดตามสถานะของบริษัท(start)
    async actionGetTackingAllCompany (context, access) {
      const data = await AxiosTacking.GetTackingAllCompany(access)
      await context.commit('mutationsAllOrderCompany', data)
    }
    // ติดตามสถานะของบริษัท(End)
  }
}
export default ModuleTacking
