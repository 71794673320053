import AxiosRegisMorpromt from './axios_regis_morpromt'

const ModuleRegisMorpromt = {
  state: {
    stateGetOTP: [],
    stateConfirmOTP: [],
    stateFindTaxID: []
  },
  mutations: {
    mutationsGetOTP (state, data) {
      state.stateGetOTP = data
    },
    mutationsConfirmOTP (state, data) {
      state.stateConfirmOTP = data
    },
    mutationsFindTaxID (state, data) {
      state.stateFindTaxID = data
    }
  },
  actions: {
    async actionsGetOTP (context, access) {
      const getOTP = await AxiosRegisMorpromt.GetOTP(access)
      await context.commit('mutationsGetOTP', getOTP)
    },
    async actionsConfirmOTP (context, access) {
      const confirmOTP = await AxiosRegisMorpromt.ConfirmOTP(access)
      await context.commit('mutationsConfirmOTP', confirmOTP)
    },
    async actionsFindTaxID (context, access) {
      const findTaxID = await AxiosRegisMorpromt.FindTaxID(access)
      await context.commit('mutationsFindTaxID', findTaxID)
    }
  }
}

export default ModuleRegisMorpromt
