import axios from 'axios'
import { Decode } from '@/services'

const GetToken = () => {
  if (Object.prototype.hasOwnProperty.call(localStorage, 'oneData')) {
    const oneData = JSON.parse(Decode.decode(localStorage.getItem('oneData')))
    const auth = {
      headers: { Authorization: `Bearer ${oneData.user.access_token}` }
    }
    return auth
  }
}

export default {
  async GraczListProduct (data) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}api/g/list_product`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async GraczAddProduct (data) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}api/g/add_product`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async GraczEditProduct (data) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}api/g/edit_product`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async GraczChangeStatusProduct (data) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}api/g/change_status_product`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async GraczDeleteProduct (data) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}api/g/delete_product`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async GraczExportProduct (data) {
    var sellerShop = data.seller_shop_id
    var authen = data.auth
    var selected = data.selected
    try {
      var response = await axios.get(`${process.env.VUE_APP_BACK_END}api/g/export_product_price/${sellerShop}/[${selected}]/${authen}`)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async GraczImportProduct (data) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}api/g/import_product_price`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async GraczListPanitProduct (data) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}api/g/list_product_from_panit`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async GraczImportPanitProduct (data) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}api/g/import_product_from_panit`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  }
}
