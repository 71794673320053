import axios from 'axios'
import { Decode } from '@/services'

const GetToken = () => {
  if (Object.prototype.hasOwnProperty.call(localStorage, 'oneData')) {
    const oneData = JSON.parse(Decode.decode(localStorage.getItem('oneData')))
    const auth = {
      headers: { Authorization: `Bearer ${oneData.user.access_token}` }
    }
    return auth
  }
}

export default {
  async GraczCustomerSellerList (data) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}api/g/list_customer`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async GraczCustomerDetail (data) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}api/g/detail_customer`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async GraczCreateCustomer (data) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}api/g/create_customer`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async GraczEditCustomer (data) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}api/g/edit_customer`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async GraczDeleteCustomer (data) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}api/g/delete_customer`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async GraczImportCustomerData (data) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}api/g/import_customer_excel`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async GraczExportCustomerForm () {
    // const auth = await GetToken()
    try {
      var response = await axios.get(`${process.env.VUE_APP_BACK_END}api/g/export_excel_customer_form`)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async GraczGetCustomerBySalesCode (data) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}api/g/list_customer_by_sales_code`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async GraczEditSalesOfCustomer (data) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}api/g/edit_sales_of_customer`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async GraczGetListCustomerAddress (data) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}api/g/list_customer_address`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async GraczDetailCustomerAddress (data) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}api/g/detail_customer_address`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async GraczCreateCustomerAddress (data) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}api/g/create_customer_address`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async GraczEditCustomerAddress (data) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}api/g/edit_customer_address`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async GraczDeleteCustomerAddress (data) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}api/g/delete_customer_address`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  // sale
  async GraczListCustomerOfSales (data) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}api/g/list_customer_of_sales`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async GraczDetailCustomerOfSales (data) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}api/g/detail_customer_of_sales`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async GraczListCustomerAddressOfSales (data) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}api/g/list_customer_address_of_sales`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async GraczCustomerExcelExportGetLoading (data) {
    const auth = await GetToken()
    try {
      var response = await axios.get(`${process.env.VUE_APP_BACK_END}api/g/export_excel_customer?seller_shop_id=` + data.seller_shop_id, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async GraczCreateCustomerForSales (data) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}api/g/create_customer_for_sales`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async GraczGetCustomerAndSalesList (data) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}api/g/list_sales_and_customer`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  }
}
