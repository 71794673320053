import axios from 'axios'
import { Decode } from '@/services'

const GetToken = () => {
  if (Object.prototype.hasOwnProperty.call(localStorage, 'oneData')) {
    const oneData = JSON.parse(Decode.decode(localStorage.getItem('oneData')))
    // console.log('ONEDATA NAAAa', oneData)
    const auth = {
      headers: { Authorization: `Bearer ${oneData.user.access_token}` }
    }
    return auth
  }
}

export default {
  // Product Detail
  async GetOrderList (val) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}api/list_order_buyer_v2`, val, auth)
      // console.log('data orderlist', response.data)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async OrderDetail (val) {
    const auth = await GetToken()
    // console.log(val)
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}api/detail_order_v2`, val, auth)
      // console.log('data orderdetail', response.data)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async OrderListSeller (val) {
    const auth = await GetToken()
    // const data = {
    //   seller_shop_id: val.seller_shop_id.toString()
    // }
    // console.log('data', data)
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}api/list_order_seller_v2`, val, auth)
      // console.log('data orderdetail=====>', response.data)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async OrderDetailSeller (val) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}api/detail_order_seller_v2`, val, auth)
      // console.log('Shoooooooppppp   orderdetail', response.data)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async UpdateStatusSeller (val) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}api/update_order_status`, val, auth)
      // console.log('update send status ', response.data)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async CancelStatusSeller (val) {
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}api/cancel_order_mobilyst`, val)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async UpdateStatusBuyer (val) {
    const auth = await GetToken()
    // console.log('ออกมาแล้ววววว', val)
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}api/buyer_update_status_order`, val, auth)
      // console.log('update send status ', response.data)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async GetListOrderApprove () {
    const auth = await GetToken()
    try {
      var response = await axios.get(`${process.env.VUE_APP_BACK_END}api/detail_approver_order_v2`, auth)
      // console.log('List Order Approve ', response.data)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async ApproveData (val) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}api/approve_order`, val, auth)
      // console.log('List Order Approve ', response.data)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async AcceptProduct (val) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}api/accecpt_product/accecpt_product`, val, auth)
      // console.log('AcceptProduct', response.data)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  // CheckAcceptProduct
  async CheckAcceptProduct (val) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}api/accecpt_product/check_accept`, val, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async GetListOrder (val) {
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}api/list_ups_order`, val)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  // Refund buyer list
  async RefundBuyerList (val) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}api/list_refund_buyer`, val, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  // Refund order detail
  async RefundOrderDetail (val) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}api/detail_refund_buyer`, val, auth)
      // console.log('data orderdetail=====>', response.data)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  // Send Refund Buyer
  async SendRefundBuyer (val) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}api/send_refund`, val, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  // Send Approve Refund Seller
  async ApproveRefundSeller (val) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}api/approve_refund_seller`, val, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  // Detaile Approve Refund Seller
  async ApproveDetailRefundSeller (val) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}api/detail_refund_approve_seller`, val, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async GetallProductReviewSeller (data) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}api/list_all_product_review_seller`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async CreateReplyComment (data) {
    const shopSellerID = localStorage.getItem('shopSellerID').toString()
    const edit = await {
      feedback_id: shopSellerID,
      reply_comment: '555555555'
    }
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}api/create_reply_comment`, edit)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async EditReplyComment (data) {
    const shopSellerID = localStorage.getItem('shopSellerID').toString()
    const edit = await {
      reply_id: shopSellerID,
      reply_edit: ''
    }
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}api/edit_reply_comment`, edit)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async DetailReviewSeller (data) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}api/detail_product_review_seller_v2`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async ChangeStatusReview (data) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}api/seller_update_status_comment`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async DetailFeedbackReview (data) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}api/detail_product_feedback_seller`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  }
}
