import axios from 'axios'
import { Decode } from '@/services'
// import dataTest from '@/components/library/dataTest.json'

const GetToken = () => {
  if (Object.prototype.hasOwnProperty.call(localStorage, 'oneData')) {
    const oneData = JSON.parse(Decode.decode(localStorage.getItem('oneData')))
    const auth = {
      headers: { Authorization: `Bearer ${oneData.user.access_token}` }
    }
    return auth
  }
}

export default {
  async getCategory () {
    // const auth = await GetToken()
    // console.log('GetUPSCategory', data)
    try {
      var response = await axios.get(`${process.env.VUE_APP_BACK_END}api/category/get_category`)
      // console.log(response.data)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  // Shop Detail Page
  async GetShopDetailPage (val) {
    var response
    var data
    if (val === null) {
      data = {
        seller_shop_id: val.seller_shop_id,
        role_user: 'ext_buyer'
      }
    } else {
      data = {
        seller_shop_id: val.seller_shop_id,
        role_user: val.role,
        company_id: val.company_id
      }
    }
    try {
      if (localStorage.getItem('oneData') === null) {
        response = await axios.post(`${process.env.VUE_APP_BACK_END}api/seller_shop_page`, data)
      } else if (localStorage.getItem('oneData') !== null) {
        const auth = await GetToken()
        response = await axios.post(`${process.env.VUE_APP_BACK_END}api/seller_shop_page`, data, auth)
      }
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async CreateSellerShop (val) {
    const auth = await GetToken()
    const data = val.dataShop
    // console.log('val nna', data)
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}api/create_shop`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async GetSellerShop (data) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}api/get_shop_detail`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async CreateSettingSellerShop (val) {
    const auth = await GetToken()
    // const data = val.dataShop
    const data = val
    // console.log(val, 'val')
    // console.log('val nna', data)
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}api/setting_shop_detail`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  // delete product
  async deleteProduct (data) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}api/product/delete_product`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  // product select option
  async GetSelectOption (data) {
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}api/product_select_option`, data)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async UpShopaddresss (data) {
    // const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}api/update_shop_address`, data)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async GetUpShopaddresss (data) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}api/get_shop_detail`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async PostListRefundSeller (data) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}api/list_refund_seller`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async PostDetailRefundSeller (data) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}api/detail_refund_seller`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async PostNotiRefundSeller (data) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}api/detail_refund_approve_seller`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async Dashboard (data) {
    const shopSellerID = localStorage.getItem('shopSellerID').toString()
    const dataAll = await {
      shopSellerID: shopSellerID,
      search_type: data.search_type,
      start_date: data.start_date,
      end_date: data.end_date
    }
    // console.log('dataall', dataAll)
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END2}dashboard/getDashboard`, dataAll)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async Dashboard2 (data) {
    const shopSellerID = localStorage.getItem('shopSellerID').toString()
    const dataAll = await {
      shopSellerID: shopSellerID,
      search_type: data.search_type,
      start_date: data.start_date,
      end_date: data.end_date
    }
    // console.log('SSDashboard2', dataAll)
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END2}dashboard/getDashboard`, dataAll)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async GetProductBestSeller (data) {
    const shopSellerID = localStorage.getItem('shopSellerID').toString()
    const dataAll = await {
      shopSellerID: shopSellerID
    }
    // console.log('dataall', dataAll)
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END2}dashboard/getProductBestSeller`, dataAll)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async GetUserMostBuyProduct (data) {
    const shopSellerID = localStorage.getItem('shopSellerID').toString()
    const dataAll = await {
      shopSellerID: shopSellerID
    }
    // console.log('dataall', dataAll)
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END2}dashboard/getUserMostBuyProduct`, dataAll)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async GetListRefMerchant (data) {
    // console.log('GetListRefMerchant')
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}api/list_ref_share_to_merchant`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async GetRefMerchant (data) {
    // console.log('GetRefMerchant xxx')
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}api/ref_share_to_merchant`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async CreateShop (data) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}api/create_new_seller_shop`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async DetailShop (data) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}api/get_seller_shop_detail_mp`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async EditShop (data) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}api/edit_seller_shop_mp`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async ShowProductShop (data) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}api/edit_seller_shop_mp`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async DetailMerchantFromPayment (data) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}api/detail_merchant_from_payment`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  // Get Review Prodoct
  async GetReviewProduct (data) {
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}api/get_all_review_in_product`, data)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },

  async GetListPartnerBuyer (data) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}api/2022/company/list_seller_partner_v2`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },

  async PostRequestPartnerBuyer (data) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}api/2022/company/request_partner_v2`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },

  async DetailDocumentShop (data) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}api/2022/company/detail_document_shop`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async DetailSellerPartner (data) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}api/2022/company/detail_seller_partner`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async EditPartner (data) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}api/2022/company/edit_request_partner`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  // api Quotation List  seller
  async ListQuotationSeller (data) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}api/qu/list_for_seller`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  //
  async ListUserCompany (data) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}api/2022/company/list_position_of_company`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async DetailUserCompanyPosition (data) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}api/2022/company/detail_position_of_company`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async CreatePositon (data) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}api/2022/company/create_position_of_company`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  // Edit Position
  async EditPosition (data) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}api/2022/company/edit_position_of_company`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  // list Company Position
  async ListCompanyPosition (data) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}api/2022/company/list_user_company`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  // Search User Company
  async SearchUserCompany (data) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}api/2022/company/search_user`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  // Detail User Company
  async DetailUserCompany (data) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}api/2022/company/detail_user_company`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  // Edit Position User Company
  async EditPositionCompany (data) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}api/2022/company/edit_user_company`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async CreatePositionCompany (data) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}api/2022/company/add_user_company`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  // listData Manage Buyer
  async ListManageBuyer (data) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}api/2022/company/list_manage_buyer`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async PostReport (data) {
    // console.log('PostReport', data)
    // const shopSellerID = localStorage.getItem('shopSellerID').toString()
    // const auth = await GetToken()
    // const oneData = JSON.parse(Decode.decode(localStorage.getItem('oneData')))
    // const dataAll = {
    //   token: oneData.user.access_token,
    //   search: data
    // }
    // const dataAll = await {
    //   shopSellerID: shopSellerID,
    //   search_type: data.search_type,
    //   start_date: data.start_date,
    //   end_date: data.end_date
    // }
    // console.log('PostReport', dataAll)
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END2}report/company/getReport`, data)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async DetailManageBuyer (data) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}api/2022/company/detail_manage_buyer`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async ListAppover (data) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}api/2022/company/list_approver_manage_buyer`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async AddAppover (data) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}api/2022/company/add_approver_manage_buyer`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async EditAppover (data) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}api/2022/company/edit_approver_manage_buyer`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async AppoverQU (data) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}api/qu/approve_qu`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  // (YaPalm)Seller List Order Credit Term
  async listOrderCreditTerm (data) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}api/credit_term/list_by_seller_shop`, data, auth)
      return response.data
    } catch (error) {
      return error.response
    }
  },
  // (YaPalm)Seller List Credit Term
  async listCreditTerm (data) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}api/credit_term/list_credit_term_by_seller_shop`, data, auth)
      return response.data
    } catch (error) {
      return error.response
    }
  },
  // (YaPalm)Seller List Credit Term
  async uploadInvoiceCreditTerm (data) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}api/credit_term/upload_invoice_credit_term`, data, auth)
      return response.data
    } catch (error) {
      return error.response
    }
  },
  // (YaPalm)Seller Request New Credit Term
  async listRequestChangeTermBySellerShop (data) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}api/credit_term/list_request_change_term_by_seller_shop`, data, auth)
      return response.data
    } catch (error) {
      return error.response
    }
  },
  // (YaPalm)Seller Request New Credit Term
  async updateRequestCreditTerm (data) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}api/credit_term/update_request_credit_term_by_seller_shop`, data, auth)
      return response.data
    } catch (error) {
      return error.response
    }
  },
  // (Ya)Seller List Special Price Seller
  async listSpecialPriceSeller (data) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END2}listSpecialPriceSeller`, data, auth)
      return response.data
    } catch (error) {
      return error.response
    }
  },
  // (Ya)Seller Detail Special Price Seller
  async detailSpecialPrice (data) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END2}detailSpecialPrice`, data, auth)
      return response.data
    } catch (error) {
      return error.response
    }
  },
  // (Non) New Seller Detail Special Price Seller
  async newDetailSpecialPrice (data) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END2}detailSpecialPriceSeller`, data, auth)
      return response.data
    } catch (error) {
      return error.response
    }
  },
  // (Ya)Seller Approve Special Price Seller request auth
  async approveSpecialPrice (data) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END2}approveSpecialPrice`, data, auth)
      return response.data
    } catch (error) {
      return error.response
    }
  },
  // (Ya)Seller Calculate Special Price Seller
  async calculateSpecialPrice (data) {
    const auth = GetToken()
    try {
      // console.log('before fn')
      var response = await axios.post(`${process.env.VUE_APP_BACK_END2}calculateSpecialPrice`, data, auth)
      // console.log('fn pass', response.data)
      return response.data
    } catch (error) {
      return error.response
    }
  },
  // (Ya)Seller Edit Special Price Seller
  async editSpecialPrice (data) {
    const auth = GetToken()
    try {
      // console.log('before fn')
      var response = await axios.post(`${process.env.VUE_APP_BACK_END2}editSpecialPriceSeller`, data, auth)
      // console.log('fn pass', response.data)
      return response.data
    } catch (error) {
      return error.response
    }
  },
  // (Ya)Merchant
  async checkMerchant (data) {
    const auth = GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}api/check_merchant`, data, auth)
      return response.data
    } catch (error) {
      return error.response
    }
  },
  async createMerchant (data) {
    const auth = GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}api/create_merchant`, data, auth)
      return response.data
    } catch (error) {
      return error.response
    }
  },
  async createUpdatePositionInShop (data) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}api/create_update_position_in_shop`, data, auth)
      // console.log(response.data)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async getPositionDetailInShop (data) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}api/get_position_detail_in_shop`, data, auth)
      // console.log(response.data)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async listPositionInShop (data) {
    // console.log('888 axios', data)
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}api/list_position_in_shop`, data, auth)
      // console.log(response.data)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async manageUserWithRoleInShop (data) {
    // console.log('888 axios', data)
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}api/manage_user_with_role_in_shop`, data, auth)
      // console.log(response.data)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  // shop coupon
  async listShopCoupon (data) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END2}ngs/listShopCoupon`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async detailShopCoupon (data) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END2}ngs/detailShopCoupon`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async deleteShopCoupon (data) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END2}ngs/deleteShopCoupon`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async createShopCoupon (data) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END2}ngs/createShopCoupon`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async searchCoupon (data) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}api/search_coupon `, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async editShopCoupon (data) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END2}ngs/editShopCoupon`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async DetailMerchantCrterm (data) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}api/detail_merchant_crterm_from_payment`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async RefShareCreditTerm (data) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}api/refshare_credit_term_to_merchant`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async NewGetDashboard (data) {
    console.log('NewGetDashboard', data)
    const auth = await GetToken()
    const shopSellerID = localStorage.getItem('shopSellerID').toString()
    const dataAll = await {
      seller_shop_id: shopSellerID,
      type: data.type,
      start_date: data.start_date,
      end_date: data.end_date
    }
    // console.log('dataall', dataAll)
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END2}dashboard/v2/getDashboard`, dataAll, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async UpdatePositionStatus (data) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}api/update_position_status`, data, auth)
      // console.log(response.data)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  // Check User Business
  async CheckUserBusiness () {
    const auth = await GetToken()
    try {
      var response = await axios.get(`${process.env.VUE_APP_BACK_END}api/check_user_business`, auth)
      // console.log(response.data)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async GetNewRefMerchant (data) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}api/withdraw_seller`, data, auth)
      // console.log(response.data)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async GetListAllShop (data) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}api/list_all_shop`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  }
}
