import AxiosSaleOrderEtax from './axios_gracz_etax_api'

const ModuleSaleOrderEtax = {
  state: {
    stateSaleOrderDetailSettingEtax: [],
    stateSaleOrderCheckETaxInShop: [],
    stateSaleOrderSendAccessETax: [],
    stateSaleOrderGetInvoice: [],
    stateSaleOrderUpsertInvoice: [],
    stateSaleOrderAddInvoice: [],
    stateSaleOrderGetInvoiceAddress: [],
    stateCreateaEtax: [],
    stateDownloadEtaxDocument: [],
    stateCheckTaxId: [],
    stateListDocumentEtax: [],
    stateDetailDocumentEtax: [],
    stateCreateDocumentEtax: [],
    stateEditDocumentEtax: [],
    stateLoginBySharedTokenEfac: [],
    stateUpdateEtaxDocument: [],
    stateDetailEtaxDocument: [],
    stateListEtaxDocumentOrder: [],
    stateCreateCreditNote: []
  },
  mutations: {
    mutationsSaleOrderDetailSettingEtax (state, data) {
      state.stateSaleOrderDetailSettingEtax = data
    },
    mutationsSaleOrderCheckETaxInShop (state, data) {
      state.stateSaleOrderCheckETaxInShop = data
    },
    mutationsSaleOrderSendAccessETax (state, data) {
      state.stateSaleOrderSendAccessETax = data
    },
    mutationsSaleOrderGetInvoice (state, data) {
      state.stateSaleOrderGetInvoice = data
    },
    mutationsSaleOrderUpsertInvoice (state, data) {
      state.stateSaleOrderUpsertInvoice = data
    },
    mutationsSaleOrderAddInvoice (state, data) {
      state.stateSaleOrderAddInvoice = data
    },
    mutationsSaleOrderGetInvoiceAddress (state, data) {
      state.stateSaleOrderGetInvoiceAddress = data
    },
    mutationsCreateaEtax (state, data) {
      state.stateCreateaEtax = data
    },
    mutationsDownloadEtaxDocument (state, data) {
      state.stateDownloadEtaxDocument = data
    },
    mutationsCheckTaxId (state, data) {
      state.stateCheckTaxId = data
    },
    mutationsListDocumentEtax (state, data) {
      state.stateListDocumentEtax = data
    },
    mutationsDetailDocumentEtax (state, data) {
      state.stateDetailDocumentEtax = data
    },
    mutationsCreateDocumentEtax (state, data) {
      state.stateCreateDocumentEtax = data
    },
    mutationsEditDocumentEtax (state, data) {
      state.stateEditDocumentEtax = data
    },
    mutationsUpdateStatusDocumentEtax (state, data) {
      state.stateUpdateStatusDocumentEtax = data
    },
    mutationsLoginBySharedTokenEfac (state, data) {
      state.stateLoginBySharedTokenEfac = data
    },
    mutationsUpdateEtaxDocument (state, data) {
      state.stateUpdateEtaxDocument = data
    },
    mutationsDetailEtaxDocument (state, data) {
      state.stateDetailEtaxDocument = data
    },
    mutationsListEtaxDocumentOrder (state, data) {
      state.stateListEtaxDocumentOrder = data
    },
    mutationsCreateCreditNote (state, data) {
      state.stateCreateCreditNote = data
    }
  },
  actions: {
    async actionsSaleOrderDetailSettingEtax (context, access) {
      var response = await AxiosSaleOrderEtax.SaleOrderDetailSettingEtax(access)
      await context.commit('mutationsSaleOrderDetailSettingEtax', response)
    },
    async actionsSaleOrderCheckETaxInShop (context, access) {
      var response = await AxiosSaleOrderEtax.SaleOrderCheckETaxInShop(access)
      await context.commit('mutationsSaleOrderCheckETaxInShop', response)
    },
    async actionsSaleOrderSendAccessETax (context, access) {
      var response = await AxiosSaleOrderEtax.SaleOrderSendAccessETax(access)
      await context.commit('mutationsSaleOrderSendAccessETax', response)
    },
    async actionsSaleOrderGetInvoice (context, access) {
      var response = await AxiosSaleOrderEtax.SaleOrderGetInvoice(access)
      await context.commit('mutationsSaleOrderGetInvoice', response)
    },
    async actionsSaleOrderUpsertInvoice (context, access) {
      var response = await AxiosSaleOrderEtax.SaleOrderUpsertInvoice(access)
      await context.commit('mutationsSaleOrderUpsertInvoice', response)
    },
    async actionsSaleOrderAddInvoice (context, access) {
      var response = await AxiosSaleOrderEtax.SaleOrderAddInvoice(access)
      await context.commit('mutationsSaleOrderAddInvoice', response)
    },
    async actionsSaleOrderGetInvoiceAddress (context, access) {
      var response = await AxiosSaleOrderEtax.SaleOrderGetInvoiceAddress(access)
      await context.commit('mutationsSaleOrderGetInvoiceAddress', response)
    },
    async actionsCreateaEtax (context, access) {
      var response = await AxiosSaleOrderEtax.CreateaEtax(access)
      await context.commit('mutationsCreateaEtax', response)
    },
    async actionsDownloadEtaxDocument (context, access) {
      var response = await AxiosSaleOrderEtax.DownloadEtaxDocument(access)
      await context.commit('mutationsDownloadEtaxDocument', response)
    },
    async actionsCheckTaxId (context, access) {
      var response = await AxiosSaleOrderEtax.CheckTaxId(access)
      await context.commit('mutationsCheckTaxId', response)
    },
    async actionsListDocumentEtax (context, access) {
      var response = await AxiosSaleOrderEtax.ListDocumentEtax(access)
      await context.commit('mutationsListDocumentEtax', response)
    },
    async actionsDetailDocumentEtax (context, access) {
      var response = await AxiosSaleOrderEtax.DetailDocumentEtax(access)
      await context.commit('mutationsDetailDocumentEtax', response)
    },
    async actionsCreateDocumentEtax (context, access) {
      var response = await AxiosSaleOrderEtax.CreateDocumentEtax(access)
      await context.commit('mutationsCreateDocumentEtax', response)
    },
    async actionsEditDocumentEtax (context, access) {
      var response = await AxiosSaleOrderEtax.EditDocumentEtax(access)
      await context.commit('mutationsEditDocumentEtax', response)
    },
    async actionsUpdateStatusDocumentEtax (context, access) {
      var response = await AxiosSaleOrderEtax.UpdateStatusDocumentEtax(access)
      await context.commit('mutationsUpdateStatusDocumentEtax', response)
    },
    async actionsLoginBySharedTokenEfac (context) {
      var response = await AxiosSaleOrderEtax.LoginBySharedTokenEfac()
      await context.commit('mutationsLoginBySharedTokenEfac', response)
    },
    async actionsUpdateEtaxDocument (context, access) {
      var response = await AxiosSaleOrderEtax.UpdateEtaxDocument(access)
      await context.commit('mutationsUpdateEtaxDocument', response)
    },
    async actionsDetailEtaxDocument (context, access) {
      var response = await AxiosSaleOrderEtax.DetailEtaxDocument(access)
      await context.commit('mutationsDetailEtaxDocument', response)
    },
    async actionsListEtaxDocumentOrder (context, access) {
      var response = await AxiosSaleOrderEtax.ListEtaxDocumentOrder(access)
      await context.commit('mutationsListEtaxDocumentOrder', response)
    },
    async actionsCreateCreditNote (context, access) {
      var response = await AxiosSaleOrderEtax.CreateCreditNote(access)
      await context.commit('mutationsCreateCreditNote', response)
    }
  }
}
export default ModuleSaleOrderEtax
