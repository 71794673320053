import AxiosCoupons from './axios_mycoupnsandpoints'

const ModuleCoupons = {
  state: {
    // get_MyCuoponsAndPoint
    stateGetMyCuoponsAndPoint: [],
    stateGetCompanyCuoponsAndPoint: [],
    stateGetHomeCouponsAll: [],
    stateAllCouponInShop: [],
    stateKeepCoupon: [],
    stateGetCouponCart: [],
    stateGetPointsWithCart: [],
    stateReserveCoupon: [],
    stateCancelCoupon: [],
    stateReservePoint: [],
    stateCancelPoint: [],
    stateSearchCoupon: [],
    stateCouponModel: false,
    getCouponQu: [],
    getPointQu: [],
    SelectCouponOrPoint: {
      coupon: [],
      point: []
    },
    stateData: [],
    stateUseCodeFromNGS: []
  },
  mutations: {
    // get_MyCuoponsAndPoint
    mutationsMyCuoponsAndPoint (state, data) {
      state.stateGetMyCuoponsAndPoint = data
    },
    // get Company Cuopons And Point
    mutationsCompanyCuoponsAndPoint (state, data) {
      state.stateGetCompanyCuoponsAndPoint = data
    },
    // get Home Coupons All
    mutationsHomeCouponsAll (state, data) {
      state.stateGetHomeCouponsAll = data
    },
    // get list All Coupons in shop
    mutationsAllCouponInShop (state, data) {
      state.stateAllCouponInShop = data
    },
    // keep Coupons in shop
    mutationsKeepCouponInShop (state, data) {
      state.stateKeepCoupon = data
    },
    // Get Coupon With Cart
    mutationsGetCouponCart (state, data) {
      state.stateGetCouponCart = data
    },
    // PointsWithCart
    mutationsGetPointsWithCart (state, data) {
      state.stateGetPointsWithCart = data
    },
    // Reserve Coupon
    mutationsReserveCoupon (state, data) {
      state.stateReserveCoupon = data
    },
    // CancelCoupon
    mutationsCancelCoupon (state, data) {
      state.stateCancelCoupon = data
    },
    mutationsReservePoint (state, data) {
      state.stateReservePoint = data
    },
    mutationsCancelPoint (state, data) {
      state.stateCancelPoint = data
    },
    mutationsSearchCoupon (state, data) {
      state.stateSearchCoupon = data
    },
    mutationsUseCodeFromNGS (state, data) {
      state.stateUseCodeFromNGS = data
    }
  },
  actions: {
    // get_MyCuopons
    async actionsMyCuoponsAndPoint (context, access) {
      var response = await AxiosCoupons.GetMyCuoponsAndPoint(access)
      await context.commit('mutationsMyCuoponsAndPoint', response)
    },
    // get Company CuponsAndPoint
    async actionsCompanyCuoponsAndPoint (context, access) {
      var response = await AxiosCoupons.GetCompanyCuoponsAndPoint(access)
      await context.commit('mutationsCompanyCuoponsAndPoint', response)
    },
    // get Coupon All Home
    async actionsHomeCouponsAll (context, access) {
      var response = await AxiosCoupons.GetHomeCouponsAll(access)
      await context.commit('mutationsHomeCouponsAll', response)
    },
    // get list All Coupons in shop
    async actionsAllCouponInShop (context, access) {
      var response = await AxiosCoupons.GetAllCouponInShop(access)
      await context.commit('mutationsAllCouponInShop', response)
    },
    // keep Coupons in shop
    async actionskeepCouponInShop (context, access) {
      var response = await AxiosCoupons.KeepCouponInShop(access)
      await context.commit('mutationsKeepCouponInShop', response)
    },
    // Get Coupon With Cart
    async actionsGetCouponCart (context, access) {
      var response = await AxiosCoupons.GetCouponCart(access)
      await context.commit('mutationsGetCouponCart', response)
    },
    // api/get_points_with_cart
    async actionsGetPointsWithCart (context, access) {
      var response = await AxiosCoupons.GetPointsWithCart(access)
      await context.commit('mutationsGetPointsWithCart', response)
    },
    // Reserve Coupon
    async actionsReserveCoupon (context, access) {
      var response = await AxiosCoupons.ReserveCoupon(access)
      await context.commit('mutationsReserveCoupon', response)
    },
    // Cancel Coupon
    async actionsCancelCoupon (context, access) {
      var response = await AxiosCoupons.CancelCoupon(access)
      await context.commit('mutationsCancelCoupon', response)
    },
    // reserve_point
    async actionsReservePoint (context, access) {
      var response = await AxiosCoupons.ReservePoint(access)
      await context.commit('mutationsReservePoint', response)
    },
    // Cancel Point
    async actionsCancelPoint (context, access) {
      var response = await AxiosCoupons.CancelPoint(access)
      await context.commit('mutationsCancelPoint', response)
    },
    // search Coupon
    async actionsSearchCoupon (context, access) {
      var response = await AxiosCoupons.SearchCoupon(access)
      await context.commit('mutationsSearchCoupon', response)
    },
    // Use Code From NGS
    async actionsUseCodeFromNGS (context, access) {
      var response = await AxiosCoupons.UseCodeFromNGS(access)
      await context.commit('mutationsUseCodeFromNGS', response)
    }
  }
}
export default ModuleCoupons
