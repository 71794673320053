import axios from 'axios'
import { Decode } from '@/services'

const GetToken = () => {
  if (Object.prototype.hasOwnProperty.call(localStorage, 'oneData')) {
    const oneData = JSON.parse(Decode.decode(localStorage.getItem('oneData')))
    const auth = {
      headers: { Authorization: `Bearer ${oneData.user.access_token}` }
    }
    return auth
  }
}

export default {
  async GraczAddToCart (data) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}api/g/add_to_cart_gracz`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async GraczGetDetailCart (data) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}api/g/get_detail_cart_gracz`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async GraczUpdateCart (data) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}api/g/update_cart_gracz`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async GraczDeleteSelectItem (data) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}api/g/delete_product_in_cart`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async GraczGetCartCheckout (data) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}api/g/get_cart_gracz`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async GraczCreateOrder (data) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}api/g/create_order_gracz`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async GraczGetListPromotionCart (data) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}api/g/list_promotion_cart`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async GraczFindProductPromotionCart (data) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}api/g/find_product_promotion`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async GraczSearchPromotionCart (data) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}api/g/search_promotion`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async GraczCheckProductPromotionCart (data) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}api/g/check_promotion_cart_gracz`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async SaleOrderGetPaymentPage (data) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}api/g/get_payment_page`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  }
}
