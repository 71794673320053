import axios from 'axios'
import { Decode } from '@/services'

const GetToken = () => {
  if (localStorage.getItem('oneData') !== null) {
    const oneData = JSON.parse(Decode.decode(localStorage.getItem('oneData')))
    const auth = {
      headers: { Authorization: `Bearer ${oneData.user.access_token}` }
    }
    return auth
  } else {
    const auth = ''
    return auth
  }
}

export default {
  // Create Company
  async createCompany (data) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}api/2022/company/create_v2`, data, auth)
      // console.log(response.data)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  // Edit Company
  async editCompany (data) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}api/2022/company/edit_v2`, data, auth)
      // console.log(response.data)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  // Detail Company
  async detailCompany (data) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}api/2022/company/detail_v2`, data, auth)
      // console.log(response.data)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  // List Company
  async listCompany () {
    const auth = await GetToken()
    try {
      var response = await axios.get(`${process.env.VUE_APP_BACK_END}api/2022/company/list_v2`, auth)
      // console.log(response.data)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  // List User Commpany
  async listUserCompany () {
    const auth = await GetToken()
    try {
      var response = await axios.get(`${process.env.VUE_APP_BACK_END}api/2022/user/list`, auth)
      // console.log(response.data)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  // List PO For Buyer
  async listPOBuyer (data) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}api/qu/list_for_buyer`, data, auth)
      // console.log(response.data)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  // Detail QU for buyer
  async DetailQUBuyer (data) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}api/qu/detail`, data, auth)
      // console.log(response.data)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  // update_status_buyer
  async UpdateStatusBuyer (data) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}api/qu/update_status_buyer`, data, auth)
      // console.log(response.data)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  // list_order_purchaser
  async listOrderPurchaser (data) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}api/list_order_purchaser`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  // detail_order_purchaser
  async detailOrderPurchaser (data) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}api/detail_order_purchaser`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  // edit QU Buyer
  async EditQU (data) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}api/qu/detail_edit_qu`, data, auth)
      // console.log('', response.data)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async estimateQu (data) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}api/qu/estimate_qu`, data, auth)
      // console.log('', response.data)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  // List Refund Product Company
  async ListRefundPurchaser (data) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}api/list_refund_purchaser`, data, auth)
      // console.log(response.data)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  // Detail Refund Product Company
  async DetailRefundPurchaser (data) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}api/detail_refund_purchaser`, data, auth)
      // console.log(response.data)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  // (YaPalm) List Order Credit Term
  async listOrderCreditTerm (data) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}api/credit_term/list_by_company`, data, auth)
      return response.data
    } catch (error) {
      return error.response
    }
  },
  // (YaPalm) List Credit Term
  async listCreditTerm (data) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}api/credit_term/list_credit_term_by_company`, data, auth)
      return response.data
    } catch (error) {
      return error.response
    }
  },
  // (YaPalm) Request New Credit Term
  async calculateRequestCreditTerm (data) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}api/credit_term/calculate_request_credit_term`, data, auth)
      return response.data
    } catch (error) {
      return error.response
    }
  },
  // (YaPalm) Check Calculate
  async checkCalculate (data) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}api/credit_term/check_calculate_request_credit_term`, data, auth)
      return response.data
    } catch (error) {
      return error.response
    }
  },
  // (YaPalm) Request New Credit Term
  async requestChangeTerm (data) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}api/credit_term/request_change_term`, data, auth)
      return response.data
    } catch (error) {
      return error.response
    }
  },
  // (YaPalm) Cancel Request New Credit Term
  async cancelRequestChangeTerm (data) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}api/credit_term/update_request_credit_term_by_company`, data, auth)
      return response.data
    } catch (error) {
      return error.response
    }
  },
  async EditQuotation (data) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}api/qu/edit_qu`, data, auth)
      // console.log(response.data)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async listPositionOfCompany (data) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}api/2022/company/list_position_of_company`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  // List User  podition Seller
  async ListPositionUserSeller (data) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}api/list_user_in_shop`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async detailPositionOfCompany (data) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}api/2022/company/detail_position_of_company`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async DetailPositionUserSeller (data) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}api/get_user_detail_with_role`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async createPositionOfCompany (data) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}api/2022/company/create_position_of_company`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async SearchPositionUserSeller (data) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}api/search_email_check_user_in_biz`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async editPositionOfCompany (data) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}api/2022/company/edit_position_of_company`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  // Edit User Position
  async EditPositionUserSeller (data) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}api/edit_user_in_shop`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  // Add User Position
  async AddPositionUserSeller (data) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}api/manage_user_with_role_in_shop`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  // list Position shop
  async ListPositionShop (data) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}api/list_position_in_shop`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  // ListSpecialPriceBuyer
  async ListSpecialPriceBuyer (data) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END2}listSpecialPriceBuyer`, data, auth)
      // console.log(response.data)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async DetailSpecialPrice (data) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END2}detailSpecialPrice`, data, auth)
      // console.log(response.data)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  // search product in modal special request buyer
  async ListProductAttribute (data) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}api/list_product_attribute`, data, auth)
      // console.log(response.data)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async UpdateListDataTableSpecialPrice (data) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END2}calculateSpecialPrice`, data, auth)
      // console.log(response.data)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async UpdateStatusSpecialPriceBuyer (data) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END2}updateStatusSpecialPriceBuyer`, data, auth)
      // console.log(response.data)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async RequestSpecialPrice (data) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END2}requestSpecialPrice`, data, auth)
      // console.log(response.data)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async EditSpecialPriceBuyer (data) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END2}editSpecialPriceBuyer`, data, auth)
      // console.log(response.data)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async GetPayment (data) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}api/qu/create_order_qu`, data, auth)
      // console.log(response.data)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async CreateQUCart (data) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}api/qu/create_qu_cart`, data, auth)
      // console.log(response.data)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async CreateCompanyByBiz () {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}api/create_company_by_biz`, '', auth)
      // console.log(response.data)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async DashBorardAdmin (data) {
    console.log('เข้า 2', data)
    const auth = await GetToken()
    // const data = {
    //   start_date: '2022-08-01',
    //   end_date: '2022-08-09',
    //   year: '2022'
    // }
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END4}panit/dashboard/summary`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async GetListAdminPlatform () {
    const auth = await GetToken()
    try {
      var response = await axios.get(`${process.env.VUE_APP_BACK_END}api/admin_platform/list_admin_platform`, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async allGpOfsystem (data) {
    // console.log('เข้า 2', data)
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END4}panit/dashboard/all_gp_of_system`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async CreateAdminPlatform (data) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}api/admin_platform/create_admin_platform`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async EditAdminPlatform (data) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}api/admin_platform/edit_admin_platform`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async DetailAdminPlatform (data) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}api/admin_platform/detail_admin_platform`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async DeleteAdminPlatform (data) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}api/admin_platform/delete_admin_platform`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async SearchAdminPlatform (data) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}api/admin_platform/search_by_username`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  }
}
