import AxiosGraczPromotion from './axios_gracz_promotion_api'

const ModuleGraczPromotion = {
  state: {
    stateGraczCheckCanCreate: [],
    stateGraczSearchPromotion: [],
    stateGraczListPromotionCart: [],
    stateGraczFindProductPromotion: []
  },
  mutations: {
    mutationsGraczCheckCanCreate (state, data) {
      state.stateGraczCheckCanCreate = data
    },
    mutationsGraczSearchPromotion (state, data) {
      state.stateGraczSearchPromotion = data
    },
    mutationsGraczListPromotionCart (state, data) {
      state.stateGraczListPromotionCart = data
    },
    mutationsGraczFindProductPromotion (state, data) {
      state.stateGraczFindProductPromotion = data
    }
  },
  actions: {
    async actionsGraczCheckCanCreate (context, access) {
      var response = await AxiosGraczPromotion.GraczCheckCanCreate(access)
      await context.commit('mutationsGraczCheckCanCreate', response)
    },
    async actionsGraczSearchPromotion (context, access) {
      var response = await AxiosGraczPromotion.GraczSearchPromotion(access)
      await context.commit('mutationsGraczSearchPromotion', response)
    },
    async actionsGraczListPromotionCart (context, access) {
      var response = await AxiosGraczPromotion.GraczListPromotionCart(access)
      await context.commit('mutationsGraczListPromotionCart', response)
    },
    async actionsGraczFindProductPromotion (context, access) {
      var response = await AxiosGraczPromotion.GraczFindProductPromotion(access)
      await context.commit('mutationsGraczFindProductPromotion', response)
    }
  }
}
export default ModuleGraczPromotion
