import axios from 'axios'
import { Decode } from '@/services'

const GetToken = () => {
  if (Object.prototype.hasOwnProperty.call(localStorage, 'oneData')) {
    const oneData = JSON.parse(Decode.decode(localStorage.getItem('oneData')))
    const auth = {
      headers: { Authorization: `Bearer ${oneData.user.access_token}` }
    }
    return auth
  }
}

export default {
  async GraczListChannel (data) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}api/g/list_channel`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async GraczDetailChannel (data) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}api/g/detail_channel`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async GraczCreateChannel (data) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}api/g/create_channel`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async GraczEditChannel (data) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}api/g/edit_channel`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async GraczTurnOnOffChannel (data) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}api/g/turn_on_off_channel`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async GraczDeleteChannel (data) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}api/g/delete_channel`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  }
}
