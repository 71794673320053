import AxiosReviewBuyer from './axios_review_buyer_api'

const ModuleReviewBuyer = {
  state: {
    stateReviewListBuyer: [],
    stateListOrderReviewBuyer: [],
    // stateDetailReviewBuyer: [],
    stateCheckLiistReviewProductBuyer: [],
    stateCreateReviewProductBuyer: [],
    stateEditReviewProductBuyer: []
  },
  mutations: {
    mutationsReviewListBuyer (state, data) {
      state.stateReviewListBuyer = data
    },
    mutationsListOrderReviewBuyer (state, data) {
      state.stateListOrderReviewBuyer = data
    },
    mutationsCheckLiistReviewProductBuyer (state, data) {
      state.stateCheckLiistReviewProductBuyer = data
    },
    mutationsCreateReviewProductBuyer (state, data) {
      state.stateCreateReviewProductBuyer = data
    },
    mutationsEditReviewProductBuyer (state, data) {
      state.stateEditReviewProductBuyer = data
    }
    // mutationsDetailReviewBuyer (state, data) {
    //   state.stateDetailReviewBuyer = data
    // }
  },
  actions: {
    // ReviewBuyer List
    async actionsReviewListBuyer (context, access) {
      const response = await AxiosReviewBuyer.reviewListBuyer(access)
      await context.commit('mutationsReviewListBuyer', response)
    },
    async actionsListOrderReviewBuyer (context, access) {
      const response = await AxiosReviewBuyer.listOrderReviewBuyer(access)
      await context.commit('mutationsListOrderReviewBuyer', response)
    },
    // // Detail ReviewBuyer
    // async actionsDetailReviewBuyer (context, access) {
    //   const response = await AxiosReviewBuyer.detailReviewBuyer(access)
    //   await context.commit('mutationsDetailReviewBuyer', response)
    // },
    // Check Liist Review Product Buyer
    async actionsCheckLiistReviewProductBuyer (context, access) {
      const response = await AxiosReviewBuyer.checkListReviewProductBuyer(access)
      await context.commit('mutationsCheckLiistReviewProductBuyer', response)
    },
    // Check Liist Review Product Buyer
    async actionsCreateReviewProductBuyer (context, access) {
      const response = await AxiosReviewBuyer.createReviewProductBuyer(access)
      await context.commit('mutationsCreateReviewProductBuyer', response)
    },
    // Check Liist Review Product Buyer
    async actionsEditReviewProductBuyer (context, access) {
      const response = await AxiosReviewBuyer.editReviewProductBuyer(access)
      await context.commit('mutationsEditReviewProductBuyer', response)
    }
  }
}

export default ModuleReviewBuyer
