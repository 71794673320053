import AxiosGraczSupplier from './axios_gracz_supplier_api'

const ModuleGraczSupplier = {
  state: {
    stateGraczListSupplier: [],
    stateGraczCreateSupplier: []
  },
  mutations: {
    mutationsGraczListSupplier (state, data) {
      state.stateGraczListSupplier = data
    },
    mutationsGraczCreateSupplier (state, data) {
      state.stateGraczCreateSupplier = data
    }
  },
  actions: {
    async actionsGraczListSupplier (context, access) {
      var response = await AxiosGraczSupplier.GraczListSupplier(access)
      await context.commit('mutationsGraczListSupplier', response)
    },
    async actionsGraczCreateSupplier (context, access) {
      var response = await AxiosGraczSupplier.GraczCreateSupplier(access)
      await context.commit('mutationsGraczCreateSupplier', response)
    }
  }
}
export default ModuleGraczSupplier
