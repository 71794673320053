import AxiosHomepage from '../store_homepage_api/axios_hompage_api'

const ModuleHompage = {
  state: {
    // Homepage
    statehomepagedata: [],
    // more_new_products_home
    stateMoreNewProductHome: [],
    // more_bs_products_home
    stateMoreBestSeller: [],
    // Search Text
    stateSearchText: [],
    // Search Text New
    stateSearchTextNew: []
  },
  mutations: {
    // Homepage
    mutationHompage (state, data) {
      state.statehomepagedata = data
    },
    // more_new_products_home
    mutationMoreNewProductHome (state, data) {
      state.stateMoreNewProductHome = data
    },
    // more_bs_products_home
    mutationMoreBestSeller (state, data) {
      state.stateMoreBestSeller = data
    },
    // Search Text
    mutationSearchText (state, data) {
      state.stateSearchText = data
    },
    // Search Test New
    mutationsSearchTextNew (state, data) {
      state.stateSearchTextNew = data
    }
  },
  actions: {
    // Homepage
    async actionHompage (context, access) {
      const dataHomepage = await AxiosHomepage.GetHomepage(access)
      await context.commit('mutationHompage', dataHomepage)
    },
    // more_new_products_home
    async actionMoreNewProductHome (context, access) {
      const dataAllNewProduct = await AxiosHomepage.GetMoreNewProduct(access)
      await context.commit('mutationMoreNewProductHome', dataAllNewProduct)
    },
    // more_bs_products_home
    async actionMoreBestSeller (context, access) {
      const dataAllBestSeller = await AxiosHomepage.GetMoreBestSeller(access)
      await context.commit('mutationMoreBestSeller', dataAllBestSeller)
    },
    // Search Text
    async actionSearchText (context, access) {
      const dataSearch = await AxiosHomepage.GetSearchResult(access)
      await context.commit('mutationSearchText', dataSearch)
    },
    // Search Test New
    async actionsSearchTextNew (context, access) {
      const dataSearchNew = await AxiosHomepage.GetSearchResultNew(access)
      await context.commit('mutationsSearchTextNew', dataSearchNew)
    }
  }
}

export default ModuleHompage
