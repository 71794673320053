import AxiosApprove from './axios_approve'

const ModuleApprove = {
  state: {
    // approve_position
    stateListApprovePosition: [],
    stateCreateApprovePosition: [],
    stateEditApprovePosition: [],
    stateDeleteApprovePosition: [],
    // approver_list
    stateApproverInApprovePosition: [],
    stateListSelectApprover: [],
    stateAddApproverInApprovePosition: [],
    stateEditUserApproverInApprovePosition: [],
    stateDeleteApproverInApprovePosition: [],
    // ฝั่งผู้ซื้อ
    stateListPurchaserHaveApprovePosition: [],
    stateListSelectPurchaser: [],
    stateSettingPurchaserApprovePosition: [],
    stateDeletePurchaserApprovePosition: []
  },
  mutations: {
    // approve_position
    mutationsListApprovePosition (state, data) {
      state.stateListApprovePosition = data
    },
    mutationsCreateApprovePosition (state, data) {
      state.stateCreateApprovePosition = data
    },
    mutationsEditApprovePosition (state, data) {
      state.stateEditApprovePosition = data
    },
    mutationsDeleteApprovePosition (state, data) {
      state.stateDeleteApprovePosition = data
    },

    // approver_list
    mutationsListApproverInApprovePosition (state, data) {
      state.stateApproverInApprovePosition = data
    },
    mutationsListSelectApprover (state, data) {
      state.stateListSelectApprover = data
    },
    mutationsAddApproverInApprovePosition (state, data) {
      state.stateAddApproverInApprovePosition = data
    },
    mutationsEditUserApproverInApprovePosition (state, data) {
      state.stateEditUserApproverInApprovePosition = data
    },
    mutationsDeleteApproverInApprovePosition (state, data) {
      state.stateDeleteApproverInApprovePosition = data
    },
    // ฝั่งผู้ซื้อ
    mutationsListPurchaser (state, data) {
      state.stateListPurchaserHaveApprovePosition = data
    },
    mutationsListSelectPurchaser (state, data) {
      state.stateListSelectPurchaser = data
    },
    mutationsSettingPurchaser (state, data) {
      state.stateSettingPurchaserApprovePosition = data
    },
    mutationsDeletePurchaser (state, data) {
      state.stateDeletePurchaserApprovePosition = data
    }
  },
  actions: {
    // approve_position
    async actionsListApprovePosition (context, access) {
      const response = await AxiosApprove.listApprovePosition(access)
      await context.commit('mutationsListApprovePosition', response)
    },
    async actionsCreateApprovePosition (context, access) {
      const response = await AxiosApprove.createApprovePosition(access)
      await context.commit('mutationsCreateApprovePosition', response)
    },
    async actionsEditApprovePosition (context, access) {
      const response = await AxiosApprove.editApprovePosition(access)
      await context.commit('mutationsEditApprovePosition', response)
    },
    async actionsDeleteApprovePosition (context, access) {
      const response = await AxiosApprove.deleteApprovePosition(access)
      await context.commit('mutationsDeleteApprovePosition', response)
    },

    // approver_list
    async actionsListApproverInApprovePosition (context, access) {
      const response = await AxiosApprove.listApproverInApprovePosition(access)
      await context.commit('mutationsListApproverInApprovePosition', response)
    },
    async actionsListSelectApprover (context, access) {
      const response = await AxiosApprove.listSelectApprover(access)
      await context.commit('mutationsListSelectApprover', response)
    },
    async actionsAddApproverInApprovePosition (context, access) {
      const response = await AxiosApprove.addApproverInApprovePosition(access)
      await context.commit('mutationsAddApproverInApprovePosition', response)
    },
    async actionsEditUserApproverInApprovePosition (context, access) {
      const response = await AxiosApprove.editUserApproverInApprovePosition(access)
      await context.commit('mutationsEditUserApproverInApprovePosition', response)
    },
    async actionsDeleteApproverInApprovePosition (context, access) {
      const response = await AxiosApprove.deleteApproverInApprovePosition(access)
      await context.commit('mutationsDeleteApproverInApprovePosition', response)
    },
    // ฝั่งผู้ซื้อ
    async actionsListPurchaser (context, access) {
      const response = await AxiosApprove.ListPurchaser(access)
      await context.commit('mutationsListPurchaser', response)
    },
    async actionsListSelectPurchaser (context, access) {
      const response = await AxiosApprove.ListSelectPurchaser(access)
      await context.commit('mutationsListSelectPurchaser', response)
    },
    async actionsSettingPurchaser (context, access) {
      const response = await AxiosApprove.SettingPurchaser(access)
      await context.commit('mutationsSettingPurchaser', response)
    },
    async actionsDeletePurchaser (context, access) {
      const response = await AxiosApprove.DeletePurchaser(access)
      await context.commit('mutationsDeletePurchaser', response)
    }
  }
}

export default ModuleApprove
