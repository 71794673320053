import axios from 'axios'
import { Decode } from '@/services'

const GetToken = () => {
  if (localStorage.getItem('oneData') !== null) {
    const oneData = JSON.parse(Decode.decode(localStorage.getItem('oneData')))
    const auth = {
      headers: { Authorization: `Bearer ${oneData.user.access_token}` }
    }
    return auth
  } else {
    const auth = ''
    return auth
  }
}
const GetTokenNoBearer = () => {
  if (localStorage.getItem('oneData') !== null) {
    const oneData = JSON.parse(Decode.decode(localStorage.getItem('oneData')))
    const auth = {
      headers: { Authorization: `${oneData.user.access_token}` }
    }
    return auth
  } else {
    const auth = ''
    return auth
  }
}
export default {
  // add to cart
  async AddToCart (val) {
    const auth = await GetToken()
    try {
      // var response = await axios.post(`${process.env.VUE_APP_BACK_END}api/add_to_cart`, val, auth)
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}api/add_to_cart_ups`, val, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  // update cart
  async UpdateCart (val) {
    const auth = await GetToken()
    try {
      // var response = await axios.post(`${process.env.VUE_APP_BACK_END}api/update_cart`, val, auth)
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}api/update_to_cart_ups`, val, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  // delete all item cart
  async DeleteAllProductCart (val) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}api/delete_product_in_cart`, val, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  // get detail cart
  async DetailCart (val) {
    // console.log('AxiosDetailCart', val)
    const auth = await GetToken()
    try {
      // var response = await axios.post(`${process.env.VUE_APP_BACK_END}api/detail_cart`, val, auth)
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}api/get_detail_cart_ups_v2`, val, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  // get cart
  async GetCart (val) {
    const auth = await GetToken()
    try {
      // var response = await axios.post(`${process.env.VUE_APP_BACK_END}api/get_cart`, val, auth)
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}api/get_cart_v2`, val, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  // GetCartSpecialPrice
  async GetCartSpecialPrice (val) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}api/get_cart_special_price`, val, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  // create order
  async CreateOrder (val) {
    const auth = await GetToken()
    try {
      // var response = await axios.post(`${process.env.VUE_APP_BACK_END}api/create_order`, val, auth)
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}api/create_order_eprocurement`, val, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  // create order special price
  async CreateOrderSpecialprice (val) {
    const auth = await GetToken()
    try {
      // var response = await axios.post(`${process.env.VUE_APP_BACK_END}api/create_order`, val, auth)
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}api/create_order_special_price`, val, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  // CreateInvoiceAddressPurchaser
  async CreateInvoiceAddressPurchaser (val) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}api/create_invoice_address`, val, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  // confirmOrderLogin add Taxaddress sync CreateOrder
  async CreateOrderSyncTaxaddress (val) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}api/invoice_relate_cart`, val, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async GetCompanyPurchaser (val) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}api/get_company_purchaser`, val, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async GetAdminData (val) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}api/get_admin_data`, val, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async CreateQuManual (val) {
    // const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}api/create_qu_dummy`, val)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  // get Payment page
  async GetPaymentPage (val) {
    const auth = await GetToken()
    try {
      // var response = await axios.post(`${process.env.VUE_APP_BACK_END}api/get_payment_page`, val, auth)
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}api/get_payment_page`, val, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  // Local Storage
  async LocalstorageDetailCart (val) {
    try {
      // var response = await axios.post(`${process.env.VUE_APP_BACK_END}api/frontend_localstorage_detail_cart`, data)
      var response = await axios.post(`${process.env.VUE_APP_BACK_END2}localstoragecart/frontendLocalStorageDetailCart`, val)
      // console.log('API GET RES')
      return response.data
    } catch (error) {
      // console.log('API GET ERR')
      return error.response.data
    }
  },
  async LocalstorageGetCart (val) {
    try {
      // var response = await axios.post(`${process.env.VUE_APP_BACK_END}api/frontend_localstorage_get_cart`, data)
      var response = await axios.post(`${process.env.VUE_APP_BACK_END2}localstoragecart/frontendLocalStorageGetCart`, val)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async LocalstorageCreateOrder (val) {
    try {
      // var response = await axios.post(`${process.env.VUE_APP_BACK_END}api/frontend_localstorage_create_order`, val)
      var response = await axios.post(`${process.env.VUE_APP_BACK_END2}localstoragecart/frontendLocalStorageCreateOrder`, val)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async CreateSentDataPPL (val) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_PAPERLESS}external_service/api/v1/post_external_data`, val, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  // check data change when add to cart local
  async LocalstorageCheckAddToCart (val) {
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END2}localstoragecart/frontendLocalStorageCheckAddToCart`, val)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  // PreviewQU
  async PreviewQU (val) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}api/qu/preview_qu`, val, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async GetETaxPDF (val) {
    const auth = await GetTokenNoBearer()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END2}etax/document`, val, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async PayCashInStore (val) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}api/pay_cash_in_store`, val, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  }
}
