import axios from 'axios'
import { Decode } from '@/services'

const GetToken = () => {
  if (localStorage.getItem('oneData') !== null) {
    const oneData = JSON.parse(Decode.decode(localStorage.getItem('oneData')))
    const auth = {
      headers: { Authorization: `Bearer ${oneData.user.access_token}` }
    }
    return auth
  } else {
    const auth = ''
    return auth
  }
}

export default {
  // Create Business ID
  async axiosCreateBusiness (val) {
    const data = val
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}api/create_business`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  // Get Detail Business ID
  async axiosDetailBusiness (val) {
    // console.log(val)
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}api/detail_business`, val, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  // find tax id detail อันนี้จะเป็นตัวที่ยิงไปหากรมอะไรซักอย่าง
  async axiosFindTaxIDByRevenue (val) {
    // console.log(val)
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}api/business/find_tax_id`, val, auth)
      // console.log('axiosFindTaxIDByRevenue', response.data)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  // find tax id detail by one id
  async axiosFindTaxIDByOneID (val) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}api/business/find_tax_id_by_one_id`, val, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  // Upload Approver
  async axiosUploadApprover (val) {
    const oneData = JSON.parse(Decode.decode(localStorage.getItem('oneData')))
    const auth = {
      headers: {
        Authorization: `Bearer ${oneData.user.access_token}`,
        'Content-Type': 'multipart/form-data'
      }
    }
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}api/business/register_business_step_1`, val, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  // Upload ResultDocument
  async axiosUploadResultDocument (val) {
    const oneData = JSON.parse(Decode.decode(localStorage.getItem('oneData')))
    const auth = {
      headers: {
        Authorization: `Bearer ${oneData.user.access_token}`,
        'Content-Type': 'multipart/form-data'
      }
    }
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}api/business/register_business_step_2`, val, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async axiosDetailBusinessOfUser () {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}api/business/detail_business_of_user`, '', auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async axiosCreateBusinessOther (data) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}api/business/register_business_other`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  }
}
