import AxiosSettingQuotation from './axios_setting_quotation'

const ModuleSettingQuotation = {
  state: {
    stateSettingQuotationList: [],
    stateCreateQuotation: [],
    stateEditQuotation: [],
    stateDetailQuotation: [],
    stateUpdateStatusQuotation: []
  },
  mutations: {
    mutationsSettingQuotationList (state, data) {
      state.stateSettingQuotationList = data
    },
    mutationsCreateQuotation (state, data) {
      state.stateCreateQuotation = data
    },
    mutationsEditQuotation (state, data) {
      state.stateEditQuotation = data
    },
    mutationsDetailQuotation (state, data) {
      state.stateDetailQuotation = data
    },
    mutationsUpdateStatusQuotation (state, data) {
      state.stateUpdateStatusQuotation = data
    }
  },
  actions: {
    // Setting Quotation List
    async actionsSettingQuotationList (context, access) {
      const response = await AxiosSettingQuotation.settingQuotationList(access)
      await context.commit('mutationsSettingQuotationList', response)
    },
    // Create Setting Quotation
    async actionsCreateQuotation (context, access) {
      const response = await AxiosSettingQuotation.createQuotation(access)
      await context.commit('mutationsCreateQuotation', response)
    },
    // Edit Setting Quotation
    async actionsEditQuotationSetting (context, access) {
      const response = await AxiosSettingQuotation.editQuotation(access)
      await context.commit('mutationsEditQuotation', response)
    },
    // Detail Setting Quotation
    async actionsDetailQuotation (context, access) {
      const response = await AxiosSettingQuotation.detailQuotation(access)
      await context.commit('mutationsDetailQuotation', response)
    },
    // Update status Setting Quotation
    async actionsUpdateStatusQuotation (context, access) {
      const response = await AxiosSettingQuotation.updateStatusQuotation(access)
      await context.commit('mutationsUpdateStatusQuotation', response)
    }
  }
}

export default ModuleSettingQuotation
