const ModuleGlobal = {
  state: {
    Loader: false
  },
  mutations: {
    closeLoader (state) {
      state.Loader = false
    },
    openLoader (state) {
      state.Loader = true
    }
  },
  actions: {
  }
}
export default ModuleGlobal
