import axios from 'axios'
import { Decode } from '@/services'

const GetToken = () => {
  if (Object.prototype.hasOwnProperty.call(localStorage, 'oneData')) {
    const oneData = JSON.parse(Decode.decode(localStorage.getItem('oneData')))
    // console.log('ONEDATA NAAAa', oneData)
    const auth = {
      headers: { Authorization: `Bearer ${oneData.user.access_token}` }
    }
    return auth
  }
}

export default {
  async GetSellerShop () {
    const auth = await GetToken()
    try {
      var response = await axios.get(`${process.env.VUE_APP_BACK_END}api/get_shop_detail`, auth)
      // console.log('response.data', response.data)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async PostRegisterbuyer (val) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}api/register_business_moph_commerce`, val, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async ConfirmOTPOneID (val) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}api/oauth_otp`, val, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  }
}
