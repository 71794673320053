import AxiosGraczProductPHP from './axios_gracz_product_php_api'

const ModuleGraczProductPHP = {
  state: {
    stateGraczGetProductDetail: []
  },
  mutations: {
    mutationsGraczGetProductDetail (state, data) {
      state.stateGraczGetProductDetail = data
    }
  },
  actions: {
    async actionsGraczGetProductDetail (context, access) {
      var response = await AxiosGraczProductPHP.GraczGetProductDetail(access)
      await context.commit('mutationsGraczGetProductDetail', response)
    }
  }
}
export default ModuleGraczProductPHP
