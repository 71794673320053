import axios from 'axios'
import { Decode } from '@/services'

const GetToken = () => {
  if (localStorage.getItem('oneData') !== null) {
    const oneData = JSON.parse(Decode.decode(localStorage.getItem('oneData')))
    const auth = {
      headers: { Authorization: `Bearer ${oneData.user.access_token}` }
    }
    return auth
  } else {
    const auth = ''
    return auth
  }
}

export default {
  // approve_position
  async listApprovePosition (data) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}api/approver/list_approve_position`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async createApprovePosition (data) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}api/approver/create_approve_position`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async editApprovePosition (data) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}api/approver/edit_approve_position`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async deleteApprovePosition (data) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}api/approver/delete_approve_position`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },

  // approver_list
  async listApproverInApprovePosition (data) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}api/approver/list_approver_in_approve_position`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async listSelectApprover (data) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}api/approver/list_select_approver`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async addApproverInApprovePosition (data) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}api/approver/add_approver_in_approve_position`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async editUserApproverInApprovePosition (data) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}api/approver/edit_user_approver_in_approve_position`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async deleteApproverInApprovePosition (data) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}api/approver/delete_approver_in_approve_position`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  // ฝั่งผู้ซื้อ
  async ListPurchaser (data) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}api/approver/list_purchaser_have_approve_position`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async ListSelectPurchaser (data) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}api/approver/list_select_purchaser`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async SettingPurchaser (data) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}api/approver/setting_purchaser_approve_position`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  },
  async DeletePurchaser (data) {
    const auth = await GetToken()
    try {
      var response = await axios.post(`${process.env.VUE_APP_BACK_END}api/approver/delete_purchaser_approve_position`, data, auth)
      return response.data
    } catch (error) {
      return error.response.data
    }
  }
}
