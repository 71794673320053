import AxiosProduct from './axios_product_api_node'

const ModuleProductNode = {
  state: {
    // Product Detail
    stateProducts: [],
    stateProductsManage: [],
    stateProductNew: [],
    stateProductBestSeller: [],
    stateProductRecomment: [],
    stateAllProduct: [],
    stateAllProductCategory: [],
    stateBuyAgainProduct: []
  },
  mutations: {
    // Product Detail
    mutationsProduct (state, data) {
      state.stateProducts = data
    },
    mutationsProductManageShop (state, data) {
      state.stateProductsManage = data
    },
    // Get New Product
    mutationsProductNew (state, data) {
      state.stateProductNew = data
    },
    // Get BestSeller Product
    mutationsProductBestSeller (state, data) {
      state.stateProductBestSeller = data
    },
    // Get Recomment Product
    mutationsProductRecomment (state, data) {
      state.stateProductRecomment = data
    },
    // Get All Product
    mutationsAllProduct (state, data) {
      state.stateAllProduct = data
    },
    // Get All Product Categoty
    mutationsAllProductCategory (state, data) {
      state.stateAllProductCategory = data
    },
    // Get Buy Again Product
    mutationsBuyAgainProduct (state, data) {
      state.stateBuyAgainProduct = data
    }
  },
  actions: {
    // Product Detail
    async actionsProduct (context, access) {
      const dataProduct = await AxiosProduct.GetProduct(access)
      await context.commit('mutationsProduct', dataProduct)
    },
    // Product Detail In Manage Shop
    async actionsProductManageShop (context, access) {
      const dataProduct = await AxiosProduct.GetProductManageShop(access)
      await context.commit('mutationsProductManageShop', dataProduct)
    },
    // Get Product New
    async actionsProductNew (context, access) {
      const dataProduct = await AxiosProduct.GetProductNew(access)
      await context.commit('mutationsProductNew', dataProduct)
    },
    // Get BestSeller Product
    async actionsProductBestSellerHome (context, access) {
      const dataProduct = await AxiosProduct.GetProductBestSeller(access)
      // console.log(dataProduct)
      await context.commit('mutationsProductBestSeller', dataProduct)
    },
    // Get Recomment Product
    async actionsProductRecomment (context, access) {
      const dataProduct = await AxiosProduct.GetProductRecomment(access)
      await context.commit('mutationsProductRecomment', dataProduct)
    },
    // Get All Product
    async actionsAllProduct (context, access) {
      const dataProduct = await AxiosProduct.GetAllProduct(access)
      await context.commit('mutationsAllProduct', dataProduct)
    },
    // Get All Product Categoty
    async actionsGetAllProductCategory (context, access) {
      const dataProductCategory = await AxiosProduct.GetAllProductCategory(access)
      await context.commit('mutationsAllProductCategory', dataProductCategory)
    },
    // Get Buy Again Product
    async actionsGetBuyAgainProduct (context, access) {
      const dataProduct = await AxiosProduct.GetBuyAgainProduct(access)
      await context.commit('mutationsBuyAgainProduct', dataProduct)
    }
  }
}

export default ModuleProductNode
